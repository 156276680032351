@import "../../../../node_modules/@anthem/uxd/assets/styles/core/variables";
@import "../../../../node_modules/@anthem/uxd/assets/styles/core/mixins";
@import "../../../../node_modules/@anthem/uxd/assets/styles/themes/motif/colors";

/* Import Reimagined Classic Icon Variables */
@import "../../../../node_modules/@anthem/uxd/assets/fonts/icons/classic/variables";
@import "../../../../node_modules/@anthem/uxd/assets/fonts/icons/classic/icons";
@import "../../../../node_modules/@anthem/uxd/assets/fonts/icons/motif/variables";
@import "../../../../node_modules/@anthem/uxd/assets/fonts/icons/motif/icons";

//TODO: define the common css rule variables here
$ant-motif-dove-dark-gray: #666666;
