/**
 *
 * Description:
 * This SCSS file contains styles for overlay components used in the 'findcare' redesign of the application.
 *
 * Note:
 * The components styled in this file are not rendered inside the 'fcr-page-wrapper' container.
 * Therefore, they require separate styling considerations to ensure that the styles are correctly applied in the pf-element.
 *
 * Usage:
 * Import this file in your main.scss and element-main.scss file to apply the overlay styles to the relevant components.
 */

// Common side panel styles starts here
.pf-side-panel {
  overflow-y: auto !important;
  height: 100% !important;

  .close-btn-ht {
    height: 60px;
  }

  .modal-component-body {
    padding: 5rem 1rem 5rem 5.5rem !important;

    @media screen and (min-width: 801px) {
      padding: 1rem 5rem 5rem 5rem !important;
    }
  }
}
// Common side panel styles ends here

//  Member Selection Modal style starts here
.fcr-member-selection-modal-overview {
  .modal-dialog {
    @media screen and (max-width: $breakpoint-xs) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modal-content {
      padding: 32px;

      @media screen and (max-width: $breakpoint-xs) {
        height: fit-content;
        margin: 0 16px;
        padding: 24px;
      }

      .modal-footer {
        display: none !important;
      }

      .modal-header {
        height: 0px;
        padding-top: 0px;
        padding-bottom: 0px;

        .close {
          border: none;
          padding: 0;
          right: -50px;
          top: -26px;

          &:focus {
            box-shadow: none;
          }

          .fa.fa-remove {
            border: none;
            color: #949494;
            font-size: 28px;

            @media screen and (max-width: $breakpoint-xs) {
              font-size: 20px;
              padding: 0;
              margin: 12px 9px 0 0;
            }
          }
        }
      }

      .modal-body {
        padding: 0 !important;

        .modal-component-body {
          padding: 1.5rem;
          padding-left: 5.5rem;

          @media screen and (max-width: $breakpoint-sm) {
            padding-left: 3rem;
          }

          @media screen and (max-width: $breakpoint-xs) {
            padding: 0;
          }
        }
      }
    }
  }

  .fcr-member-selection-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      display: block;
    }

    h2 {
      font-family: montserrat;
      font-weight: 300;
      font-size: 26px;
      line-height: 32px;
      color: #333333;
      margin-bottom: 16px;
      text-align: center;

      @media screen and (max-width: $breakpoint-xs) {
        font-weight: 400;
        font-size: 22px !important;
        color: #000000;
        line-height: 26px;
        margin-bottom: 12px;
        text-align: start;
      }
    }

    .fcr-member-selection-modal-description {
      font-family: lato;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: $breakpoint-xs) {
        font-weight: 700;
        font-size: 12px !important;
        color: #000000;
        line-height: 22px;
      }

      .modal-desc {
        margin: 0;
        text-align: center;

        @media screen and (max-width: $breakpoint-xs) {
          display: contents;
        }
      }
    }

    .fcr-member-selection-modal-btn-container {
      display: flex;
      flex-wrap: wrap;
      column-gap: 12px;
      margin-top: 40px;
      max-width: 550px;
      justify-content: center;

      @media screen and (max-width: $breakpoint-sm) {
        margin-top: 32px;
      }

      @media screen and (max-width: $breakpoint-xs) {
        justify-content: flex-start;
      }

      .fcr-member-selection-modal-btn {
        font-family: lato;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        color: #286ce2;
        width: auto;
        border: 1px solid #286ce2;
        padding: 8px 16px;
        margin-bottom: 8px;
        text-transform: lowercase;
        background-color: #ffffff;
      }

      .fcr-member-selection-modal-btn::first-letter {
        text-transform: uppercase;
      }

      .fcr-member-selection-modal-btn:only-child {
        justify-content: flex-start;
      }
    }

    .fcr-member-selection-modal-action-container {
      .fcr-member-selection-modal-checkbox {
        padding: 0 8px;

        p {
          font-family: lato;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }

      .fcr-member-selection-modal-action-btn {
        height: 45px;
        width: 100%;
        border: 1px solid #286ce2;
        font-family: lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: #ffffff;
        background-color: #286ce2;
      }
    }
  }

  &[data-uxd-modal-cmp] {
    background: rgba(51, 51, 51, 0.5) !important;
  }
}
// Member Selection Modal style ends here

// Update Location side panel style starts here
.fcr-location-side-panel {
  display: flex;
  flex-direction: column;

  &.pf-side-panel h3.modal-title {
    font-size: 26px;

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 22px;
      line-height: 1.2;
    }
  }

  .fixed-tray .btn {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }

  @media only screen and (max-width: $breakpoint-xs) {
    .fixed-tray.map-sticky-footer.fcr-sticky-footer {
      display: unset;
      box-shadow: 0px -2px 10px 0px #00000014 !important;
      padding-top: 0;

      .btn-location-slider {
        display: flex;
        width: 90%;
        position: absolute;
        bottom: 0;

        .btn {
          margin-top: 8px;
          margin-left: unset;
          width: 50%;
        }
      }
    }
  }

  .fcr-location-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .view-claims {
      display: inline-flex;

      input[type="radio"] {
        margin: 2px 0;
      }
    }

    .fcr-zipcode-input {
      @media screen and (max-width: $breakpoint-xs) {
        width: 100%;
      }
    }

    .fcr-sticky-footer {
      padding: 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      box-shadow: 0px -2px 10px 0px #00000014;

      @media screen and (max-width: $breakpoint-xs) {
        padding: 16px;
        justify-content: center;
      }

      .btn {
        width: 168px;
      }

      .btn.btn-primary {
        background: #286ce2;
      }
    }
  }

  .fcr-scroller-mob {
    @media only screen and (max-width: $breakpoint-xs) {
      padding-left: 10px;
    }
  }
}

.modal-container.slide-in.right {
  .fcr-location-side-panel {
    .clearfix {
      .close {
        @media only screen and (max-width: $breakpoint-xs) {
          width: 48px;
          height: 48px;
        }

        .fcr-close-icon:before {
          color: #286ce2;
          font-size: 26px;

          @media only screen and (max-width: $breakpoint-xs) {
            font-size: 22px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .pf-side-panel.switchLocation.fcr-location-side-panel .location-modal-height {
    padding-left: 25px !important;
    height: 90%;

    h3.modal-title {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .overlay-container .overlay-wrapper {
    position: absolute;
    top: 0;
    right: 0 !important;
    width: 90%;
    height: 100%;
    left: unset;
  }
}
//Update location side panel style ends here

//Update care Plan side panel style starts here
.fcr-change-plan-side-panel {
  &.fcr-location-side-panel .fixed-tray.map-sticky-footer.fcr-sticky-footer .btn-location-slider {
    @media only screen and (max-width: $breakpoint-xs) {
      margin-left: 16px;
    }
  }

  &.fcr-location-side-panel .modal-component-body {
    @media only screen and (max-width: $breakpoint-xs) {
      padding-left: 24px !important;
    }
  }

  .fcr-plan-name {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: 300;
    line-height: 31.69px;
    text-align: left;
    color: #000000;
    margin-bottom: 40px;

    @media only screen and (max-width: $breakpoint-xs) {
      margin-bottom: 19px;
    }
  }

  .fcr-plan-text {
    //styleName: Paragraph Regular;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #333333;
  }

  .fcr-eligible {
    //styleName: Paragraph Bold;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    margin-bottom: 4px;
  }

  .fcr-modal-text {
    margin-top: 16px;

    .radio-option {
      padding-top: 24px;
    }
  }

  h4.modal-heading {
    //styleName: Headline H2 Regular;
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: 300;
    line-height: 31.69px;
    text-align: left;
    padding-bottom: 0px;
  }

  h4 + span {
    //styleName: Paragraph Regular;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #333333;
  }

  .fcr-include-text {
    //styleName: Paragraph Bold;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #333333;

    ~ .fcr-planChange-text {
      font-weight: 400;
      color: #333333;
      display: flex;
      flex-wrap: wrap;
      column-gap: 8px;
      max-width: 550px;
    }
  }

  .fcr-plan-network-radio {
    ~ .fcr-contract-details {
      margin-left: 8px;
      margin-top: 8px;
      display: flex;
      padding-left: 15px;

      .fcr-planChange-text {
        padding-left: 4px;
        line-height: 22px;
      }

      ~ .fcr-member-details {
        margin-left: 8px;
        padding-left: 15px;
        margin-top: 12px;
        padding-bottom: 24px;
        border-bottom: 1px solid #cccccc;
      }
    }

    ~ .fcr-effective-date {
      background: #f7f7f7;
      font-family: Lato;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-align: center;
      text-transform: uppercase;
      padding: 3px 6px;
      font-size: 10px !important;
    }

    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;
    color: #333333;
    margin-left: 8px;
    margin-right: 8px;

    span.fcr-plan-list {
      //styleName: Paragraph Regular;
      font-family: "Lato";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
    }
  }

  .fcr-multiple-member-btn {
    margin-right: 8px;
    border: 1px solid #666666;
    height: 30px;
    background-color: white;
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: #666666;
    width: auto;
    padding: 8px 16px;
    margin-top: 4px;

    .fcr-multiple-member-span {
      //styleName: Misc;
      font-family: Lato;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      text-align: center;
      padding-top: 6px;
      text-transform: capitalize !important;
    }
  }
}
//Update care Plan side panel style ends here

// Quicklinks Feedback Overlay styles starts
.overlay-container {
  &:has(.fcr-feedback-panel),
  &:has(.fcr-view-all-side-panel),
  &:has(.fcr-ct-side-panel),
  &:has(.fcr-ct-remove-side-panel) {
    .overlay-wrapper {
      right: 0;
      left: unset;
      float: right;
      width: 100%;

      @media screen and (max-width: $breakpoint-sm) {
        width: 92%;
      }
    }

    .modal-container.slide-in.right {
      .modal-panel {
        width: 45%;

        @media screen and (max-width: $breakpoint-sm) {
          width: 100%;
        }
      }
    }
  }
}
// Quicklinks Feedback Overlay styles ends

// Quicklinks Feedback Panel styles starts
.fcr-feedback-panel {
  overflow-y: auto !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .fct-feedback-panel-header {
    .fcr-close {
      width: 62px;
      height: 60px;
      background: #f7f7f7;
      border: 0;

      &:focus-visible {
        outline: none;
      }

      .fcr-close-icon {
        font-size: 26px !important;
        color: #286ce2;
      }
    }
  }

  .fcr-feedback-panel-body {
    padding: 8px 40px 42px 82px;

    @media screen and (max-width: $breakpoint-sm) {
      padding: 24px;
    }

    .fcr-reasonHeader {
      h2 {
        margin-bottom: 24px;
      }
    }

    .fcr-modalContentTop {
      p {
        margin-bottom: 24px;
      }
    }

    .fcr-reason-title {
      .prLabel {
        margin: 0;
        margin-bottom: 32px;
        align-items: flex-start;
        line-height: unset;

        &::before {
          margin-right: 9px;
          height: 16px;
          width: 16px;
        }
      }
    }

    .fcr-reason-txt-container {
      margin-top: -21px !important;

      .fcr-not-interested-input {
        width: calc(100% - 58px) !important;
        padding: 13px 12px !important;
        border: 1px solid #949494;

        @media screen and (max-width: $breakpoint-sm) {
          width: calc(100% - 24px) !important;
          padding: 10px !important;
        }
      }
    }
  }

  .fcr-side-panel-footer {
    box-shadow: 0px -2px 10px 0px #00000014;
    background: #ffffff;
    width: 100%;

    .fcr-action-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 24px;
      gap: 12px;

      .fcr-btn {
        width: 168px;
        height: 45px;
        align-items: center;
        padding: unset;
      }

      .btn-primary {
        background: #286ce2;
      }
    }
  }
}
// Quicklinks Feedback Panel styles ends

//viewall sliderpanel styles starts
.fcr-view-all-side-panel {
  overflow-y: auto !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .fcr-width-100 {
    width: 100%;
  }

  .fcr-close {
    width: 62px;
    height: 60px;
    background: #f7f7f7;
    border: 0;

    @media screen and (max-width: $breakpoint-sm) {
      width: 48px;
      height: 48px;
    }

    &:focus-visible {
      outline: none;
    }

    .fcr-close-icon {
      font-size: 26px !important;
      color: #286ce2;

      @media screen and (max-width: $breakpoint-sm) {
        font-size: 22px !important;
      }
    }
  }

  .fcr-modal-component-body {
    padding: 8px 40px 42px 82px;

    @media screen and (max-width: $breakpoint-sm) {
      padding: 25px 16px 42px 25px;
    }

    .fcr-view-list {
      .fcr-modal-title {
        font-size: 26px;
        font-weight: 300;
        line-height: 31.69px;
        margin-bottom: 36px;
        color: #000;
      }

      .fcr-modal-text {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        margin-bottom: 36px;

        .fcr-radio-btn {
          margin: 4px 0 0;
          height: 16px;
          width: 16px;
        }

        .fcr-modal-txt-wrapper {
          display: flex;
          flex-direction: column;
          align-self: start;

          .fcr-member-name,
          .fcr-member-dob {
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            color: #333333;
            text-transform: capitalize;
          }

          .fcr-member-dob {
            font-weight: 400;
          }
        }
      }
    }
  }

  .fcr-component-footer {
    box-shadow: 0px -2px 10px 0px #00000014;
    background: #ffffff;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 24px;

    @media screen and (max-width: $breakpoint-sm) {
      justify-content: center;
    }

    .fcr-btn-primary {
      background: #286ce2;
      color: #ffffff;
    }

    .btn {
      width: 145px;
      height: 45px;

      @media screen and (max-width: $breakpoint-xs) {
        width: 153px;
      }
    }
  }
}
//viewall sliderpanel styles ends

// Compare providers web modal styles starts
.fcr-providers-compare-modal-overview {
  .modal-dialog {
    .modal-content {
      width: 1128px;
      max-height: 720px;
      padding: 0px;
      border-radius: 20px;

      .modal-header,
      .modal-footer {
        display: none !important;
      }

      .modal-body {
        padding: 0 !important;

        .modal-component-body {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          border-radius: 20px;
        }

        .modal-component-body::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .fcr-providers-compare-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: scroll;

    .fcr-providers-compare-modal-heading {
      font-family: Lato;
      padding: 24px 24px 16px 24px;
      position: sticky;
      top: 0;
      background: #fff;
      border: 1px solid #ccc;
      z-index: 20001;
      .fcr-compare-provider-header-top {
        display: flex;
        align-items: center;
        width: 100%;

        h5 {
          flex: 1;
          font-weight: 700;
          font-size: 22px;
          line-height: 26.4px;
          text-align: center;
        }

        .fcr-provider-print-icon {
          height: auto;
          width: auto;
          font-family: Lato;
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          color: #286ce2;
          margin-right: 40px;
          cursor: pointer;

          img {
            padding-right: 5px;
          }
        }

        .fcr-provider-close-icon {
          cursor: pointer;

          .close-icon {
            height: 22px;
            width: 22px;
            vertical-align: top;
          }
        }
      }
    }

    .fcr-compare-provider-table {
      flex-grow: 1;
      max-height: 500px;

      .fcr-compare-table-display-none {
        display: none;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;

      th {
        &:first-child {
          width: 206px;
        }

        &:not(:first-child) {
          width: calc((100% - 206px) / 3);
          vertical-align: top;
        }
      }

      thead > tr,
      tfoot > tr {
        tr {
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
        }
      }

      img {
        padding-right: 8px;
        vertical-align: text-top;
      }

      .fcr-provider-bold-text {
        font-weight: 700;
      }
      .fcr-compare-provider-table-data.fcr-provider-map {
        padding-left: 0px;
        padding-right: 0px;
        div {
          height: 180px;
        }
      }
    }

    .fcr-table-sticky-header {
      top: 70px;
      font-family: Lato;
      color: #333333;

      .fcr-provider-name {
        font-size: 22px;
        font-weight: 700;
        line-height: 26.4px;
        padding-bottom: 12px;
      }

      .fcr-provider-network-status,
      .fcr-provider-type {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        padding-bottom: 12px;
      }
    }

    .fcr-table-sticky-header,
    .fcr-table-sticky-footer {
      position: sticky;
      background-color: #f7f7f7;
      z-index: 20001;
      border: 1px solid #0000001a;
    }

    th,
    td {
      border-right: 2px solid rgba(148, 148, 148, 0.5);
      padding: 16px 24px 0px 16px;
      width: 200px;

      .fcr-add-another-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .fcr-add-another-button {
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #e6efff;
      }

      ul.fcr-provider-no-bullets {
        list-style: none;
        padding-left: 0;

        li {
          &:last-child {
            padding-bottom: 0px;
          }

          padding-bottom: 16px;
        }
      }
    }

    .fcr-compare-provider-table-content {
      max-height: 443px;
      overflow-y: scroll;
      scrollbar-width: none;
    }

    .fcr-compare-provider-table-content::-webkit-scrollbar {
      display: none;
    }

    td.fcr-compare-provider-table-headings {
      text-align: right;
      vertical-align: top;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      color: #333333;
    }

    td.fcr-compare-provider-table-data {
      text-align: left;
      vertical-align: top;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #333333;
      padding-left: 24px;

      a {
        font-weight: 700;
        color: #286ce2;
        text-decoration: none;
        word-wrap: break-word;
      }
    }

    td.fcr-provider-bold-text {
      font-weight: 700;
    }

    td.fcr-provider-empty-cell-data,
    td.fcr-empty-footer-cell {
      border-right: none;
    }

    .fcr-empty-footer-cell,
    .fcr-provider-empty-cell-data {
      background-color: #e6efff;
      border: none;
      padding: 16px 24px;
      height: 100%;
    }

    .fcr-provider-empty-cell-data {
      background-color: #f7f7f7;
      border: none;
      padding: 16px 24px;
    }

    td:last-child,
    th:last-child {
      border-right: none;
    }

    .fcr-table-sticky-footer {
      bottom: 0;

      .fcr-provider-footer-button-container {
        display: flex;
        justify-content: space-between;
        margin: 8px 0px 24px;
      }

      .fcr-remove-button-icon {
        padding-left: 8px;
        padding-right: 0px;
      }
    }

    th button,
    td button {
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      color: #286ce2;
      cursor: pointer;
      display: flex;
      align-items: center;
      border: none;
      background: #f7f7f7;
      height: fit-content;
      width: fit-content;
    }

    button[disabled] {
      background: #f7f7f7;
      cursor: not-allowed;
    }

    .fcr-provider-rewards-scroll {
      height: 500px;
      overflow-y: auto;
      h5 {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 700;
        line-height: 26.82px;
        padding: 24px;
      }
      p {
        padding-left: 24px;
      }
    }

    .fcr-provider-rewards-scroll::-webkit-scrollbar {
      display: none;
    }
    .fcr-provider-rewards-scroll {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  .fcr-provider-rating-container {
    padding: 24px 50px 0px;
  }
}

[data-uxd-modal-cmp].fcr-providers-compare-modal-overview {
  background: rgba(51, 51, 51, 0.5) !important;

  .modal-content {
    max-width: 1128px;
    margin: 13% auto 0;
  }

  .modal-open {
    overflow: hidden;
  }

  .modal-dialog {
    transform: translate(-12%, -12%) !important;
    margin: 50px auto 0px;
  }
}

// Provider recognition cards styles starts here

.fcr-provider-recognition-cards {
  margin: 24px;

  .fcr-recognitions-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 34px;
  }

  .fcr-recognitions-card {
    background-color: #fff;
    border: 1px solid #0000001a;
    padding: 18px 24px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    flex-direction: column;
    color: #333;
  }

  .fcr-recognitions-card-title {
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    padding-bottom: 18px;
  }

  .fcr-recognitions-card-icon {
    width: 82px;
    height: 82px;
    padding-bottom: 10px;
  }

  .fcr-recognitions-card-description {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  @media screen and (max-width: 840px) {
    .fcr-recognitions-card-container {
      grid-template-columns: 1fr;
    }

    .fcr-provider-recognition-cards {
      margin: 16px;
    }
  }
}

// Provider recognition cards styles ends here
