@import "variables";

//Motif Theme Overrides that aren't in Motif
.pfSelect {
  legend {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}

a {
  border-bottom: none;
}

.ant-input-lg {
  width: 500px;
  max-width: 100%;
}

.back-to-top-dir {
  background-color: $ant-blue;
  text-decoration: none;

  &:hover {
    background-color: $ant-motif-highlight-midnight-blue;
    color: $ant-motif-white;
  }

  span {
    color: $ant-motif-white;
    text-decoration: none;
  }

  &:hover > .fa.black, &:focus > .fa.black {
    color: $ant-motif-white;
    background-color: $ant-motif-highlight-midnight-blue;
  }
}

fieldset.ant-auto-suggest {
  .psDropdown.active {
    position: absolute;
    // top: 70px; //fix for PL5.9
    top: 100%;
  }

  .ant-search-button {
    position: relative;
    right: 1px;
    top: 1px;
    padding: 0;
    span.fa {
      // top: 5px; //fix for PL5.9
      padding: 0.1rem 0.6rem;
      font-size: 1.5rem;
    }
  }

  .ant-text-input {
    border: 1px solid $ant-motif-dusty-gray;
    color: $ant-motif-dove-gray;
  }

}
//non RCP position fix
//.auto-suggest-top-fix {
//  fieldset.ant-auto-suggest {
//    .psDropdown.active {
//      top: 31px;
//    }
//  }
//}

.psOption.focus + .psLabel {
  background: $ant-motif-highlight-ocean;
  color: $ant-motif-white;
  text-decoration: none;
}

.popover {
  max-width: 276px;
}

.popover.right, .popover.left, .popover.top, .popover.bottom {
  z-index: 12;
}

.ant-auto-suggest {
  .psButton .psArrow,
  .psArrow {
    border-left: none;
    // padding: 0.5rem 0.7rem 0.05rem 0rem !important;
  }

  .pfSelect .psButton:focus .psArrow {
    border-left: none;
  }

  .pfSelect .psArrow {
    border-left: none;
    color: inherit;
    font-size: 1rem;
  }
}

.pcLabel::before {
  vertical-align: bottom;
}

//TODO remove once modals are updated to Motif
[data-uxd-modal-cmp] {
  background: transparent;
  .modal-content {
    .close {
      .fa.fa-remove {
        margin-right: 0;
      }
    }
  }
}

//TODO remove once Baseline-Theme is removed from main.scss
.ant-alphabet-tabs-container {
  .ant-alphabet-tabs {
    ul {
      li {
        border-right: 1px solid $ant-motif-mine-shaft !important;
        background-color: $ant-motif-white !important;
        height: inherit;
        a {
          color: $ant-blue;
          line-height: 0;
        }
      }

      li.active{
        a {
          color: $ant-motif-white;
        }
      }
    }
  }
}
