@import "variables";


.fly-out {
  z-index: 499;
  position: absolute;
  background-color: #fff;
  margin-top: 2px;
  padding: .75rem;

  a {
    text-decoration: none;
  }

  .result-link {
    text-decoration: none;
    font-weight: 600;
  }
}


.look-up-container {
  border: 1px solid $ant-motif-silver;
  border-radius: 5px;
  // box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  .input-container{
      display: -ms-flexbox;
      display: flex;
      width: 96%;
      margin-bottom: 8px;
      padding: 1rem 1rem 0rem 2rem;
    input.input-field{
      width: 100%;
      padding: 8px;
      outline: none;
      font-size: 12px;
      background: rgb(255, 255, 255);
      border-radius: 0px;
      border: 1px solid rgb(148, 148, 148);
      &:focus{
        border: 2px solid #000;
      }
    }
    .search-icon{
      color: #fff;
      text-align: center;
      background: rgb(40, 108, 226);
      border-radius: 0px;
      height: 40px;
      width: 40px;
      font-size: 1.5rem;
      padding-top: 9px;
    }
  }
}
.live-chat-container {
  display: flex;
  align-items: center;
}
.chat-icon {
  margin-bottom: -5px;
  margin-left: 5px;
  margin-right: 5px;
}
.underline {
  text-decoration: underline;
}

.look-up-item-header {
  background-color: #f7f7f7;
  font-size: 18px;
  padding: 7px 10px;
  font-weight: 600;
  &.secondary{
    background: #fff;
    padding:0;
    .itemheader{
      background: #f7f7f7;
      padding: 7px 10px;
    }
  }
  @media screen and (max-width: 801px) {
    .section-pill-bg.col-sm-9{
      width: 75%;
      float: left;
    }
  }
  .show-more-label {
    display: flex;
    justify-content: flex-end;
  }
  .col-sm-3.show-more-label{
    padding-top: .75rem;
    @media screen and (max-width: 801px) {
      width: 25%;
    }
    a.result-link{
      padding-left: 1rem;
    }
  }
}

.look-up-item {
  font-size: 15px;
  padding: 10px;
}

.look-up-item-border {
  border-bottom: 1px solid #f7f7f7;
}

.look-up-item-more {
  height: 13rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ftsSuggestion-btn {   //For suggestion list of (Free Text Search feature) in look-up-container
  width: 100%;
  text-align:left;
}

.changeLocation{

  .a{
    color: #E6E6E6;
    font-size: 14rem;
  }

}


.search-bar-container {
  display: flex;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);

  @media screen and (max-width: $breakpoint-sm) {
    flex-wrap: wrap;
    box-shadow: none;
  }

  @media screen and (max-width: $breakpoint-xs) {
    flex-wrap: nowrap;
    box-shadow: none;
    display: block;
  }


  .search-term-wrapper {
    flex: 1 0 60%;
    .motif-search-1 {
      position: absolute;
      color: #286CE2;
      font-size: 1.5rem!important;
      padding-top: 19px;
      padding-left: 10px;
    }
  }
.fts-search-term-wrapper {
  position: relative;
  display: inline-block;

  .input {
    margin-right: 5rem;
    padding: 11px 5rem 11px 3rem;
  }

  .fts-search-link {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    text-decoration: none;
    margin-left: 3rem;
  }
}

  .search-input {
    height: 100%;
    width: 100%;
    font-size: 18px;
    color: $ant-motif-dove-gray;
    padding: 6px 40px 11px;
    &:focus {
      box-shadow: 0 0 3px 2px #286ce2;
    }
  }

  .type-btn-wrapper {
    flex: 1 0 16%;
    .search-type-button {
      background-color: $ant-motif-white;
      border: 1px solid #949494;
      color: $ant-motif-dove-gray;
      font-size: 18px;
      line-height: 17px;
      padding: 0 1rem 0 2rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: nowrap;
      width: 100%;

      .search-btn-label {
        text-align: left;
      }

      .motif-icon {
        padding: .5rem .5rem .5rem .75rem;
      }

      &:focus {
        border: 2px solid #286CE2;
      }
    }

    .fly-out {
      padding: .75rem;

      .radio-grp {
        padding: .5rem;
      }

      .fly-out-btn-grp {
        display: flex;
        padding: 1rem 0 0 0;
        border-top: 1px solid #E6E6E6;
        justify-content: space-around; //000-000

        .btn {
          //padding: 0.75rem 2.25rem;
          font-size: 12px;
          padding: 0.75rem 2rem //000-000
        }

        .btn-secondary {
          margin-right: 15px;
          padding: 0.75rem 2rem; //000-000
        }
      }
    }
  }

  .location-wrapper {
    flex: 1 0 20%;
    @media screen and (max-width: $breakpoint-sm) {
      flex: 1 0 100%;
    }
  }

  .search-zip-container {
    display: flex;
    align-items: center;

    .motif-marker {
      position: relative;
      right: 98%;
      @media screen and (min-width: $breakpoint-sm) {
        right: 94%;
      }
      color: #286CE2;
      font-size: 1.5rem!important;
    }
  }

  .fts-look-up-item {
    max-height: 30rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  //Styling for location icon in Free Text Search
  .search-zip-container-fts{
    display: flex;
    align-items: center;
    .change-location-icon-fts{
      color:#ffff;
      font-size: 1.5rem;
    }
  }
  .search-city-zip {
    height: 100%;
    font-size: 18px;
    font-family: "Lato", sans-serif;
    color: $ant-motif-dove-gray;
    flex: 1 0 100%;
    width: 100%;
    padding: 11px 1rem 11px 3rem;
  }

  .location-error {
    border: 1px solid $ant-motif-accent-red;
  }
}

.fly-out-box {
  z-index: 1000;
  position: absolute;
  top: 121%;
  right: 457px;
  width: 20%;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);

  .radio-grp {
    padding: 1rem;
  }

  .fly-out-btn-grp {
    display: flex;
    padding: 1rem 1rem 0 1rem;
    border-top: 1px solid #E6E6E6;

    .btn-secondary {
      margin-right: 15px;
    }
  }
}

.pill-header-wrapper{
  .pill-header-profile{}
  .section-pill-bg{
    p.section-pill-p{
      padding: .75rem 2rem 0 1rem;
    }
  }
}
.empty-field-error{
  border: 1px solid #cc0033;
}
.dropdown-error{
  border-color: #cc0033;
}

.look-up-sub {
  background-color: #f7f7f7;
  padding: 5px 5px;
  font-weight: 600;
  font-size: 12px !important;
}
.show-less-label{
  display:none;
}

.motif-showMore{
  color: black;
  padding-right: 6px;
}
.modal-component-body.location-modal-height {
  @media screen and (max-width: $breakpoint-md) {
    height: 600px;
    overflow-y: scroll;
  }
  .speciality-sticky-button .btn-row-area {
    @media screen and (max-width: $breakpoint-md) {
      position: static;
      box-shadow: none !important;
    }
  }
  .look-up-item-more {
    max-height: 9rem !important;
    overflow: auto;
    height: auto;
  }
}
