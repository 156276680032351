@import "src/assets/styles/partials/variables";

.pf-map-input {
  width: 48% !important;
  float: left;
  padding: 0;

  .directionsPanel {
    float: left;
    width: 100% !important;

    .dirWp {
      input {
        width: 230px;
      }
    }
  }
}
.pf-pre-wrap {
  white-space: pre-wrap;
}

.pf-inline-span {
  display: inline !important;
  span {
    display: inline !important;
  }
}

.pf-back-to-link {
  padding: 10px 0px;
  list-style: none;
  background-color: #fff;

  a {
    cursor: pointer;
  }

  .pf-back-link {
    font-weight: 700;
    border-bottom: none;
    text-decoration: none;

    .pf-back-link-text {
      margin-left: 10px;
    }
    .phone-color {
      color: #286ce2;
    }
  }
}

.pf-map-directions {
  width: 100%;
  padding: 0;
}
.pf-map-view {
  float: left;
  padding: 0;
  position: relative;
  border: 1px solid black;
  width: 315px;
  height: 300px !important;

  @media screen and (min-width: $breakpoint-sm) {
    width: 350px;
    height: 300px !important;
  }
  @media screen and (min-width: $breakpoint-md) {
    width: 450px;
    height: 400px !important;
  }
  @media screen and (min-width: $breakpoint-lg) {
    width: 550px;
    height: 500px !important;
  }
}
.pf-details-award-container {
  .award-container {
    margin: 20px 0 40px 0;
    padding: 0 10px 0 10px;
    .award-image {
      text-align: left;
      @media screen and (max-width: $breakpoint-sm) {
        text-align: left;
        margin-bottom: 1rem;
      }
    }
    .award-title {
      font-size: 1.5rem;
      font-weight: 300;
      margin-bottom: 1.85rem;
    }
    .award-content {
      @media screen and (min-width: $breakpoint-sm) {
        border-left: 2px solid #e3e1e1;
      }
    }
    .award-text {
      min-height: 75px;
    }
  }
}

.pf-map-modal-container {
  .modal-dialog {
    max-width: 1200px;
    .modal-header {
      padding-bottom: 0;
      .close {
        border: none !important;
      }
    }
  }
  .MicrosoftMap.dirSDK .directionsPanel {
    width: 100% !important;
  }
  .award-container {
    margin: 4rem 0;
    textarea {
      min-height: 100px;
      width: 100%;
    }
    // .map-directions {
    //   max-height: 485px;
    //   overflow: scroll;
    // }
    .btn-getdirection {
      // margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  h2 {
    padding-bottom: 0 !important;
    font-size: 1.86rem !important;
    margin-bottom: 0px;
    padding-top: 0px !important;
  }
  .map-img {
    text-align: center;
  }
  .start-container,
  .end-container {
    padding-bottom: 0.5rem;
    flex-basis: 100%;
    .fa-map-marker {
      float: left;
    }
    textarea {
      border: 1px solid black;
    }
  }
  .pf-swap-link-container {
    padding-bottom: 0.5rem;
    a {
      border: none;
      text-decoration: none;
      color: #286ce2;
      font-family: Lato, sans-serif;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  a.ant-action-link {
    padding-right: 0;

    .fa-print {
      color: #fe5e3c !important;
    }
  }
  .direction-container {
    margin: 2rem 0 !important;
    @media screen and (max-width: $breakpoint-md) {
      height: 525px;
      overflow: auto;
    }
  }
  .route-container {
    margin-top: 2.14rem;
    margin-bottom: 0.71rem;
    .prLabel {
      margin: 0 0 0.79rem 0;
      padding: 0px;
      margin-right: 0.5rem;
      line-height: 1.5;
    }
  }
  .directionsPanel {
    max-height: 485px;
    overflow: scroll;
  }
  .modal-body {
    .srch-phrm-map-align {
      padding-right: 15px;
      float: left !important;
      .srch-providr-map {
        width: 100%;
        margin-left: 0px;
        height: 485px;
      }
    }
    #btn-direction-back {
      margin-top: 1rem;
    }
    .print-btn {
      margin-left: 1rem;
      margin-top: 1rem;
    }
    .direction-print-btn {
      margin-top: 1rem;
      margin-left: 1rem;
    }
    .direction-back-btn {
      margin-left: 1rem;
      margin-top: 1rem;
    }
  }

  .modal-directions-btn {
    margin-right: 1rem;
    padding: 0.5rem 1rem;
    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: 1rem;
    }
  }

  .modal-header {
    .close {
      @media screen and (max-width: $breakpoint-xs) {
        top: -4px;
        right: -15px;
      }
    }
  }
}
// @media screen and (min-width: $breakpoint-md) {
//   .srch-providr-map {
//     float: right;
//     margin-top: 0px;
//   }
// }

.pf-fake-show-map {
  // text-align: center;
  margin-bottom: 1rem;
  .base-toggle-body {
    margin-top: 0.5rem;
  }
  a,
  button {
    border: none;
  }
}
//Recent chat styles//
.rc-side-panel {
  overflow-y: auto;
  height: 100%;
}
.model-recent-chat {
  padding: 5rem 1rem 5rem 5.5rem !important;
  @media screen and (min-width: 801px) {
    padding: 1rem 12.5rem 5rem 5rem !important;
  }
}
.model-recent-chat-title {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  margin-bottom: 24px;
}
.rc-rows-container {
  overflow-y: auto !important;
  height: 100% !important;
  margin: 0, 30px, 50px, 30px;
}
.rc-chat-row {
  height: auto;
  padding-left: 16px;
  padding-right: 16px;
}
.rc-chat-detail-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.rc-chat-row-toggle-container {
  width: 30px;
  justify-content: center;
}
.rc-detail-desc {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.rc-chat-title {
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}
.rc-chat-type {
  font-family: Lato, sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.2rem;
}
.rc-desc-go-chat {
  margin-top: 16px;
  padding-right: 15px;
}
.rc-chat-row-description {
  align-self: center;
  margin-bottom: 16px;
}
.rc-close-btn-slider {
  position: absolute;
  bottom: 1%;
  right: 5%;
}
.rc-divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
.rc-header-divider {
  margin: 0 0 24px;
}
.rc-row-devider {
  margin: 24px 0 24px;
}
.rc-no-decorate {
  text-decoration: none !important;
}
.rc-full-width {
  width: 100%;
}
.vc-ra-title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  align-items: center;
}
.rc-go-to-chat {
  padding: 0 0.25rem 0 0.25rem;
}
.rc-back-btn {
  float: none;
  height: 60px;
  width: 60px;
  border: none !important;
}
.backButton {
  font-family: "ant-classic-icons", serif;

  &:before {
    content: $ant-classic-arrow-right;
    font-size: 2rem; // redundant?
    color: #286ce2;
  }
}

.pf-vc-lm-container {
  text-align: end;
}

.pf-vc-bottom-space {
  padding-bottom: 3rem;
}

.BaseFooter {
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem 2rem 1rem 1rem;
  position: absolute;
  width: 100%;
  text-align: end;
}

//Recent chat styles end

.pf-toggle-map-wrapper {
  // text-align: center;
  .pf-toggle-map {
    width: 100%;
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    &.show {
      max-height: 485px;
      transition: max-height 0.25s ease-in;
    }
  }
  .pf-toggle-map-button {
    padding: 1rem 0;
    margin-bottom: 1rem;
    width: 100%;
    background: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    span {
      margin-left: 5px;
      transform: rotateX(0deg);
      transition: transform 0.25s ease-in;
      &:hover {
        cursor: pointer;
      }
    }
    span.flipIcon {
      transform: rotateX(180deg);
      transition: transform 0.25s ease-out;
    }
  }
}

.pf-toggle-map-wrapper {
  .pf-toggle-map.show {
    .srch-providr-map {
      max-height: 485px;
      transition: max-height 0.15s ease-out;
      width: 100%;
      margin-top: 0;
    }
  }
  .pf-toggle-map {
    .srch-providr-map {
      height: 485px;
      max-height: 0px;
      transition: max-height 0.15s ease-out;
      width: 100%;
    }
  }
}

.pro-page-header {
  padding: 1.714rem 0;
}

.pro-page-header h1 {
  margin: auto;
  color: #fff;
  font-size: 32px;
}

.pro-page-body {
  background-color: #fff;
}

.fad-margin-top-50 {
  margin-top: 50px;
  @media (max-width: $breakpoint-md) {
    margin-top: 0px;
  }
  .dropdown-container {
    .psDropdown {
      width: 100%;
    }
  }
}

.fad-margin-auto {
  margin: auto;
}
.fad-margin-top-20 {
  margin-top: 20px;
}
.fad-margin-top-10 {
  margin-top: 10px;
}
.fad-margin-top-5 {
  margin-top: 5px;
}
.fad-search-identification {
  .ant-text-input {
    width: 600px !important;
    max-width: 100%;
  }
  .id-card {
    margin-top: 50px;
    @media (max-width: $breakpoint-md) {
      width: 18.75rem;
    }
  }
  .alpha-prefix-label {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .fad-search-identification {
    padding-top: 0px;
    width: 100%;
    float: none;
    .ant-text-input {
      width: 600px !important;
      max-width: calc(100% + 30px);
    }
    .input-alert {
      .ant-alert {
        width: 600px;
        max-width: calc(100% + 30px);
      }
    }
  }

  .fad-action-buttons #btn-plan-login.btn {
    padding: 6px 12px;
  }
}

.fad-language {
  padding-top: 20px;
  text-align: right;
}

.mbr-top-of-page-wrapper {
  top: 0;
}

.geo-pf-href-no-border {
  border: 0px;
  cursor: pointer;
  display: block;
}

.desktop-map-locations {
  display: none;
}

.fad-pf-search-wrapper {
  padding: 48px 28px;
  margin-top: 2.125rem;
  border: none 0;

  @media screen and (max-width: $breakpoint-sm) {
    padding: 0px;
    padding-bottom: 15px;
  }

  #plan-selection-title {
    margin-left: 0px;
  }
  #type-care,
  #state-dropdown,
  #plan-drop-down {
    margin-bottom: 0px;
  }
  .popover {
    &.top,
    &.bottom,
    &.left,
    &.right {
      z-index: 999999999;
    }
  }

  .fad-pf-input-lbl {
    font-size: 14px;
    font-weight: 600;
  }

  .search-toggle {
    padding: 0 2rem;
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 2rem;
    }

    .pfRadio {
      span {
        @media screen and (min-width: $breakpoint-sm) {
          display: inline-flex;
        }
      }
    }
  }

  .search-input-zip-height {
    min-height: 120px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    .search-input-zip-height {
      min-height: 0px;
    }
  }

  .search-input-name-height {
    min-height: 100px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    .search-input-name-height {
      min-height: 0px;
    }
  }

  #txt-speciiality-description {
    margin-top: 1rem;
  }

  .dropdown-container,
  .search-input-container,
  .showSplDetailsLink,
  #hideSplDetails {
    margin-bottom: 2rem;
  }
  #showSplDetails,
  #hideSplDetails {
    margin-top: 2rem;
    display: inline-block;
  }
  .checkbox-container {
    legend {
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
    label {
      font-weight: 400;
    }
  }
  .show-more-options-container {
    margin-bottom: 2rem;
  }

  .pcLabel {
    margin-right: 0px;
  }
  .popIcon {
    margin-left: 0px;
  }

  .ps-padding-fix {
    padding-right: 0;
  }

  ul.no-padding {
    padding: 0;
    margin-top: 1rem;
  }

  .psArrow.dropdown-icon {
    font-size: 0.9286rem;
    padding-left: 0.9286rem;
    padding-top: 0.07143rem;
  }

  .landing-h2 {
    font-family: Lato, sans-serif;
    font-size: 16px;
  }
  &.spacing-fix {
    padding-top: 29px;
    padding-bottom: 29px;
    margin-top: -20px;
    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 0;
    }
  }

  .landing-header {
    padding: 0 40px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    flex-basis: 100%;
    border-right: solid 1px #e4e4e4;

    &.max-width-fix {
      max-width: 50%;
    }

    &.max-width-70 {
      max-width: 70%;
    }

    .input-alert {
      .ant-alert {
        width: 100%;
      }
    }
    h2 {
      font-weight: bold;
      color: #333;
      font-size: 26px;
    }
    > * {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .plansearch-header {
      padding-left: 20px;
      > * {
        margin-bottom: 8px;
      }
      :last-child {
        margin-bottom: 0;
      }
      .plan-search-logo {
        margin-bottom: 0;
      }
      .landing-h-link {
        font-size: 22px;
        font-weight: bold;
        padding: 0;
        text-decoration: none;
        @media screen and (max-width: $breakpoint-sm) {
          font-size: 20px;
        }
      }
      @media screen and (max-width: $breakpoint-sm) {
        padding: 0;
        .plan-search-logo {
          margin-bottom: 15px;
        }
      }
    }

    &:last-child {
      border: none 0;
    }
    &.plan-search {
      border: none 0;
      flex-direction: row;
      @media screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
        margin-top: 5px;
        padding-bottom: 0;
      }
    }
    @media screen and (max-width: $breakpoint-sm) {
      padding: 0 0 24px;
      margin: 24px 40px 0;
      border: none 0;
      border-bottom: solid 1px #e4e4e4;
      &.max-width-fix,
      &.max-width-70 {
        max-width: 100%;
      }
    }
  }

  .landing-link {
    text-decoration: underline;
    &.bold-txt {
      font-weight: 600;
    }

    &:hover,
    &.no-underline {
      text-decoration: none;
    }
  }

  p.landing-h2 {
    margin-bottom: 5px;
  }

  p.landing-h2,
  a.landing-link {
    @media (max-width: $breakpoint-md) {
      line-height: 1.1;
    }
  }
}
.prefix-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $breakpoint-xs) {
    flex-direction: column;
  }
  #txt-alpha-prefix {
    width: 100%;
    height: 40px;
  }
  #btn-prefix-search {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 36px;
    @media screen and (max-width: $breakpoint-xs) {
      align-self: flex-start;
      margin-top: 25px;
      padding-left: 48px;
    }
  }
}

.login-captcha {
  margin-top: 1.1rem;
}

.fad-pf-search-wrapper {
  &.flex-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .landing-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }

    .pf-toolTip-header {
      padding-bottom: 10px;
      font-size: 17px;
      font-weight: 600;
      margin: 0 0 10px;
    }
    .id-card {
      margin-top: 0;
      max-width: 100%;
    }
    .pf-disclaimer-note {
      padding-top: 10px;
      margin: 15px 0 0;
    }
    &.margin-btm-fix {
      margin-bottom: 32px;
    }
  }
  .spl-brand-wrapper {
    margin: 15px 40px 0;
    @media screen and (max-width: $breakpoint-sm) {
      margin: 0 40px 24px;
    }
  }
  #showSplDetails,
  #hideSplDetails {
    margin-top: 1rem;
    display: inline-block;
  }
  .search-input-container {
    label {
      margin-bottom: 0.7143rem;
    }
    input {
      max-width: 100%;
    }
  }
  .checkbox-container label {
    margin-bottom: 15px;
  }
  .show-more-options-container {
    margin-bottom: 25px;
    a:hover {
      cursor: pointer;
    }
  } //popover is having dropdown content show through, increasing z-index
  .popover {
    z-index: 9999999;
  }
}

.fad-modal-component-footer {
  .btn {
    & + .btn {
      margin-left: 25px;
    }
  }
}

.fad-action-buttons {
  display: flex;
  justify-content: center;
  margin: 1rem 0 1rem 0;

  .btn {
    margin-bottom: 1rem;

    &:not(:only-child) {
      margin-right: 1rem;
    }
  }

  a {
    display: inline-block;

    &:not(:only-child) {
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    .btn {
      width: 100%;
    }
  }
}

.pf-search-footer-container {
  font-weight: 400;
  margin-top: 35px;
  .pf-search-footer-updated {
    font-size: 0.8571rem;
  }
}

b,
strong {
  font-weight: 700;
}

.pro-page-header {
  text-align: center;
  color: #fff;
  margin-top: 65px;
}

.sort-dropdown-container {
  height: 10%;
  font-size: 14px;
  display: inline-block;
  align-items: center;
  > div {
    // overflow: auto; //fix IE issue
  }
  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.sort-results-text {
  margin: 14px 10px 0 0;
  display: inline-block;
}

.fad-pf-filter-container {
  .searchFilterItem {
    margin-bottom: 3px;
    .pcLabel {
      display: inline-flex;
      &::before {
        min-width: 14px;
        min-height: 14px;
        top: 0.2rem;
      }
    }
  }
}

.searchFilterItem label.pcLabel:before,
.searchFilterItemNew label.pcLabel:before  {
  // fix for over-sized checkboxes in public cold state filters
  max-width: 20px;
  max-height: 20px;
}

.pcLabel.focus:before {
  box-shadow: 0 0 8px 1px #005b99 !important;
}

.specialtyScroll {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.personalized-match {
  padding: 0 0 15px 0;
}

.fad-pf-summary-wrapper {
  .fad-tabs-wrapper {
    margin-top: -1rem;
    .provider-container-wrap {
      width: 100%;
      display: block;
      @media screen and (max-width: $breakpoint-sm) {
        width: auto;
      }
    }
  }
  .dropdown-container {
    z-index: 99999999;
    .dropdown-icon {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  .search-results-paragraph {
    margin: 15px 0 10px;
    // width: 50%;
    // padding-right: 1rem;
    // display: inline-block;
    // .search-results-header-line2 {
    //   margin-top: 1rem;
    // }
  }
  .pf-border-right {
    border-right: 1px solid #333;
    margin: 5px;
  }
  .clear-all-link {
    display: line;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    width: max-content;
    padding-left: 10px;
  }
  @media only screen and (max-width: 800px) {
    .search-results-paragraph {
      width: 100%;
    }
  }
  .search-results-info {
    margin-top: 2rem;
    @media screen and (max-width: $breakpoint-sm) {
      .col-sm-5.flex-tile-wrap-mobile {
        .tile-view-wrapper.tile-view-wrapper-border {
          border: 2px solid #286ce2 !important;
          margin-top: 1.07rem;
        }
        .tile-view-wrapper {
          margin-top: 1.07rem;
        }
      }
    }
    .col-sm-5 {
      .flex-tile-wrap {
        height: 51.4rem;
        max-height: 47.6rem;
        margin-top: 2rem;
        overflow-y: auto;
        .search-result-grid {
          .results {
            display: flex;
            .arrow-container {
              height: 4.3rem;
              width: 4.43rem;
              background-color: #f7f7f7;
              float: left;
              margin-right: 1.6rem;
              a {
                .motif-right-arrow {
                  font-size: 1.8rem;
                  text-align: center;
                  display: block;
                  line-height: 4.3rem;
                }
              }
            }
            .drive-search {
              line-height: 60px;
              height: 60px;
              color: #333333;
              font-family: Lato, sans-serif;
              font-size: 14px;
              letter-spacing: 0;
              font-weight: normal;
              margin: 0 0 0 2.1rem;
              span {
                font-weight: bold;
              }
            }
          }
          .fad-flex-tile {
            display: block;
            width: 85%;
            @media screen and (max-width: $breakpoint-sm) {
              margin: 0;
            }
          }
          .tile-view-wrapper {
            box-sizing: border-box;
            width: 315px;
            background-color: #ffffff;
            margin-right: 24px;
            padding: 24px;
            border: 2px solid transparent;
            // border-bottom: 1px solid #dcdcdc!important;
            box-shadow: none;
            @media screen and (max-width: $breakpoint-sm) {
              box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
              border: 1px solid #ccc;
              width: 345px;
            }
            &:hover {
              border-color: #286ce2 !important;
            }
            .tile-checkbox-wrapper {
              @media screen and (max-width: $breakpoint-sm) {
                font-size: 1rem;
                color: #333;
              }
            }
            .fad-flex.fad-f-column.tile-review-flex {
              .tile-review-wrapper {
                padding: 0;
                .popover.top {
                  z-index: 100;
                }
              }
              .level-wrapper {
                padding: 0;
              }
              .address-wrapper {
                .distance-time {
                  // margin-top: 2.5rem;
                  display: flex;
                  align-items: center;
                  .map-teardrop-icon {
                    margin-right: 0.57rem;
                    font-size: 1.14rem;
                    color: #666;
                  }
                  // .summary-dist-text{
                  //  padding-top: .4rem;
                  // }
                  .summary-dist-text.travel-time {
                    padding-top: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .col-sm-7.pf-toggle-map-wrapper {
      .pin-label {
        // float: left;
        color: #37475a;
        font-family: Lato, sans-serif;
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 1.2rem;
        margin-bottom: 1rem;
      }
      .pf-toggle-map.show {
        height: 650px;
        max-height: 650px;
        @media screen and (max-width: $breakpoint-sm) {
          height: auto;
        }
        .srch-providr-map {
          max-height: 650px;
          height: 632px !important;
          @media screen and (max-width: $breakpoint-sm) {
            height: 440px !important;
            max-height: 440px;
          }
        }
      }
    }
  }
  .booking-buttons {
    display: flex;
    flex-flow: column;
    @media screen and (min-width: $breakpoint-md) {
      min-width: 170px;
    }

    .btn {
      margin-bottom: 1rem;
      font-size: 12px;
      padding: 0.5rem 0.5rem;
      width: 170px;
      align-self: flex-end;

      @media screen and (max-width: $breakpoint-md) {
        width: 100%;
      }

      a {
        text-decoration: none !important;
        font-family: Lato, sans-serif;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .search-results-headers {
    display: block;
    width: calc(100% - 35px);
    padding: 0.5rem 0;
  }
  .pf-search-results-action-buttons {
    .btn {
      display: inline;
    }
    margin: 2rem 0 0;
    overflow: auto;
    .action-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  .fake-border {
    border-top: 2px solid #eeeeee;
  }
  .reset-search-container {
    text-align: center;
    margin: 30px 0 30px 0;
  }
  .summary-data-container {
    margin-bottom: 30px;
  }
  .summary-disclaimer {
    margin-top: 30px;
  }
  .alt-toggle-cmp {
    .alt-toggle-header {
      width: calc(100% - 35px);
      .mobile-vertical-spacer {
        margin-bottom: 0px;
      }
      .mobile-header {
        display: none;
      }
    }
  }
}

/* PF award modal wrapper */

.pf-award-modal-container {
  .awards-header {
    margin-bottom: 2rem;
  }
  .award-container {
    margin: 20px 0 40px 0;
    .award-image {
      text-align: center;
    }
    .award-title {
      font-size: 1.05rem;
      font-weight: 600;
      margin-bottom: 1.85rem;
    }
  }
  @media (min-width: 801px) {
    .modal-dialog {
      width: auto !important;
    }
  }
  @media screen and (max-width: $breakpoint-xs) {
    .motif-theme .modal.motif-theme .modal-header .close {
      top: -18px !important;
    }
  }
}

.pf-list-award-modal {
  .list-award-img {
    max-height: 52px;
    max-width: 100px;
  }
}

.pf-medicaresupplement-modal-container {
  .medicaresupplement-header {
    height: 80px;
  }
}

/* Summary page alt toggles only */
.pf-search-results-section {
  h6 {
    margin: 20px 10px 20px 0;
    display: inline-block;
  }

  .alt-toggle-icon {
    display: none !important;
  }
  .address-wrapper {
    a {
      border: none;
      text-decoration: none;
    }
  }
  .specialty {
    margin: 5px 0;
  }
  .provider-name-link {
    border-bottom: none;
    font-size: 18px !important;
    text-transform: capitalize !important;
  }
  .gender-icon {
    float: left;
    margin-right: 5px;
  }
  .network-icon {
    float: left;
    margin-right: 5px;
  }

  .network-text {
    margin-top: 10px;
    padding-top: 3px;
    text-align: right;
  }
}

.pf-details-wrapper {
  .address-wrapper {
    padding-bottom: 0.75rem;
    margin: 0 0 0.75rem;
  }
}
.fad-pf-button-placement {
  padding-bottom: 5px;

  .btn {
    & + .btn {
      margin-left: 5px;
    }
  }
}
.clear-both {
  clear: both !important;
}

.alt-toggle-cmp {
  .alt-toggle-header {
    width: 100%;
  }
  .fad-pf-provider-details-toggle {
    float: right;
  }
  .mobile-vertical-spacer {
    margin-bottom: 2rem;
    &.col-xxs-8.col-sm-2 {
      position: initial;
    }
  }
  .mobile-header {
    margin-bottom: 0.5rem;
    display: block;
  }
}

.pf-add-pointer {
  &:hover {
    cursor: pointer;
  }
}

.base-toggle-container {
  margin-bottom: 0.25rem;
  .base-toggle {
    border: none;
    .base-toggle-header {
      border: none;
      strong {
        font-weight: 400;
      }
    }
    span {
      &::before {
        margin-left: 5px;
      }
    }
  }
  .base-toggle-expanded {
    max-height: 223px !important;
    overflow: auto;
  }
  .base-toggle-body {
    .affiliation-provNm {
      display: block;
    }
    margin-top: 0.5rem;
    div {
      margin: 0.25rem 0;
    }
    .affiliation-provNm-link {
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.fad-pf-summary-wrapper {
  .col-xxs-1,
  .col-xxs-2,
  .col-xxs-3,
  .col-xxs-4,
  .col-xxs-5,
  .col-xxs-6,
  .col-xxs-7,
  .col-xxs-8,
  .col-xxs-9,
  .col-xxs-10,
  .col-xxs-11,
  .col-xxs-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .fad-pf-filter-container {
    .searchFilterItem {
      margin-bottom: 3px;
      .pcLabel {
        display: inline-flex;
        &::before {
          min-width: 14px;
          min-height: 14px;
          top: 0.2rem;
        }
      }
    }
  }
  .base-toggle-icon-expanded.fa.fa-caret-down {
    transform: rotateX(180deg);
    transition: transform 0.25s ease-out;
    color: #0079c2;
  }
  .base-toggle-icon-collapsed.fa.fa-caret-down {
    transform: rotateX(0deg);
    transition: transform 0.25s ease-in;
    color: #0079c2;
  }
  .alt-toggle-icon {
    display: none;
  }
  .award-summary-container {
    .awards,
    .reviews {
      margin-top: 1rem;
    }
  }
  .search-results-headers {
    display: none;
  }
  .semi-section-header {
    margin-top: 2rem;
  }
  .map-teardrop-icon {
    display: inline-block;
    border: none;
    cursor: pointer;
    float: left;
    width: auto;
    margin-right: 0.25rem;
  }

  .address-wrapper {
    border-bottom: 1px solid #979797;
    .address-container {
      font-size: 14px;
      .distance-wrapper {
        .distance-time {
          flex-basis: 50%;
          .motif-marker {
            color: #666;
          }
          .travel-duration {
            padding-top: 3px;
            margin-left: 2px;
          }
        }
      }
      .spaces {
        margin-top: 10px;
        .directions {
          color: #286ce2;
          font-family: Lato, sans-serif;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 17px;
          text-decoration: none;
        }
      }
    }
    &.add-border-bottom {
      padding-bottom: 0.75rem;
      margin: 0 0 0.75rem;
      border-bottom: 1px solid #979797;
    }
    &.remove-border-bottom {
      border: none;
    }
  }

  .address-wrapper-last {
    .address-container {
      float: left;
    }
    &.add-border-bottom {
      padding-bottom: 0.75rem;
      margin: 0 0 0.75rem;
    }
    &.remove-border-bottom {
      border: none;
    }
  }
  .alt-toggle-cmp {
    .alt-toggle-header {
      width: 100%;
    }
    .fad-pf-provider-details-toggle {
      float: right;
    }
    .mobile-vertical-spacer {
      margin-bottom: 2rem;
      &.col-xxs-8.col-sm-2 {
        position: initial;
      }
    }
    .mobile-header {
      margin-bottom: 0.5rem;
      display: block;
    }
  }
  .fad-pf-filter-container {
    .fad-pf-search-filter {
      &.hideFilter {
        display: none;
      }
      .fad-pf-search-toggle {
        a {
          /*display: inline-block;
            width: 100%;
            border-bottom: 1px solid #979797;
            padding: 1rem;
            margin-bottom: 0.5rem;*/
          span.fa {
            float: right;
            margin-left: 5px;
          }
        }
      }
      .fad-pf-data-container {
        margin: 0px;
        .ant-auto-suggest {
          .psDropdown {
            height: auto;
          }
        }
        &.pf-filter-hide {
          max-height: 0;
          overflow: hidden;
          transition: max-height 250ms linear;
        }
        &.pf-filter-show {
          height: auto;
          max-height: 400px;
          transition: max-height 250ms linear;
          overflow: hidden;
        }
      }
    }
    .hide-filter-container {
      display: block;
      margin-bottom: 2rem;
    }
    .apply-filter-container {
      margin: 2rem 0 2rem 0;
      text-align: center;
      width: 100%;
      &.hideFilter {
        display: none;
      }
      .button {
        display: inline-block;
      }
    }
  }
  .pf-search-results-action-buttons,
  .pf-search-details-action-buttons {
    .action-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .sort-results-text {
        margin: 20px 10px 0 0;
      }
      .ant-sm-select .pfSelect .psDropdown {
        width: calc(100% - 3px);
      }
      .btn {
        display: block;
        width: 13.8888rem;
        &.btn-secondary {
          margin-right: 15px;
        }
      }
    }
  }

  .summary-data-container {
    margin-bottom: 1rem;
    .insurance-header {
      margin-bottom: 0.5rem;
    }
    .ant-font-sb {
      margin-bottom: 0.5rem;
    }
  }
}

.pf-search-details-action-buttons {
  margin: 3rem 0;
  .action-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .buttons-new {
    .btn {
      padding: 0 46px;
      box-sizing: border-box;
      height: 37px;
    }
  }
  .secured-btns-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .float-left {
    float: left;
  }
  .margin-rt-15 {
    margin-right: 15px !important;
  }
  .margin-rt-10 {
    margin-right: 10px !important;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .pf-search-details-action-buttons {
    margin: 3rem 1rem;
  }
}

.details-map-pin {
  float: left;
  padding-right: 0.2rem;
}

.fad-pf-pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  .fad-pf-pagination-link a {
    border-bottom: none;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .toggleProviderDiv {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.plansearch-img {
  align-self: center;
  @media screen and (max-width: $breakpoint-sm) {
    align-self: flex-start;
  }
}

.simply-logo {
  max-height: 70px;
  margin-left: -38px;
}

.sny-logo {
  width: 23rem !important;
}

.hbncm-logo {
  width: 100% !important;
  max-height: 80px;
  margin-left: -40px !important;
}

.bcbsmn-logo {
  height: 100px;
  margin-left: -25px;
}

.bcbswn-logo {
  padding: 0px !important;
}

.unicare-logo {
  width: 115px !important;
}

.ahkp-logo {
  padding-top: 10px !important;
}

.kymbs-logo {
  padding-top: 20px !important;
  padding-bottom: 13px !important;
}

.txdcm-logo {
  padding: 3px 0 !important;
  height: 84px !important;
  width: 160px;
}

.cha-logo {
  height: 100px !important;
  padding-top: 8px !important;
}

@media screen and (min-width: 1024px) {
  .simply-logo {
    max-height: 90px;
    margin-left: -50px;
  }
  .unicare-logo {
    width: 115px !important;
  }
  .sny-logo {
    width: 23rem !important;
  }
}

@media screen and (max-width: 768px) {
  .unicare-logo {
    width: 50px !important;
  }
}

@media screen and (min-width: 1024px) {
  .pro-top-of-page-wrapper .ant-task-bar-content {
    top: 102%;
    right: -93%;
  }
  .pro-page-header {
    text-align: left;
    margin-top: 120px;
  }
  .brand-logo {
    height: 85px;
  }
}

@media only screen and (min-width: 800px) {
  .pro-page-header {
    text-align: left;
    margin-top: 100px;
  }
  .pro-page-header h1 {
    float: left;
  }
  .pro-page-header .ant-help-container {
    display: block;
    float: right;
    clear: none;
    margin-top: 0;
  }
}
.view-info-container {
  display: flex;
  align-items: center;
  padding-top: 10px;
  text-align: left;
  .view-info-icon {
    padding-right: 5px;
  }
  .view-info-label {
    padding-top: 2px;
    cursor: pointer;
  }
}

.search-redirect-wrapper {
  .view-info-icon {
    float: left;
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .view-info-container {
    width: 100%;
  }
  .sort-container {
    width: 100%;
    padding: 1rem 0;
  }
  .fad-pf-summary-wrapper .pf-search-results-action-buttons .action-buttons-container .btn {
    width: 10rem;
  }
}

.fcr-plan-progress-widget-container {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.fcr-plan-progress-widget-container > div:first-child:empty + div {
  margin-left: auto;
}

.fcr-fts-available-suggestions {
  width: 100%;
  padding: 13px 15px 13px 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-top: 15px;
}

.mbr-plan-progress-container {
  width: 100%;
  padding: 13px 15px 13px 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;

  .error_card_desc {
    width: 350px;
  }

  .error_card_items {
    display: flex;
    justify-content: space-between;
  }

  .error_card_img {
    display: flex;
    align-items: center;
  }

  .mbr-plan-progress-header {
    width: 100%;
    overflow: auto;
    display: inline-block;
    .plan-progress-title {
      max-width: 77%;
      float: left;
    }
    .plan-progress-title img {
      cursor: pointer;
    }
    .mbr-progress-cost {
      text-align: end;
      width: 30%;
      float: right;
    }
  }

  .mbr-spent {
    color: #0c7905;
  }

  .mbr-limit {
    color: #666666;
  }

  .mbr-plan-progress {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 0px 0px;
    align-items: center;
  }

  .mbr-plan-progress-bar {
    width: 315px;
    background-color: #e0e0e0;
    border-radius: 10px;
  }

  .mbr-plan-progress-bar-fill {
    height: 10px;
    background-color: #108808;
    border-radius: 10px;
  }

  .font-16 {
    font-size: 16px !important;
  }
}

.fcr-action-links {
  display: flex;

  @media screen and (max-width: $breakpoint-sm) {
    flex-flow: column;
  }

  .print-link-anchor {
    display: block;
    padding: 0.25rem 5px;
    @media screen and (min-width: $breakpoint-md) {
      margin-right: 2rem;
      padding: 0.1rem 0;
      display: inline-block;
    }
  }

  .motif-print,
  .motif-message {
    margin-right: 0.5rem;
    color: #949494 !important;
  }

  .print-label,
  .email-label {
    font-weight: 700;
    color: #286ce2;
  }
}

.sort-container {
  width: 100%;
  text-align: left;

  .sort-dropdown {
    float: right;
    .psLabel {
      text-align: left;
    }
    .psDropdown {
      width: 180px;
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
    .psButton {
      width: 12rem;
    }
  }
}

/* PF wrapper for filters and headers of the results */
@media only screen and (max-width: 800px) {
  .fad-pf-filter-container {
    .fad-pf-search-filter {
      &.hideFilter {
        display: none;
      }
      .fad-pf-search-toggle {
        a {
          display: inline-block;
          width: 100%;
          border-bottom: 1px solid #979797 !important;
          padding: 1rem;
          margin-bottom: 0.5rem;
          span.fa {
            float: right;
            margin-left: 5px;
          }
        }
      }
      .fad-pf-data-container {
        margin: 0px;
        .ant-auto-suggest {
          .psDropdown {
            height: auto;
          }
        }
        &.pf-filter-hide {
          max-height: 0;
          overflow: hidden;
          transition: max-height 250ms linear;
        }
        &.pf-filter-show {
          height: auto;
          max-height: 400px;
          transition: max-height 250ms linear;
          overflow: hidden;
        }
      }
    }
    .hide-filter-container {
      display: block;
      margin-bottom: 2rem;
      a {
        border-bottom: 1px solid !important;
      }
    }
    .apply-filter-container {
      margin: 2rem 0 2rem 0;
      text-align: center;
      width: 100%;
      &.hideFilter {
        display: none;
      }
      .button {
        display: inline-block;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .fad-pf-filter-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    // margin-bottom: 65px;
    .searchFilterItem {
      margin-bottom: 3px;
      padding-right: 5px;
      width: 100%;
      @media screen and (min-width: $breakpoint-sm) {
        .pcLabel {
          display: inline-flex;
          &::before {
            min-width: 18px;
            min-height: 18px;
          }
        }
      }
    }
    .fad-pf-search-filter {
      min-width: 17%;
      width: 25%;
      padding-right: 5px;
      .fad-pf-search-toggle {
        .toggle-title {
          font-weight: bold;
        }
        a {
          display: inline-block;
          border: none;
          padding: 0px;
          width: auto;
          span.fa {
            float: right;
            margin-left: 5px;
          }
        }
      }
      .fad-pf-data-container {
        margin: 0px;
        .ant-auto-suggest {
          .psDropdown {
            height: auto;
          }
        }
        &.pf-filter-hide {
          max-height: 0;
          overflow: hidden;
          transition: max-height 250ms linear;
        }
        &.pf-filter-show {
          height: auto;
          max-height: 400px;
          transition: max-height 250ms linear;
          overflow: hidden;
        }
      }
    }
    .hide-filter-container {
      display: none;
    }
    .apply-filter-container {
      text-align: center;
      margin: 0;
      width: 16.3333%;
      padding: 0px;
      .button {
        display: inline-block;
      }
    }
  }
}

/*Compare providers css -start*/
.pf-compare-view {
  display: block;
  line-height: 1.42857;
  background-color: #fff;
  font-family: "Open Sans", "open-sans", sans-serif;
  color: #333;
  font-weight: 400;
  padding: 0;
  margin: 40px 0px 0px 0px;
  overflow-y: hidden;
  overflow-x: hidden !important;
  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 0px;
  }
  p {
    line-height: inherit;
  }
  .insurance-label {
    font-size: 18px;
  }
}

.pf-compare-wrapper .pf-compare-item-container {
  display: flex;
  justify-content: flex-start;
  // margin-bottom: 25px;
  &:nth-child(even) {
    background: #eee;
  }

  @media screen and (max-width: $breakpoint-xs) {
    display: block;
  }
}

.pf-address-item {
  .pf-compare-item {
    word-break: break-word;
  }
}

.m-label {
  font-weight: 600;
  width: 100%;
}
@media screen and (min-width: $breakpoint-xs) {
  .m-label {
    display: none;
    font-weight: 600;
  }
}

.pf-compare-wrapper .pf-compare-item-container .pf-compare-item {
  display: flex;
  flex-wrap: wrap !important;
  align-self: stretch;
  flex: 1 1 25%;
  padding: 1.1rem 10px;
  border-right: 1px solid #ccc;

  a {
    border-bottom: none;
    cursor: pointer;
    .fa {
      top: 2px;
    }
  }

  // &:first-child {
  //   width: 150px;
  // }
  &:last-child {
    border-right: none;
  }
  .provider-header-text-results {
    color: #333333;
    font-size: 1.25rem;
  }
  .provider-at-text {
    color: #333 !important;
  }

  @media screen and (max-width: $breakpoint-xs) {
    border-right: none;
    &:first-child {
      width: 100%;
      // display: none;
    }
    &.first {
      display: none;
    }
    padding: 1rem 10px;
    p {
      margin-bottom: 0px;
      font-size: 1.25rem;
    }
  }
}

.pf-compare-item-alignment {
  display: block !important;
}

.pf-compare-wrapper {
  .pf-compare-item-container {
    .address-wrapper-eyc {
      flex-wrap: nowrap;

      .fa-map-marker {
        font-size: 18px;
      }
    }
  }
}
.pf-compare-wrapper .pf-compare-item-container .pf-compare-item .pf-max-width {
  width: 100%;
}

.pf-header-text {
  width: 100%;
  font-weight: 600;
}
.pf-award-image-container img {
  display: flex;
  flex-direction: column;
}
.pf-award-image {
  display: inline-block;
  margin: 5px 0;
}
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(2):first-child,
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(2):first-child ~ .pf-doctor-accepts-medicaid {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 50%;
}
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(3):first-child,
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(3):first-child ~ .pf-doctor-accepts-medicaid {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 33.3333%;
}
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(4):first-child,
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(4):first-child ~ .pf-doctor-accepts-medicaid {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 25%;
}

.pf-compare-dropdown-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(2):first-child,
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(2):first-child ~ .pf-doctor-accepts-medicaid {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 50%;
}
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(3):first-child,
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(3):first-child ~ .pf-doctor-accepts-medicaid {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 33.3333%;
}
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(4):first-child,
.pf-compare-dropdown-wrapper .pf-doctor-accepts-medicaid:nth-last-child(4):first-child ~ .pf-doctor-accepts-medicaid {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 25%;
}
.pf-compare-btn {
  margin: 20px 0px 20px 0px;
}

/*Compare providers css -end*/

.disclaimermsg {
  margin-bottom: 20px !important;
}

.disclaimer-wrapper {
  margin: 40px auto 20px auto;

  .tabs .tab-content .tab-body {
    padding-left: 0;
    padding-bottom: 15px;
  }

  &.spacing-btm {
    margin-bottom: 100px;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    padding: 0.5rem;
    &.spacing-btm {
      margin-bottom: 50px;
    }
  }

  .show-more {
    text-align: right !important;
  }

  .header-text {
    margin-bottom: 10px;
  }
}

.modal-component-body {
  .more-info-header {
    padding-bottom: 1rem;
  }
  .text-container {
    padding-bottom: 5px;
    .text-data {
      padding-top: 5px;
      text-align: justify;
    }
  }
}

.base-toggle-icon-expanded.fa.fa-caret-down {
  transform: rotateX(180deg);
  transition: transform 0.25s ease-out;
  color: #0079c2;
}
.base-toggle-icon-collapsed.fa.fa-caret-down {
  transform: rotateX(0deg);
  transition: transform 0.25s ease-in;
  color: #0079c2;
}
/*Emulate Badge - start*/

.pf-emulate-badge {
  width: 0.1em;
  height: 100px;
  padding-top: 1.2em;
  padding-right: 0.1em;
  cursor: pointer;
  border-radius: 7px 0px 0px 7px;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  position: fixed;
  top: 250px;
  left: 0;
}

.pf-emulate-badge:hover {
  width: 30px;
}

.fad-pf-details-wrapper,
.modal-component-body {
  .summary-data-container {
    margin-bottom: 20px;

    .column-holder {
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
      .noprocedure-card {
        padding-right: 0px;
        border-right: none;
        a {
          font-size: 26px;
          font-family: "Montserrat";
        }
      }
    }
    .card-align {
      margin-bottom: 3rem;
      padding-left: 0px;
      @media screen and (max-width: $breakpoint-sm) {
        margin-top: 1.5rem;
        padding-right: 0px;
      }
    }
    // < pricing ON
    .details-column-1-on {
      border-right: solid 1px #e3e1e1;
    }
    .details-column-2-on {
      border-left: solid 1px #e3e1e1;
    }
    // pricig ON >

    .details-column-1 {
      border-right: solid 1px #fff;
    }
    .details-column-2 {
      border-left: solid 1px #fff;
    }

    ul.pricing-included,
    ul.pricing-services {
      padding-left: 15px;
    }

    .tab-content-sep-wrapper {
      padding: 1rem;
      padding-right: 5px;
      &.padding-top-no {
        padding-top: 0;
      }
      &.margin-btm-1rm {
        margin-bottom: 1rem;
      }
    }
    .bg-skyblue {
      background-color: #eff5ff;
      .tab-content-section:last-child {
        margin-bottom: 0;
      }
    }

    span.fa.fa-question-circle {
      color: #286ce2;
    }

    .distance-block {
      margin: 10px 0;
      .details-map-pin {
        margin-right: 5px;
      }
      .travel-time {
        display: inline-block;
        margin-left: 1.5rem;
        a {
          margin-bottom: 5px;
          margin-right: 3px;
        }
      }
    }

    .details-column-1,
    .details-column-2,
    .details-column-1-on,
    .details-column-2-on {
      // border-right: solid 1px #e3e1e1;
      // border-left: solid 1px #e3e1e1;
      padding: 2rem;

      @media screen and (max-width: $breakpoint-sm) {
        border: solid 0px #e3e1e1;
      }

      h2 {
        margin-bottom: 2rem;
      }
      .pricing-col-content,
      .estim-col-content {
        margin-bottom: 1.5rem;

        h5 {
          font-weight: 500;
          margin-bottom: 2rem;
        }
      }
      .pricing-left-container {
        h5 {
          margin-bottom: 1rem;
        }
        .left-pricing-group {
          margin-top: 2rem;
          h6 {
            font-size: 1.06rem;
            font-weight: bold;
          }
        }
      }
    }

    .detail-group {
      width: 100%;
      padding-left: 0;
      margin-bottom: 2rem;

      .detail-left,
      .detail-right {
        padding-left: 0;
        padding-right: 0;

        .price {
          font-size: 1.25rem;
        }
      }
      .description {
        border-top: solid 1px #000;
        padding-left: 0;
      }

      .details-foot {
        font-size: 1.1rem;

        .motif-icon.motif-help {
          font-size: 1.1rem;
        }
      }
    }

    .selector-price-prod-container {
      padding-bottom: 2.5rem;
      width: 50%;
      font-weight: 600;
      .pricing-dropdown-cmp {
        fieldset,
        input {
          width: 100%;
          top: -0.28rem;
          font-weight: 400;
        }
        .psDropdown.active {
          top: 100%;
        }
      }
      .pricing-dropdown-cmp-cpt {
        fieldset,
        input {
          width: 100%;
          top: -0.28rem;
          font-weight: 400;
        }
        button {
          visibility: hidden;
        }
        .psDropdown.active {
          top: 100%;
        }
      }

      .selector-label {
        margin-bottom: 0.5rem;
      }
      input {
        width: 45%;
        padding: 0.5rem 0.75rem;
        text-overflow: ellipsis;
        padding-right: 2rem;
      }

      @media screen and (max-width: $breakpoint-sm) {
        .selector-price-prod-container {
          width: 100%;
        }
      }
    }
  }

  .procedure-medicalcode {
    .procedure-dropdown.selector-price-prod-container {
      width: 13%;
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
      @media print and (max-width: $breakpoint-sm) {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .procedure-dropdown {
      .pfSelect-legend {
        width: 150%;
        white-space: nowrap;
      }
    }
    .selector-price-prod-container.pricing-value {
      width: 50%;
      @media screen and (max-width: $breakpoint-sm) {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
      }

      @media print and (max-width: $breakpoint-sm) {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
      }
    }
    .cpt-dropdown {
      width: 25%;
      padding-left: 3rem;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: $breakpoint-md) {
        padding-left: 1rem;
      }
      .pricing-dropdown-cmp {
        fieldset,
        input {
          width: 100%;
        }
        .psDropdown.active {
          top: 100%;
        }
      }
      @media screen and (max-width: $breakpoint-sm) {
        margin-top: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0px;
        width: 100%;
      }

      @media print and (max-width: $breakpoint-sm) {
        margin-top: 0;
        padding-top: 10px;
        padding-bottom: 0;
        padding-left: 0px;
        width: 100%;
      }
    }
    .cpt-sub-dropdown {
      width: 100%;
      margin-top: 17px;
    }
  }

  .pf-location-block-end {
    //color: #666;
    text-align: right;
    a {
      float: none;
    }
    .travel-time {
      margin-top: 5px;
      a {
        float: none;
        margin-right: 7px;
      }
    }
    .details-space {
      margin-top: 1rem;
      a {
        font-weight: bold;
      }
    }
  }

  .location-icon-wrapper {
    display: flex;
    flex-flow: row;
  }
  .location-cost-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
    font-size: 18px;
  }
  .address-wrapper {
    .map-teardrop-icon {
      display: inline-block;
      border: none;
      cursor: pointer;
      float: left;
      width: auto;
      margin-right: 0.25rem;

      margin-bottom: 60px;
    }
    a {
      border: none;
    }
  }

  div > .clearfix:not(:last-child) {
    .address-wrapper {
      border-bottom: 1px solid #979797;
      margin: 0 0 1.75rem;

      &.add-border-bottom {
        padding-bottom: 0.75rem;

        border-bottom: 1px solid #979797;
      }

      &.remove-border-bottom {
        border: none;
      }
    }
  }
}

.fad-pf-details-wrapper .procedure-medicalcode .selector-price-prod-container.pricing-value.containerWidth {
  width: 50%;
  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
  input {
    width: 100%;
    font-weight: normal;
  }

  @media print and (max-width: $breakpoint-sm) {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
}

.fad-pf-details-wrapper-fc,
.modal-component-body {
  .summary-data-container {
    margin-bottom: 20px;

    .column-holder {
      border: solid 1px #e3e1e1;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
      .noprocedure-card {
        padding-right: 0px;
        border-right: none;
        a {
          font-size: 26px;
          font-family: "Montserrat";
        }
      }
    }
    .card-align {
      margin-bottom: 3rem;
      padding-left: 0px;
      @media screen and (max-width: $breakpoint-sm) {
        margin-top: 1.5rem;
        padding-right: 0px;
      }
    }
    // < pricing ON
    .details-column-1-on {
      border-right: solid 1px #e3e1e1;
    }
    .details-column-2-on {
      border-left: solid 1px #e3e1e1;
    }
    // pricig ON >

    .details-column-1 {
      border-right: solid 1px #fff;
    }
    .details-column-2 {
      border-left: solid 1px #fff;
    }

    ul.pricing-included,
    ul.pricing-services {
      padding-left: 15px;
    }

    .tab-content-sep-wrapper {
      padding: 1rem;
      padding-right: 5px;
      &.padding-top-no {
        padding-top: 0;
      }
      &.margin-btm-1rm {
        margin-bottom: 1rem;
      }
    }
    .bg-skyblue {
      background-color: #eff5ff;
      .tab-content-section:last-child {
        margin-bottom: 0;
      }
    }

    span.fa.fa-question-circle {
      color: #286ce2;
    }

    .distance-block {
      margin: 10px 0;
      .details-map-pin {
        margin-right: 5px;
      }
      .travel-time {
        display: inline-block;
        margin-left: 1.5rem;
        a {
          margin-bottom: 5px;
          margin-right: 3px;
        }
      }
    }

    .details-column-1,
    .details-column-2,
    .details-column-1-on,
    .details-column-2-on {
      // border-right: solid 1px #e3e1e1;
      // border-left: solid 1px #e3e1e1;
      padding: 2rem;

      @media screen and (max-width: $breakpoint-sm) {
        border: solid 0px #e3e1e1;
      }

      h2 {
        margin-bottom: 2rem;
      }
      .pricing-col-content,
      .estim-col-content {
        margin-bottom: 1.5rem;

        h5 {
          font-weight: 500;
          margin-bottom: 2rem;
        }
      }
      .pricing-left-container {
        h5 {
          margin-bottom: 1rem;
        }
        .left-pricing-group {
          margin-top: 2rem;
          h6 {
            font-size: 1.06rem;
            font-weight: bold;
          }
        }
      }
    }

    .detail-group {
      width: 100%;
      padding-left: 0;
      margin-bottom: 2rem;

      .detail-left,
      .detail-right {
        padding-left: 0;
        padding-right: 0;

        .price {
          font-size: 1.25rem;
        }
      }
      .description {
        padding-left: 0;
        font-size: 12px;
        color: #666;
        text-align: left;
      }

      .details-foot {
        font-size: 1.1rem;

        .motif-icon.motif-help {
          font-size: 1.1rem;
        }
      }
    }

    .selector-price-prod-container {
      padding-bottom: 10px;
      width: 100%;
      font-weight: 600;
      .pricing-dropdown-cmp {
        fieldset,
        input {
          width: 100%;
          top: -0.28rem;
          font-weight: 400;
        }
        .psDropdown.active {
          top: 100%;
        }
      }
      .pricing-dropdown-cmp-cpt {
        fieldset,
        input {
          width: 100%;
          top: -0.28rem;
          font-weight: 400;
        }
        button {
          visibility: hidden;
        }
        .psDropdown.active {
          top: 100%;
        }
      }

      .selector-label {
        margin-bottom: 0.5rem;
      }
      input {
        width: 100%;
        padding: 0.5rem 0.75rem;
        text-overflow: ellipsis;
        padding-right: 2rem;
        font-weight: normal;
      }

      @media screen and (max-width: $breakpoint-sm) {
        .selector-price-prod-container {
          width: 100%;
        }
      }
    }
  }
  .pricing-dropdown-cmp.cpt-sub-dropdown {
    width: 100%;
    fieldset.ant-auto-suggest {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }

  .procedure-medicalcode {
    .procedure-dropdown.selector-price-prod-container {
      width: 13%;
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
      @media print and (max-width: $breakpoint-sm) {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .procedure-dropdown {
      .pfSelect-legend {
        width: 150%;
        white-space: nowrap;
      }
    }
    .selector-price-prod-container.pricing-value {
      width: 50%;
      @media screen and (max-width: $breakpoint-sm) {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
      }

      @media print and (max-width: $breakpoint-sm) {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
      }
    }
    .cpt-dropdown-fc {
      width: 198px;
      padding-left: 3rem;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: $breakpoint-md) {
        padding-left: 1rem;
      }
      .pricing-dropdown-cmp {
        fieldset,
        input {
          width: 100%;
        }
        .psDropdown.active {
          top: 100%;
        }
      }
      @media screen and (max-width: $breakpoint-sm) {
        margin-top: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0px;
        width: 100%;
      }

      @media print and (max-width: $breakpoint-sm) {
        margin-top: 0;
        padding-top: 10px;
        padding-bottom: 0;
        padding-left: 0px;
        width: 100%;
      }
    }
    .cpt-sub-dropdown {
      width: 100%;
      margin-top: 17px;
    }
  }

  .pf-location-block-end {
    //color: #666;
    text-align: right;
    a {
      float: none;
    }
    .travel-time {
      margin-top: 5px;
      a {
        float: none;
        margin-right: 7px;
      }
    }
    .details-space {
      margin-top: 1rem;
      a {
        font-weight: bold;
      }
    }
  }

  .location-icon-wrapper {
    display: flex;
    flex-flow: row;
  }
  .location-cost-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
    font-size: 18px;
  }
  .address-wrapper {
    .map-teardrop-icon {
      display: inline-block;
      border: none;
      cursor: pointer;
      float: left;
      width: auto;
      margin-right: 0.25rem;

      margin-bottom: 60px;
    }
    a {
      border: none;
    }
  }

  div > .clearfix:not(:last-child) {
    .address-wrapper {
      border-bottom: 1px solid #979797;
      margin: 0 0 1.75rem;

      &.add-border-bottom {
        padding-bottom: 0.75rem;

        border-bottom: 1px solid #979797;
      }

      &.remove-border-bottom {
        border: none;
      }
    }
  }
  .fc-panel {
    margin-left: -18px;
    width: auto;
    background: #f7f7f7;
    padding-left: 15px;
    width: 113%;
  }
  .expand-header {
    color: #333;
    font-size: 14px;
  }
  .uxd-expansion-panel .uxd-expansion-button {
    color: #333;
    width: 30rem;
    span.cost-share {
      text-align: end;
      float: left;
      width: 60%;
    }
  }
}

.map-loader {
  margin-bottom: 20px;
  .ant-data-load {
    padding: 5px !important;
  }
}
/*Emulate Badge - end*/

.cost-info div.row {
  padding: 5px 0px 5px 0px;
}
.col-sm-3.fad-side-filter {
  margin-top: 20px;
  @media screen and (min-width: $breakpoint-sm) {
    padding-right: 0px;
  }
  .alt-toggle-header {
    width: auto;
  }
  // border-right: 2px solid #eeeeee;
  .alt-toggle-plus,
  .alt-toggle-minus {
    margin-right: 15px;
  }
  @media screen and (min-width: $breakpoint-sm) {
    border-right: 2px solid #eeeeee;
  }
}

.clear-filter {
  text-align: right;
  float: right;
  a {
    cursor: pointer;
    margin-right: 1rem;
    &:focus {
      border: 1px solid #dcdcdc;
    }
  }
}

.filter-actions {
  padding-bottom: 30px;
}

.filter-align {
  width: min-content;
}

a.align-icon {
  position: absolute;
}

.specialty-dropdown {
  height: 13rem;
  .ant-text-input {
    width: 400px;
    @media screen and (min-width: $breakpoint-sm) {
      width: 225px;
    }
    @media screen and (min-width: $breakpoint-md) {
      width: 300px;
    }
  }
  .psLabel {
    word-break: break-word;
  }
  .psDropdown {
    // width: calc(100% - 39px);
    &.active {
      max-height: 9.5rem;
    }
  }
}

.dental-vision-wrapper {
  margin-top: 2rem;
  margin-bottom: 1.1rem;
  .keep-left {
    float: left;
  }
  .blank-style {
    font-weight: normal !important;
    padding-bottom: 15px;
  }
  .padding-left {
    padding-left: 2px;
  }
  .full-width {
    width: 100%;
  }
  label {
    /*font-size: 0.875em;*/
    font-weight: normal !important;
  }
  .reason-label {
    font-weight: 600;
    font-size: 1rem;
    padding-bottom: 0.7143rem;
  }
  .radio-container {
    width: 90%;
    .prLabel {
      padding-left: 0px;
    }
  }
}

.fad-error {
  color: #cc0033;
  font-weight: normal !important;
  padding-bottom: 20px;
  /* margin-top: 19px; */
  img {
    width: 25px;
  }
  a {
    cursor: pointer;
    color: #cc0033;
    font-weight: bold !important;
  }
}

.search-results-wrapper {
  padding-left: 30px !important;
}

.fad-search-filter {
  margin-bottom: 4rem;

  .pcLabel,
  .prLabel {
    font-size: 14px !important;
    font-weight: 400;
    margin-right: 0;
    padding-left: 0;
  }

  legend,
  label {
    width: inherit;
  }

  .fad-pcp-filter {
    padding-bottom: 0.5rem;
  }

  .filter-checkbox-label {
    display: flex;
    padding-right: 20px;
  }
  .alt-toggle-cmp .alt-toggle-header .alt-toggle-body {
    width: 20rem;
    .filter-check {
      display: inline-block;
    }
  }

  @media screen and (min-width: $breakpoint-sm) {
    .alt-toggle-cmp .alt-toggle-header .alt-toggle-body {
      width: 8rem;
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    .alt-toggle-cmp .alt-toggle-header .alt-toggle-body {
      width: 10rem;
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    .alt-toggle-cmp .alt-toggle-header .alt-toggle-body {
      width: 13rem;
    }
  }
}

.helpful-hints {
  .hint-header {
    padding-bottom: 1rem;
  }
  .hint {
    padding: 0.5rem 0;
  }
  padding-right: 1rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #eeeeee;
}
.helpful-hints-wrapper {
  .helpful-hints {
    .hint-header {
      padding-bottom: 1rem;
    }
    .hint {
      padding: 0.5rem 0;
    }
    padding-right: 1rem;
    padding-bottom: 0rem;
    padding-top: 0px;
    border-bottom: 0px solid #ffffff;
  }
}
.eyc-Banner {
  div {
    text-align: right;
    span {
      font-weight: 600;
      padding: 0px 5px 0px 5px;
    }
  }
  margin-bottom: 1rem;
  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 1rem;
  }
}
.filter-toggle {
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 1rem;
  .toggle-icon {
    float: right;
    padding-right: 15px;
  }
  .toggle-header {
    padding-bottom: 1rem;
  }
  .toggle-body {
    padding-bottom: 1rem;
    .ant-sm-select {
      padding-right: 1rem;
    }
  }
  .long-toggle-heading {
    display: inline-block;
    max-width: 80%;
    @media screen and (max-width: $breakpoint-md) {
      max-width: 70%;
    }
  }
}

.greyOut {
  color: grey;
  border-bottom: none;
  font-weight: 600;
  opacity: 0.8;
}
.select-header {
  min-width: 50px;
  word-break: break-word;
  hyphens: auto;
}
.recognition-header {
  word-break: break-word;
  hyphens: auto;
}

.emulate-border {
  border-top: 2px solid #eeeeee;
  width: 100%;
  margin: 10px 0px 10px 0px;
  padding-bottom: 10px;
}

ul.pfbreadcrumb {
  padding: 10px 0px;
  list-style: none;
  background-color: #fff;
  a {
    cursor: pointer;
  }
}
ul.pfbreadcrumb li {
  display: inline;
  font-size: 14px;
}
ul.pfbreadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: url("/assets/images/common/bc_right_arrow.png");
}
ul.pfbreadcrumb li a {
  color: #0275d8;
  text-decoration: none;
  font-size: 14px;
}
ul.pfbreadcrumb li a:hover {
  color: #01447e;
  text-decoration: none;
}
a.popIcon-wrapper {
  text-decoration: underline;
  margin-left: 0px;
  display: flex;
  text-indent: 0px;
}

.leaving-disclaimer-btns {
  margin-bottom: 2rem !important;

  .btn-primary {
    margin-left: 20px;
  }
  @media screen and (max-width: $breakpoint-xs) {
    .btn {
      width: 100%;
      margin-bottom: 15px;
    }
    .btn-primary {
      margin-left: 0px;
    }
  }
}

.fad-float-right {
  float: right;
}
.fad-float-left {
  float: left;
}

/* PF homehealthcare modal wrapper */

.pf-homehealthcare-modal-container {
  h2 {
    margin-bottom: 20px;
  }
  .homehealthcare-header {
    margin-bottom: 2rem;
  }
  .homehealthcare-container {
    margin: 20px 0 40px 0;
    .homehealthcare-image {
      text-align: center;
    }
    .homehealthcare-title {
      font-size: 1.05rem;
      font-weight: 600;
    }
  }
  @media (min-width: 801px) {
    .modal-dialog {
      max-width: 660px;
    }
  }
}

div.account-balance {
  margin-top: 1em;
}

.print-tabs {
  display: none;
}
.print-toggle {
  display: none;
}
.print-disclaimer {
  display: none;
}
.print-disclaimer-heading {
  display: none;
}
.print-disclaimer-rule {
  display: none;
}
.print-award {
  display: none;
}
.print-search-results-paragraph {
  display: none;
}
.print-tooltip {
  display: none;
  font-size: 0.875rem;
}
.print-cost-disclaimer-tab-content {
  display: none;
}

@mixin print-mixin() {
  .mbr-top-of-page-wrapper {
    display: none;
  }
  .ant-alert {
    display: none;
  }
  .nav-links {
    display: none;
  }
  .fad-side-filter {
    display: none;
  }
  .pf-toggle-map-wrapper {
    display: none;
  }
  .checkbox-print {
    display: none;
  }
  .ant-tab-header {
    display: none;
  }
  .print-margin-label {
    margin-left: 8.33333333%;
  }
  .pf-search-results-action-buttons {
    display: none;
  }
  .fad-pf-pagination-container {
    display: none;
  }
  .back-to-top-dir {
    display: none;
  }
  .fad-search-results {
    width: 100%;
  }
  .brand-logo {
    display: none;
  }
  .fad-language {
    display: none;
  }
  .show-more-btn {
    display: none;
  }
  .pf-emulate-badge {
    display: none;
  }
  .m-label {
    display: none;
  }
  .nav-links {
    display: none;
  }
  .pf-compare-btn {
    display: none;
  }
  .tabs {
    display: none;
  }
  .print-tabs {
    display: block;
  }
  .new-line {
    clear: both;
    padding-top: 20px;
  }
  .fad-pf-details-wrapper {
    .details-column-2 {
      margin-left: 15px;
    }
  }
  .email-print-container {
    display: none;
  }
  .detail-helpfulhints {
    display: none;
  }
  .print-toggle {
    display: block;
    word-break: break-word;
  }
  .base-toggle-container {
    display: none;
  }
  .provider-details {
    padding-top: 0px;
  }
  .cost-tab-disc {
    display: none;
  }
  .print-link {
    display: none;
  }
  .dismissable-alert {
    display: none;
  }
  .desktop-disclaimer {
    display: none;
  }
  .print-disclaimer {
    display: block;
  }
  .print-disclaimer-heading {
    display: block;
    margin-top: 1rem;
  }
  .print-disclaimer-rule {
    display: block;
  }
  .pf-award-modal-container {
    background: #ffffff !important;
  }
  .modal-component-footer {
    display: none;
  }
  .beFirstReview {
    display: none;
  }
  .modal {
    .modal-header {
      .close {
        display: none;
      }
    }
  }
  .print-award {
    display: block;
    .award-container {
      position: relative;
    }
  }
  .pf-details-award-container {
    .award-container {
      position: relative;
    }
  }
  .award-image {
    width: 100%;
    margin: 1rem 0;
  }
  .pf-details-award-container .award-container .award-image {
    text-align: left !important;
  }
  .mbr-page-header {
    padding: 0px;
  }
  .search-results-paragraph {
    display: block;
  }
  .print-search-results-paragraph {
    display: block;
  }
  .print-tooltip {
    display: block;
  }
  .popIcon {
    display: none;
  }
  .transportation-print {
    display: none;
  }
  .mobile-provider-label {
    margin-left: 8.33333333%;
  }
  .cost-disclaimer-tab-content {
    display: none;
  }
  .print-cost-disclaimer-tab-content {
    display: block;
  }
  .fp-map-container {
    display: none;
  }

  //Driving directions printing styles
  .pf-map-modal-container {
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    overflow: visible !important;

    .award-container {
      margin: 0;
    }

    .pf-map-directions {
      width: 100%;
      padding-bottom: 1rem;

      p {
        font-size: 0.75rem;
      }

      .MicrosoftMap {
        .dirIcon {
          img {
            height: 25px;
          }
        }
      }
    }

    .pf-map-view {
      //page-break-before: always;
      width: 100%;
      height: 500px !important;
      border: none;
    }

    .modal-dialog {
      visibility: visible !important;
      overflow: visible !important;
      margin: -155px auto;
      .modal-header {
        padding-bottom: 0;
      }
    }

    div.print-break-point {
      clear: both;
      display: block;
      break-before: page;
      page-break-after: always;
      position: relative;
    }

    .modal-content {
      max-width: 100%;
      margin: 0 auto;
      border: none;
      padding-bottom: 20px;
      width: 990px;
      max-width: 990px;
      .modal-title {
        padding-bottom: 0;
        font-size: 1.86rem;
        margin-bottom: 0px;
        padding-top: 0px;
      }
    }

    .directionsPanel {
      display: inline;
      max-height: 100%;
      overflow: visible;
    }

    .bm_bottomLeftOverlay,
    .bm_bottomRightOverlay {
      display: none !important;
    }
  }
}

@media print {
  @include print-mixin();

  .no-print {
    display: none !important;
  }
}

.align-right {
  float: right;
}

.print-preview {
  @include print-mixin();

  .no-print {
    display: none;
  }
}

.distance-dropdown {
  .pfSelect-legend {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0.7rem;
  }
}

.results-label-header {
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}

.mobile-label {
  @media (min-width: $breakpoint-sm) {
    display: none;
  }
  div.account-balance {
    margin-bottom: 1em;
  }
}
.no-score {
  width: 100%;
}

.awards-link {
  word-break: break-word;
  hyphens: auto;
  cursor: pointer;
}

.websiteUrl {
  word-break: break-word;
  hyphens: auto;
}

.compareEmail {
  word-break: break-word;
  hyphens: auto;
}

.provider-container {
  display: flex;
  .gridBoxes button.btn {
    width: auto;
    padding: 5px 10px 10px 10px;
    height: 30px;
    margin-right: 9px;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    font-size: 12px;
  }
  @media only screen and (max-width: $breakpoint-sm - 1) {
    flex-wrap: wrap;
    justify-content: flex-start;
    .gridBoxes button.btn {
      margin-right: 12px;
      margin-bottom: 12px;
    }
  }
  a {
    cursor: pointer;
  }
  .provider-details-container {
    .picture-block {
      display: flex;
      flex-flow: row;
      margin-bottom: 0.86rem;
      .picture-id {
        flex-basis: 20%;
        margin-right: 8px;
        margin-top: 0.29rem;
      }
      .provider-name-row {
        flex-flow: column;
        flex-basis: 100%;
        .picture-info {
          display: flex;
          flex-flow: column;
        }
        &.provider-name-row-withimage {
          flex-basis: 75%; //mainly an IE fix
        }
      }
    }
  }
}

.provider-d-wrapper {
  padding: 0.25rem 0;
}

.provider-links {
  margin-top: 5px;
  a {
    cursor: pointer;
  }
}

@media (max-width: $breakpoint-sm) {
  .provider-container {
    margin-bottom: 0.7rem !important;
  }
}

.recognition-container {
  min-height: 1.9rem;
}

.filter-container {
  display: none;
  min-width: 230px !important;
}
.helpful-hints-container {
  display: none;
}

@media screen and (min-width: $breakpoint-sm) {
  .mobile-filter-toggle {
    display: none;
  }
  .mobile-hints-toggle {
    display: none;
  }
  .filter-container {
    display: block;
  }
  .helpful-hints-container {
    display: block;
  }
}

.mobile-filter-toggle {
  margin: 1rem 0;
  .show-filter-options {
    text-decoration: none;
    cursor: pointer;

    .motif-icon {
      padding-left: 0.5rem;
      // color: $accent-color-salmon;
      color: rgb(51, 51, 51);
    }

    &::before {
      content: "Show Filter Options";
    }
  }
}

.mobile-hints-toggle {
  margin-bottom: 1rem;
  a::after {
    content: "Show Helpful Hints";
  }
}

.show-filter {
  .filter-container {
    display: block;
  }
  .mobile-filter-toggle {
    a::before {
      content: "Hide Filter Options";
    }
  }
}
.show-helpful {
  .helpful-hints-container {
    display: block;
  }
  .mobile-hints-toggle {
    a::after {
      content: "Hide Helpful Hints";
    }
  }
}

/* Cpt Slide out panel */
.cpt-expansion-panel-label {
  padding-left: 10px;
  font-weight: 400;
  font-style: normal;
  color: #333333;
  font-size: 18px !important;
}

.cpt-tab-content-section {
  padding: 20px 20px 20px 0;
  width: 95%;
}
.alert-count {
  background-color: #d20a3c;
  color: #ffffff;
  border-radius: 50%;
  padding: 7px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 9px;
  text-align: center;
  margin: 6px;
  display: inline-flex;
  width: 25px;
  height: 25px;
}

.cpt-tab-top-1 {
  margin-top: 1rem;
}

.cpt-service-limit {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.cpt-slide-content-wrapper {
  padding-left: 32px;
  padding-bottom: 32px;
}

.cpt-expansion-panel {
  border-bottom: 0;
  width: 95%;
  margin-bottom: 10%;
}

.cpt-alertBox-container {
  margin-top: 1rem;
  padding-bottom: 1rem;
  .dismiss {
    position: relative;
    float: right;
    .fa.fa-plus {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      color: #949494;
      font-size: 2.25rem;
    }
  }

  .infoBox {
    &.fit-content {
      width: fit-content;
    }
    .ant-alert {
      padding: 0em;
      margin-top: 0;
      border: #ccc solid 1px;
      border-left: 5px #cc0033 solid;
      .alert-icon {
        padding: 5px 0px !important;
        font-size: 1rem;
      }
      .ant-alert-left {
        padding: 0.2rem;
        background-color: #fff;
        .alert-icon.alert-icon-info::before {
          content: "\e9b4";
          color: #cc0033;
          margin-right: 0.2rem;
          margin-left: 0.2rem;
        }
      }
      .ant-alert-main {
        padding: 0;
        background-color: #fff !important;
        .cpt-alert-text {
          padding: 0.4rem 2rem 0.4rem 0.2rem;
        }
        p {
          width: 100%;
          span {
            display: flex;
            align-items: center;
            padding-right: 5px;
            .fa.fa-plus {
              font-size: 1.5rem;
            }
          }
        }
        a {
          text-decoration: none;
          font-weight: 600;
        }
        .infoText {
          width: 97%;
          @media screen and (max-width: 700px) {
            width: 95%;
          }
        }
        .ant-alert {
          border: none;
          .ant-alert-left {
            border: 0 #fff solid;
          }
        }
      }
    }
  }

  .cptAlert {
    &.fit-content {
      width: fit-content;
    }
    .ant-alert {
      padding: 0em;
      margin-top: 0;
      border: #ccc solid 1px;
      border-left: 5px #286ce2 solid;
      .alert-icon {
        padding: 0px !important;
        font-size: 25px;
      }
      .ant-alert-left {
        padding: 0.2rem;
        background-color: #fff;
        display: inline-block;
        .alert-icon.alert-icon-info {
          background-image: none;
          &::before {
            content: "\e9f9";
            color: #286ce2;
            margin-right: 0.2rem;
            margin-left: 0.2rem;
          }
        }
      }
      .ant-alert-main {
        padding: 0;
        background-color: #fff !important;
        margin-left: 5px;
      }
    }
  }
}

.cpt-alertBox-container.cpt-alert-slide-context .infoBox .ant-alert {
  .alert-icon {
    font-size: 1.5rem;
  }

  .ant-alert-left {
    align-items: baseline;
  }
}

.price-detail-modal {
  .rtModalContent.pricetab {
    .cpt-slide-modal-title {
      @media screen and (max-width: $breakpoint-sm) {
        font-size: 1.7rem;
      }
    }
  }
}

.cpt-cost-estimate-content-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $breakpoint-sm) {
    border-bottom: 1px solid #979797;
    padding-bottom: 5px;
  }
}

.cpt-cost-estimate-description {
  font-size: 12px;
  color: #666;
  padding-top: 4px;
}

.cpt-what-you-pay {
  padding-top: 42px;
  padding-bottom: 15px;
  @media screen and (min-width: $breakpoint-sm) {
    border-top: #ccc solid 1px;
  }
}

.oon-no-cost-share-print {
  display: none;
}

@media print {
  .oon-no-cost-share-print {
    display: block;
  }
  html,
  body {
    height: auto;
  }
  .modal-panel {
    width: 100% !important;
    position: relative !important;
  }
  .overlay-wrapper,
  .overlay-container {
    display: table !important;
    position: relative !important;
  }
  .close-icon {
    display: none !important;
  }
}

/*Variables*/
$font-size-1rem: 1rem;
$font-weight-600: 600;
$font-weight-500: 500;

/*Pharmacy*/
.pharmacy-content-wrapper {
  .container-wrapper {
    margin-bottom: 2.5rem;
  }
  .reson-label {
    border: none;
    font-size: $font-size-1rem;
    font-weight: $font-weight-600;
    display: inline-block;
    margin-bottom: 0.7143rem;
  }
  .prLabel {
    padding-left: 0;
    font-weight: $font-weight-500;
  }
  .pharmacy-disclaimer-container {
    display: flex;
  }
}
.looking-for {
  .ant-text-input {
    width: 100%;
  }
}

@media (max-width: $breakpoint-sm) {
  .looking-for {
    .ant-text-input {
      width: 600px;
      max-width: 100%;
    }
  }
}

.print-link {
  text-align: right;
  a {
    cursor: pointer;
  }
}

.fad-margin-bottom-1em {
  margin-bottom: 1em;
}

.fad-tier-icon {
  width: 1em;
  margin-left: 0.2em;
  margin-bottom: 0.2em;
}

.fad-tier-image {
  max-height: 52px;
  max-width: 100px;
}

/*focus outline for radio buttons*/
.prLabel.focus:before {
  box-shadow: 0 0 8px 1px #005b99 !important;
}

.hideTabs {
  .tabs {
    ul {
      display: none;
    }
  }
}

.fad-search-filter {
  .fad-pf-data-container {
    margin: 0;
    .ant-auto-suggest {
      .psDropdown {
        height: auto;
      }
    }
    &.pf-filter-hide {
      max-height: 0;
      overflow: hidden;
      transition: max-height 250ms linear;
    }
    &.pf-filter-show {
      height: auto;
      max-height: 400px;
      transition: max-height 250ms linear;
      overflow: hidden;
    }
  }
}

// < new
.modalVeil {
  width: 100%;
  background-color: rgba(63, 63, 63, 0.6);
  position: fixed;
  left: 0;
  top: 5rem;
  height: 100%;
  z-index: 1000;
  // display: none;
  // border: 1px #000 dashed;

  .rightModal {
    .closeBtn {
      position: absolute;
      top: 70px;
      left: 0;
      padding: 1.5rem;

      a.ant-arrow-next {
        font-size: 1.5rem;
        text-decoration: none;
      }
      .motif-arrows-long-right {
        font-size: 2rem;
        text-decoration: none;
      }
    }

    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 9rem 3rem 3rem 3rem;
    height: 100%;
    overflow-y: hidden;
    overflow-y: scroll;
    width: 50%;
    background-color: #fff;
    float: right;
    /* right: 0rem; */
    /* top: 20rem; */
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }

    .rtModalContentTop {
      input {
        margin-right: 0.7rem;

        margin-bottom: 0.6rem;
      }
    }

    h2 {
      padding-bottom: 4rem;
    }

    .rtModalContent {
      padding: 2rem;

      // <NEW

      .detail-group {
        width: 100%;
        padding-left: 0;
        margin-bottom: 3rem;

        .detail-left,
        .detail-right {
          padding-left: 0;
          padding-right: 0;

          .price {
            font-size: 1.25rem;
          }
        }
        .description {
          border-top: solid 1px #000;
          padding-left: 0;
        }

        .details-foot {
          font-size: 1.1rem;

          .motif-icon.motif-help {
            font-size: 1.1rem;
          }
        }
      }

      // NEW>

      .slidePcpIdSection {
        margin-top: 3rem;
        margin-left: 0;
        margin-right: 0;

        .list-item {
          padding: 0;
          margin-top: 1.5rem;

          .radioBox {
            padding: 0.3rem;
          }
          .radioText {
            padding: 0.3rem;
          }
        }
      }

      .selectDropList {
        margin-top: 4rem;

        input {
          height: 2.3rem;
          width: 100%;
        }
        .rtModalButtons {
          // display: flex;
          margin-top: 2rem;
        }
      }
    }
  }
}

.tile-view-wrapper {
  .tile-checkbox-wrapper {
    width: 100%;
    .pfCheckboxInputCmp .pcLabel {
      width: 10%;
    }
  }
  .negetive-spacing {
    margin-bottom: 0 !important;
  }
  .picture-block {
    display: flex;
    flex-flow: row;
    margin-bottom: 1rem;
    margin-top: 0.93rem;
    .picture-id {
      flex-basis: 20%;
      margin-right: 8px;
      margin-top: 0.29rem;
    }
    .tile-name-wrapper {
      padding: 0;
      .provider-name-link {
        text-transform: capitalize !important;
      }
      .provider-d-wrapper {
        padding: 0;
      }
      &.tile-name-wrapper-withimage {
        flex-basis: 75%; // mainly an IE fix
      }
    }
    .provider-name-row {
      flex-flow: column;
      .picture-info {
        display: flex;
        flex-flow: column;
        .speciality-comma {
          color: #666666;
          font-family: Lato, sans-serif;
          font-size: 1rem;
        }
      }
    }
  }
}
.tile-name-wrapper {
  @media screen and (max-width: $breakpoint-sm) {
    padding: 0 !important;
  }
  .speciality-comma {
    color: #666666;
    font-family: Lato, sans-serif;
    font-size: 1rem;
  }
}
.pf-map-modal-container {
  .modal-content {
    max-width: 990px;
    padding-bottom: 20px;
    .pf-map-view {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .nav-links {
    justify-content: flex-end;
    span.print-label {
      height: 34px;
      width: 31px;
      color: #37475a;
      font-family: Lato, sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
    }
    a.anchor-icon {
      margin-left: 0;
      color: #37475a;
    }
  }
  .start-container,
  .end-container {
    label {
      margin-bottom: 0;
      color: #333333;
      font-family: Lato, sans-serif;
      font-size: 1rem;
    }
    textarea {
      box-sizing: border-box;
      min-height: 52px;
      border: 1px solid #949494;
      background-color: #ffffff;
      padding: 10px 9px;
      font-family: Lato, sans-serif;
      font-size: 0.86rem;
      line-height: 15px;
      resize: none;
      color: #333;
      width: 100%;
    }
  }
  .map-input-wrapper {
    display: flex;
    align-items: center;
    flex-flow: row;
    margin-bottom: 10px;
  }
  .btn-primary.modal-directions-btn {
    background-color: #286ce2;
  }
  .btn-secondary.modal-directions-btn {
    width: 86px;
  }
  .virtual-provider {
    padding-bottom: 2rem;
    margin-top: 2rem;
    border-bottom: 1px solid #eee;
    &:last-child {
      border-bottom: none;
    }
    .provider-name-link {
      cursor: pointer;
      font-size: 18px !important;
      text-transform: capitalize !important;
      border-bottom: none;
    }
  }
}

.pf-map-modal-container .award-container .btn-getdirection {
  display: block;
}

@media screen and (max-width: $breakpoint-md) {
  .pf-map-modal-container .award-container .btn-getdirection {
    margin-bottom: 1rem;
    #btn-mapdirections-modal-close {
      float: right;
    }
  }
}

.fixed-tray.map-sticky-footer {
  @media screen and (max-width: $breakpoint-sm) {
    padding-bottom: 0.93rem;
    padding-top: 0.64rem;
  }
  .tray-button-row {
    @media screen and (max-width: $breakpoint-sm) {
      justify-content: center !important;
      width: 100%;
      display: flex;
    }
    .compare-msg {
      color: #333333;
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 64px;
      line-height: 32px;
      height: 32px;
      @media screen and (max-width: $breakpoint-sm) {
        margin-right: 0;
      }
    }
    .btn-secondary {
      width: 127px;
    }
    .btn-primary {
      width: 168px;
      background-color: #286ce2;
    }
  }
}

.future-pcp-floater-tag {
  color: white;
  background: #f2bc35;
  border-radius: 2px;
  display: inline-block;
  font-size: 10px;
  margin: 4px 0px 0px;
  padding: 4px 5px 2px;
  font-weight: bold;
  letter-spacing: 1px;
  width: fit-content;
  @media screen and (max-width: 1024px) {
    right: 10%;
  }
  @media screen and (max-width: 1200px) {
    right: 10%;
  }
}

.online-pharmacy {
  @media screen and (max-width: $breakpoint-sm) {
    display: flex !important;
  }
  .online-pharmacy-text {
    letter-spacing: 1px;
    text-align: center;
  }
  .online-phramacy-label {
    color: #000;
    background: #f2bc35;
    border-radius: 2px;
    display: inline-block;
    font-size: 10px;
    margin: 4px 0px 0px;
    padding: 4px 5px 2px;
    font-weight: bold;
    letter-spacing: 1px;
    width: fit-content;
    @media screen and (max-width: 1024px) {
      right: 10%;
    }
    @media screen and (max-width: 1200px) {
      right: 10%;
    }
  }

  .popIcon {
    margin-left: 0;
    vertical-align: sub !important;
    .fa {
      color: #286ce2 !important;
      font-size: 20px !important;
    }
  }
}

.care-team-item .future-pcp-floater-tag {
  display: block;
}

.tile-view-wrapper .future-pcp-floater-tag {
  display: block;
}

.pcp-effective-date {
  height: 17px;
  //width: 213px;
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
  padding-top: 0.5rem;
}

.slide-in .affiliate-address-slide-in-content {
  h2.slide-in-header {
    margin-bottom: 0.5rem;
  }

  .affiliate-address-container {
    border-bottom: 1px solid #949494;
    padding: 1rem 0;

    h3 {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }
}

.affiliationItem {
  margin-top: 1rem;
  &:first-child {
    margin-top: 0.5rem;
  }
}

.modal-component-footer.sticky-slide-in-footer {
  bottom: 0;
  right: 0;
  text-align: right;
  padding: 1rem;
  background-color: #fff;
  padding-top: 1.5rem;

  .btn.btn-primary,
  .btn.btn-secondary,
  #btn-view-more-locations-close {
    padding: 0.6rem 3rem;
    font-size: 0.8rem;
  }
}
.popover.right,
.popover.left,
.popover.top,
.popover.bottom {
  z-index: 100 !important;
}

/* email modal consistency quick-fix */
.pf-award-modal-container {
  .modal-content {
    .leaving-disclaimer-btns {
      margin-bottom: 2rem !important;

      .btn,
      .btn-primary,
      .btn-secondary {
        border-color: rgb(40, 108, 226);
        font-weight: 400;
        font-family: Lato, sans-serif;
        font-size: 0.8rem;
      }

      .btn-secondary {
        margin-right: 0;
        border: 1px solid rgb(40, 108, 226);
        background: rgb(255, 255, 255);
        border-color: rgb(40, 108, 226);
        color: rgb(40, 108, 226);
        font-size: 0.8rem;
      }
      .btn-primary {
        background: rgb(0, 96, 154);
        font-size: 0.8rem;
        border: 1px solid rgb(40, 108, 226);
      }
    }

    p {
      line-height: 2.2;
      font-size: 0.8rem;
      font-weight: 400;
    }
  }

  .fad-search-identification {
    #lbl-email {
      display: block;
      font-size: 14px;
    }
    #txt-email-address {
      font-size: 0.7rem;
    }
  }
  .minimal-tabbedview {
    .tab-body.is-active .ant-tab-body {
      overflow: auto;
      min-height: 150px;
      max-height: 200px;
      @media screen and (max-width: $breakpoint-xs) {
        overflow: auto;
        min-height: 300px;
        max-height: 320px;
      }
    }
  }
}
.tile-view-wrapper .pay-wrapper {
  text-align: right;
}

.network-text-left-align {
  margin-top: 10px;
  padding-top: 3px;
  text-align: left;
}

// spacing pill-filter options
.fly-out {
  .searchFilterItem {
    margin-bottom: 0;
    .pcLabel.focus {
      outline: none !important;
    }
  }
  [data-uxd-radio-input-cmp].searchFilterItem {
    margin-bottom: 0rem;
  }
}

.fad-app-wrapper {
  .searchFilterItem {
    label.pcLabel {
      width: 85% !important;
      font-size: 14px;
    }
  }
  .popover.right {
    right: 10px !important;
  }
  .provider-details a.popIcon {
    vertical-align: super;
  }
  .fad-page-wrapper {
    overflow-x: visible !important;
  }
}

.tile-view-wrapper .tile-name-wrapper .provider-name-link.anchor-icon.provider-on-hover {
  text-transform: capitalize !important;
}

.fad-tab-title {
  text-transform: none !important;
}

.summary-data-container ul {
  padding-left: 1rem;
  margin-bottom: 0px;
  &.fad-pf-list-nobullet {
    list-style: none;
    padding-left: 0px;
  }
}

.pf-compare-item-container ul {
  padding-left: 1rem;
  margin-bottom: 0px;
  &.fad-pf-list-nobullet {
    list-style: none !important;
    padding-left: 0px !important;
  }
}

// styles for transportation modal
.margin-btm-8px {
  margin-bottom: 8px !important;
}
.margin-btm-20px {
  margin-bottom: 20px !important;
}
.margin-btm-28px {
  margin-bottom: 28px !important;
}
.margin-btm-10px {
  margin-bottom: 10px !important;
}

.smallTxt {
  font-size: 0.8em;
}
.line-ht-2em {
  line-height: 2em;
}
.fad-clearfix {
  clear: both !important;
}
.fad-trans-footer-height {
  height: 48px;
  margin-bottom: 2rem !important;
  &.pos-static {
    position: static;
  }
}
.margin-rt-5px {
  margin-right: 5px !important;
}
.padding-rt-no {
  padding-right: 0;
}
.width-100 {
  width: 100% !important;
}
.width-50 {
  width: 50%;
}
.auto {
  width: auto;
}
.width-90 {
  width: 90%;
}
//--

@media screen and (max-width: $breakpoint-sm) {
  .modal-footer {
    background: none !important;
  }

  .provider-details .pf-location-wrapper .pf-location-flex .pf-location-block-end {
    margin-top: 0;
    padding-left: 0 !important;
    flex-basis: 48%;
  }
  .pf-location-block {
    flex-basis: 50%;
  }
}

.telehealth-link {
  margin-left: -3px;
  margin-top: 10px;
  a {
    font-weight: bold;
    text-decoration: none;
    .motif-icon {
      font-weight: normal;
      color: #f59a5f;
      font-size: 1.2rem;
      position: relative;
      top: 2px;
    }
    .telehelath-text {
      margin-left: 0.25rem;
    }
  }
}

// fixes for tooltips inside of pill filter drop downs.
.specialty-dropdown .popover {
  left: 20px !important;
}
.filter-checkbox-label .popover.right {
  margin-top: 34px;
}

.tile-view-with-map {
  .level-wrapper {
    margin-top: 1rem;
  }
}

// change plan or member side panel dropdown styles starts
.change-plan-side {
  .ant-search-button span.fa {
    padding: 0.14rem 0.6rem !important;
  }
}
// change plan or member side panel dropdown styles ends

// styles changes for new design starts [ANREIMAGED-178217]
$pf-primary-color: #286ce2;

.fad-page-wrapper {
  .sticky-back-container {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 50;
    background: #fff;
    padding: 0.5rem 0;
    padding-left: 14rem;
    @media screen and (max-width: $breakpoint-md) {
      padding-left: 5%;
      width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
      width: stretch !important;
    }
  }
  .secure-sticky-back {
    width: 158% !important;
    width: -moz-available !important; /* WebKit-based browsers will ignore this. */
    margin-left: -24rem !important;
    padding-left: 24rem !important;
    @media screen and (max-width: $breakpoint-md) {
      width: 160% !important;
      margin-left: -14rem !important;
      padding-left: 185px !important;
    }
  }
  .pt-20 {
    padding: 20px 0 0 0;
  }
  .text-color-blue {
    color: $pf-primary-color;
  }

  .btn-primary {
    background-color: $pf-primary-color;
  }

  .popover {
    border-color: $pf-primary-color !important;
  }

  .pf {
    &-mt-5 {
      margin-top: 5px;
    }
    &-mt-10 {
      margin-top: 10px;
    }
    &-mt-30 {
      margin-top: 30px;
    }
    &-mb-10 {
      margin-bottom: 10px;
    }
    &-mlr-2 {
      margin-left: 2rem;
    }
  }

  .pf-action-links a {
    display: flex !important;
    align-items: center;

    .motif-icon {
      font-size: 20px;
    }
  }
  .pf-action-links .careteam-print-email {
    .motif-icon {
      font-size: 1.3rem !important;
    }
  }

  .fad-pf-summary-wrapper {
    #search-results-table {
      padding: 25px 20px;
      @media only screen and (max-width: $breakpoint-sm - 1) {
        border: 1px solid #cccccc;
        margin-bottom: 20px;
        .fad-pf-search-results-first-col {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }

    .personalized-match-list {
      background-color: #f5f9fd;
      margin-bottom: 20px;
      border-bottom: none;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .personalized-match-list-map .tile-view-wrapper,
    .personalized-match-list-item {
      background-color: #f5f9fd !important;
    }

    .motif-icon.motif-info {
      font-size: 16px;
    }

    .pf-ratings-wrapper {
      .ratings-text {
        color: $pf-primary-color;
        font-weight: $font-weight-600;
        margin-bottom: 5px;
      }
      span.motif-icon {
        margin-right: 5px;
        font-size: 18px;
      }
    }

    .pf-pcpid-wrapper {
      display: flex;
      & > p {
        margin-right: 5px !important;
      }
      a {
        text-decoration: none;
        margin-left: 5px;
      }
    }

    .booking-buttons .btn {
      font-weight: $font-weight-600;
    }

    .booking-buttons .img {
      margin: 0 0 5px 5px !important;
    }

    .address-container a {
      text-decoration: none;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      .fad-tabs-wrapper {
        margin-top: 2rem;
      }

      .minimal-tabbedview .ant-tab-button {
        flex: 1;
      }

      .flex-tile-wrap .fad-flex-tile {
        margin: 0;
        max-width: 100%;
      }
    }
    .left-rail-container {
      .pf-search-results-action-buttons {
        @media screen and (max-width: $breakpoint-sm) {
          display: flex;
        }
      }
    }
  }

  .personalized-match-title {
    width: 100%;
    h6 {
      display: inline-block;
      margin-right: 10px;
    }
    h5 {
      display: inline-block;
      margin-right: 10px;
    }
    h5.sub-header5 {
      font-weight: 100;
      margin: 30px 5px 25px 16px;
    }
    a {
      text-decoration: none;
    }
  }

  .provider-exp-tooltip {
    a {
      text-decoration: none;
    }
    margin-bottom: 5px;
  }

  .map-provider-exp {
    margin-top: 5px;
    margin-bottom: -5px;
  }

  .fad-pf-pagination-container {
    #pagination > li {
      &.active > a {
        background-color: $pf-primary-color;
        border-color: $pf-primary-color;
      }
      a {
        margin: 0 2px;
        border-radius: 50% !important;
        border-width: 2px;
        background-color: #ddd;
        @media only screen and (min-width: $breakpoint-sm) {
          margin: 0 12px;
        }
      }
      &:not(.active) a {
        color: #000000;
        border-color: #ddd;
      }
      &:last-child,
      &:first-child {
        a {
          color: $pf-primary-color;
          border-color: $pf-primary-color;
          background-color: transparent;
        }
      }
    }
  }

  .pf-description {
    margin: 15px 0 20px;
  }

  .speciality-wrapper .speciality-space {
    margin-top: 5px;
    display: inline-block;
  }

  @media only screen and (min-width: $breakpoint-sm) {
    .pf-mobile-only {
      display: none;
    }

    .flex-tile-wrap .fad-flex-tile {
      margin: 0;
    }
  }
}

// styles for new design ends

[data-uxd-modal-cmp] .modal-content .modal-footer {
  display: block !important;
}

.search-results-info .tier-wrapper .popIcon.tile-view-modal {
  margin-left: 10px;
}
@media screen and (max-width: $breakpoint-sm) {
  .modal .modal-footer {
    position: static;
    padding: 25px;
    background: #fff;
  }
}

.specialty-dropdown-leftsidebar {
  //  height: 13rem;
  .ant-text-input {
    width: 400px;
    @media screen and (min-width: $breakpoint-sm) {
      width: 225px;
    }
    @media screen and (min-width: $breakpoint-md) {
      width: 300px;
    }
  }
  .psLabel {
    word-break: break-word;
  }
  .psDropdown {
    // width: calc(100% - 39px);
    &.active {
      max-height: 9.5rem;
    }
  }
}
.search-results-info .tier-wrapper .popIcon.tile-view-modal-leftside-filter {
  margin-left: 6px;
}
.pf2-mar-bot-17px {
  margin-bottom: 17px;
  width: 85%;
  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}
.pf2-bold {
  font-weight: bold;
}
.pf2-mar-left-5px {
  margin-left: 5px;
}
.pf2-left-rail {
  float: left;
  @media screen and (max-width: $breakpoint-sm) {
    display: none;
  }
  @media screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
    width: 30%;
  }
  .filter-container {
    display: block;
    padding-right: 1rem;
  }
  .pf2-separator {
    border-bottom: solid 1px #e3e1e1;
  }
  .sidebar-nav .sidebar-list {
    padding-left: 0;
  }
  .pf2-left-rail-header {
    font-weight: 600;
    font-style: normal;
    display: block;
    margin-bottom: 13px;
  }
  .pf2-showall {
    width: 100%;
  }
  .pf-search-pills {
    width: 100%;
  }
  .width100 {
    width: 100% !important;
  }
  .pf2-left-heading {
    font-weight: 600;
    font-style: normal;
    margin-bottom: 5px;
    display: block;
    text-align: left;
  }
  .look-up-item-header,
  .section-pill-bg {
    background-color: transparent;
  }
  .adaLabel {
    padding-bottom: 1rem;
  }
  .input-container {
    display: flex;
    width: 96%;
    margin-bottom: 8px;
    padding: 1rem 1rem 0 0;
    input.input-field {
      width: 100%;
      padding: 8px;
      outline: none;
      font-size: 12px;
      background: #fff;
      border-radius: 0;
      border: 1px solid #949494;
    }
    .search-icon {
      color: #fff;
      text-align: center;
      background: #286ce2;
      border-radius: 0;
      height: 40px;
      width: 40px;
      font-size: 1.5rem;
      padding-top: 9px;
    }
  }
  .searchFilterItemNew {
    width: 100% !important;
    padding-left: 0 !important;
    .bdcValue-style {
      margin-top: 15px;
    }
    a {
      text-decoration: none;
      font-weight: bold;
    }
  }
  .sort-container .sort-dropdown .psButton {
    width: 180px;
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }
  .toggle-body [data-uxd-radio-input-cmp] {
    margin-bottom: 0;
  }
  .toggle-body [data-uxd-checkbox-input-cmp] {
    margin-left: -10px;
  }
  .leftfilter-clear-all-link {
    padding-left: 0;
    margin-top: 10px;
    display: inline-block;
    padding-top: 0;
  }
}
.pf2-left-rail-provider {
  float: left;
  width: 81%;
  margin-left: 2%;

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
  .ant-tab-header {
    margin-left: 20px;
    .ant-tab-list {
      display: inline-block;
      width: 100%;
      .ant-tab-button {
        float: left;
        width: 18%;
        @media screen and (max-width: $breakpoint-md) {
          width: 48%;
        }
      }
    }
    .sort-dropdown-container {
      display: inline-block;
      float: right;
    }
  }
  .disclaimer-wrapper {
    width: 100%;
  }
  .search-result-grid .provider-details-container {
    width: 240px;
  }
}
.pf2-left-rail-provider-mobile {
  float: left;
  width: 100%;
  margin-left: 1%;

  @media screen and (max-width: $breakpoint-md) {
    // display: none;
  }
  .ant-tab-header {
    .ant-tab-list {
      .ant-tab-button {
        float: left;
      }
    }
    .sort-dropdown-container {
      display: inline-block;
      float: right;
    }
  }
  .pf-search-results-section .pf-flex-col.tier-wrapper {
    width: 17%;
    flex: none;
  }
  .disclaimer-wrapper {
    width: 100%;
  }
  .search-result-grid .provider-details-container {
    width: 240px;
  }
}
.btn-mobile-only {
  display: none;
  @media screen and (max-width: $breakpoint-md) {
    display: block;
  }
}
.fad-search-results {
  @media screen and (max-width: $breakpoint-md) {
    overflow-x: hidden;
  }
  @media screen and (max-width: $breakpoint-xs) {
    border-right: 1px solid white; //fix for safari mobile display issue
  }
}
.btn-mr-22 {
  margin-right: 22px;
}
.no-show {
  display: none;
}
.pf2-show-filter {
  display: block;
  width: 100%;
  @media screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
    width: 30%;
  }
}
@media screen and (min-width: $breakpoint-sm) {
  .pf2-left-rail-provider {
    display: block;
  }
}

.pf-flex-col-left-rail {
  flex: 1 0 10%;
  @media screen and (min-width: $breakpoint-md) {
    flex: 1 0 12%;
  }
  margin-left: 10px;
  &:first-of-type {
    flex: 1 0 20%;
    margin-left: 0;
  }
}
.pf-left-rail-compare-ml-10 {
  margin-left: 10px;
}
.margin-top-leftrail-10 {
  margin-top: 10px;
}
.col-md-10.fad-search-results.left-rail-search-results {
  .pf-search-results-section .pf-flex-col.tier-wrapper {
    flex: none;
    width: 17%;
  }
}
.left-rail-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  // padding: 10px;
  @media screen and (max-width: $breakpoint-md) {
    display: inline-block;
    // padding: 20px;
  }
  .left-rail-filter {
    flex: 1 0 20%;
    @media screen and (max-width: $breakpoint-sm) {
      // flex: 0;
      overflow: auto;
      float: left;
      width: 100%;
    }
  }
  .left-rail-search-results {
    flex: 1 0 80%;
    @media screen and (max-width: $breakpoint-md) {
      padding-left: 0;
      padding-right: 0;
      // flex: 1 0 100%;
    }
    .pf-search-results-section .pf-flex-col.tier-wrapper {
      width: 20%;
      flex: none;
    }
    .search-results-info {
      .ant-tab-list {
        justify-content: flex-start !important;
        margin-left: 0px;
        @media screen and (max-width: $breakpoint-md) {
          margin-left: 0;
        }
      }
      .ant-tab-button {
        @media screen and (max-width: $breakpoint-md) {
          flex-basis: 50%;
        }
      }
    }
  }
}
.fad-action-buttons.btn-mobile-only {
  @media screen and (max-width: $breakpoint-sm) {
    position: sticky;
    bottom: 0;
    padding: 40px 0 20px 0;
    display: inline;
    background: #fff;
  }
}

.sub-title {
  border-bottom: none;
  font-size: 12px !important;
  font-weight: bold;
}

.provider-title {
  border-bottom: none;
  font-size: 18px !important;
  text-transform: capitalize !important;
  font-weight: normal;
  padding-bottom: 0.75rem;
}

.pf2-ml-15 {
  margin-left: 15px;
}

// over-ride default snackbar
.ant-snackbar-alert-container {
  left: 0px;
  top: auto;
  width: 334px !important;
  margin-left: 24px !important;
  margin-bottom: 24px !important;
  font-size: 14px !important;
  .ant-snackbar-alert {
    padding: 0px !important;
  }
  .ant-inline-alert {
    font-style: normal !important;
    .dismiss-button span {
      font-size: 2.5rem !important;
    }
  }
  .ant-snackbar-alert-body {
    padding-top: 30px !important;
    padding-left: 27px !important;
    padding-right: 27px !important;
    padding-bottom: 30px !important;
  }
}

.alpha-prefix-container {
  @media screen and (max-width: $breakpoint-sm) {
    .tooltip-top {
      left: auto !important;
    }
  }
}

.applied-filters {
  padding: 0px;
  a {
    color: #ffffff;
    text-decoration: none;
    line-height: 1rem;
    &:hover {
      .filter-item-close {
        background: rgba(255, 255, 255, 0.15);
      }
      .filter-more-showmore {
        background: rgba(0, 0, 0, 0.1);
      }
    }
    &:focus {
      outline: 0;
      .filter-item-close {
        border-width: 2px;
      }
      .filter-more-showmore {
        border-width: 2px;
      }
    }
  }
  .filter-item {
    line-height: 1.2rem;
    display: inline-flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 14px;
    margin-left: auto !important;
    .filter-item-close,
    .filter-more-showmore {
      display: inline-flex;
      flex: 0 0 16px;
      align-items: center;
      justify-content: center;
      font-size: 10px !important;
      font-size: 7px !important;
      font-weight: bold;
      border-radius: 8px;
      height: 16px;
      width: 16px;
    }
    .filter-item-close {
      color: white;
      border: 1px solid white;
    }
    .filter-more-showmore {
      transform: rotate(45deg);
      color: $pf-primary-color;
      border: 1px solid $pf-primary-color;
    }
    .custom-icon-showless {
      transform: none;
      font-size: 14px !important;
      &:before {
        content: "\002212";
        margin-top: -4px;
      }
    }

    .filter-name {
      font-size: 12px !important;
      font-weight: 600;
      padding-right: 10px;
    }
    &.filter-more {
      border: 1px solid $pf-primary-color;
      background: white;
      color: $pf-primary-color;
    }
  }
}

.fts-suggestion {
  padding: 0px;
  a {
    color: white;
    text-decoration: none;
    line-height: 1rem;
  }
  .suggestion-item {
    line-height: 1.2rem;
    display: inline-flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 14px;
    margin-left: auto !important;

    .suggestion-name {
      font-size: 12px !important;
      font-weight: 600;
      padding-right: 10px;
    }
  }
}

.motif-external-link:before {
  color: #000000a1 !important;
  margin-left: 5px !important;
}

.fad-founder-link {
  margin-top: 0px;
  line-height: 1.5;
}

.fad-provider-info {
  #patient-links,
  .virtula-care,
  .specialty-care,
  .urgent-care {
    line-height: 2;
    margin-top: 2rem;
  }
}

div#fad-founder-panel-patient-links {
  line-height: 2.5;
  margin-top: 2rem;
}

.fad-founder-alert {
  width: 55%;
  margin-left: 0;
  margin-top: 6rem;
}

.fad-pf-logo {
  max-width: 40%;
  width: 40%;
  height: auto;
  float: right;
}

#fad-founder-panel-behaviroalHealthTitle {
  line-height: 1.5 !important;
}

@media only screen and (max-width: 768px) {
  .fad-founder-alert {
    width: 100%;
  }
  .pf-header-content {
    margin-top: 7rem !important;
    margin-bottom: 1rem !important;
  }
  .fad-pf-logo {
    float: none;
    width: 50%;
  }
}

.display-only-on-print {
  @media screen {
    display: none !important;
  }

  @media print {
    display: block !important;
  }
}

.filter-container {
  .uxd-expansion-panel {
    padding-top: 15px;
    padding-bottom: 10px;
    border-top-width: 0px;
    .uxd-expansion-header {
      padding: 0px;
      .uxd-expansion-toggle {
        margin-top: 0 !important;
      }
      .uxd-expansion-button {
        width: auto !important;
        padding-left: 0px;
        padding-right: 0px;
        margin: 0px;
        .expansion-title {
          padding-left: 5px;
          font-weight: 600;
          font-style: normal;
          color: #333333;
        }
      }
    }
    .uxd-panel-content {
      padding-left: 20px;
      padding-top: 7px;
    }
  }
  .filter-scroll {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }
  .filter-scroll-recognition {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.overflow-visible {
  overflow: visible !important;
}

.tab-controls-container-sidebar {
  width: 100%;
  min-width: 220px;
  @media screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
    width: 30%;
  }
}

.pf2-mar-top-24 {
  margin-top: 24px;
}

.pf2-mar-bot-24 {
  margin-bottom: 24px;
}

.pf2-mar-top-10 {
  margin-top: 10px;
}

.pf2-mar-top-14 {
  margin-top: 14px;
}

.pf2-mb-14 {
  margin-bottom: 14px;
}

.pf2-mar-top-20 {
  margin-top: 20px;
}

.pf2-mar-topn18 {
  margin-top: -18px;
}

.pf2-mar-topn13 {
  margin-top: -13px;
}

.pf2-ml-30 {
  margin-left: 30px;
}
.pf2-ml-25 {
  margin-left: 25px;
}
.pf2-mr-10 {
  margin-right: 10px !important;
}

.online-pharmacy-provider {
  color: #000;
  font-weight: bold;
}

.text-decor {
  a {
    text-decoration: none;
  }
}

.pf2-more-filters {
  padding-bottom: 15px !important;
}

.pcp-info {
  padding-left: 0px !important;
  line-height: 2;
  list-style-type: none;
}

.pcp-info ul li {
  line-height: 2;
  padding-left: 0px;
}

.pcp-detail {
  margin-right: 10px;
}

.pcpInfo li {
  list-style-type: none;
}

.pcpInfo-list {
  margin-bottom: 10px;
}

/** Override styles of 'PCP IDs and Affiliations List Component' - displayed in Compare Page **/
.pf-compare-item-container {
  .pf-pcp-id-list {
    padding-left: 0px;

    .pf-pcp-id-list-item:not(:first-child) {
      border-top: none;
    }
  }
}
/** end of PCP IDs and Affiliations List Component**/
//style for recent activity section
.vc-ra-rowcontainer {
  margin: 3% 22% 0 10%;
}
.recentactivity {
  padding-bottom: 70px;
}
.vc-ra-header-title {
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 22px !important;
  color: #333;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-bottom: 16px;
}
.vc-ra-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 1rem -14px 1rem 0;
}
.vc-ra-row-symptom {
  min-height: 70px;
  flex: 1 1 calc(14.28% - 14px);
  margin-left: 24px;
  max-width: calc(20% - 14px);
  min-width: 120px;
  margin-bottom: 14px;
}
.vc-ra-row-description {
  min-height: 70px;
  flex: 1 1 calc(14.28% - 14px);
  text-align: center;
  margin-right: 14px;
  max-width: calc(60% - 14px);
  min-width: 120px;
  margin-bottom: 14px;
  margin-top: 24px;
}
.vc-ra-row-date {
  min-height: 70px;
  flex: 1 1 calc(14.28% - 14px);
  text-align: right;
  margin-right: 24px;
  max-width: calc(20% - 14px);
  min-width: 120px;
  margin-bottom: 14px;
  margin-top: 24px;
}
.ra-symptom {
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.2rem;
  margin-bottom: 0.1rem;
  margin-top: 24px;
}
.ra-symptom-desc {
  font-family: Lato, sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.2rem;
  margin-bottom: 1rem;
  margin-top: 2px;
}
.vc_ra_deviderow {
  border: solid 1px #e3e1e1;
}
.vc-ra-wrapper {
  background-color: #fff;
  margin-top: -1.09rem;
  border: none 0;
}
.go-to-chat {
  text-decoration: none !important;
}
//eoc ra section
.procedure-medicalcode {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $breakpoint-sm) {
    display: block;
    width: 100%;
  }

  @media print and (max-width: $breakpoint-sm) {
    display: block;
    width: 100%;
  }
}

.careoptions {
  position: absolute;
  margin-bottom: 20px;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  a {
    display: inline;
  }
}

.careoptions-fc {
  // margin-bottom: 15px;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  a {
    display: inline;
  }
}

.procedure-medicalcode-fc {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  @media screen and (max-width: $breakpoint-sm) {
    display: block;
    width: 100%;
  }

  @media print and (max-width: $breakpoint-sm) {
    display: block;
    width: 100%;
  }
}

.print-cpt-icon {
  padding-right: 1rem;
}

.service-limit-list-style {
  padding-left: 20px;
  margin-top: 5px;
  .service-limit-list-text {
    font-weight: bold;
  }
}

.service-limit-list-dental-style {
  padding-left: 20px;
  margin-top: 30px;
  .service-limit-list-text {
    font-weight: bold;
  }
}

.service-limit-text {
  font-weight: bold;
  margin-left: -21px;
}

.service-limit-list-header {
  display: block;
  margin-top: 35px;
}

.modal-component-body .recognition-slider .award-container {
  .font-switch {
    font-size: 18px;
    font-family: Lato, sans-serif;
  }
  .recognition-block {
    display: flex;
    border: 1px solid transparent;
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
      display: grid;
    }
    .award-image {
      height: auto;
      @media screen and (max-width: $breakpoint-sm) {
        width: 100px;
        text-align: left;
        padding-left: 0px;
      }
      img.recognition-logo {
        display: block;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media screen and (max-width: $breakpoint-sm) {
          position: static;
          transform: none;
        }
      }
    }

    .award-content {
      border-left: 1px solid #dfdfdf;
      @media screen and (max-width: $breakpoint-sm) {
        border-left: none;
        margin-top: 10px;
        padding-left: 0;
      }

      .motif-external-link:before {
        color: #286ce2 !important;
      }
    }
  }
}

.pcp {
  font-weight: bold;
  color: $ant-motif-dove-dark-gray;
}

.modal-component-body.location-slide-out,
.modal-component-body.pf-map-modal-container {
  .pf-map-view.srch-providr-map {
    width: 100%;
    margin-bottom: 30px;
  }
  .pfSelect .psButton,
  .psDropdown.active,
  .psDropdown {
    width: 40%;
    @media screen and (max-width: $breakpoint-md) {
      width: 100%;
    }
  }
  .map-location-info {
    display: block !important;
  }
  .secondary-custom {
    display: inline-flex;
    justify-content: center;
  }
  .infoBox-container .infoBox .ant-alert .ant-alert-main {
    background: #fff !important;
  }
}
.responsive-layout {
  @media screen and (max-width: $breakpoint-md) {
    display: block;
  }
}
.pf-map-modal-container .direction-container.map-responsive {
  @media screen and (max-width: $breakpoint-md) {
    height: auto !important;
    .nav-links.fad-action-buttons {
      margin-bottom: 0;
      a {
        display: flex;
      }
    }
  }
}

.oon-cost-options div {
  display: flex;
  @media screen and (max-width: $breakpoint-md) {
    display: grid;
  }
}

.pf-oon-info {
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 4px;
  color: #333333;
}

.pf-oon-icon {
  display: inline-block;
  font-size: 24px !important;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 8px;
  color: #949494;
}

.pf-info-icon-oon {
  display: inline-block;
  font-size: 24px !important;
  vertical-align: middle;
  margin-bottom: 2px;
  color: #949494;
}

.oon-providers-link {
  > span {
    margin-right: 7px;
  }
  > a {
    font-weight: 700;
    cursor: pointer;
    &:hover {
      border-bottom: none 0;
    }
  }
}

.oon-provider-msg {
  margin-bottom: 15px;
  a {
    font-weight: 700;
    cursor: pointer;
    &:hover {
      border-bottom: none 0;
    }
  }
}

.custom-suggest {
  .fly-out.look-up-container {
    width: 50% !important;
    @media screen and (max-width: $breakpoint-sm) {
      width: 100% !important;
    }
    .auto-suggest {
      width: auto;
      background: #ffffff;
      border-top: none;
      border-radius: 0;
      margin: 0;
      max-height: 20rem;
      height: auto;
      padding-top: 0px;
      overflow-x: hidden;

      .look-up-item {
        padding: 0 !important;
        .psLabel {
          padding: 0.75rem 0 0.75rem 0.75rem !important;
        }
      }
      .look-up-item-border {
        border-bottom: none;
        padding-left: 10px !important;
        padding-right: 15px !important;
      }
      .noSuggestionsFound {
        font-weight: 400;
        padding: 0.75rem 0 0.75rem 0.75rem !important;
      }
    }
  }
}

.look-up-grid-container {
  display: grid;
  grid-template-columns: 1fr; /* Creates a single column layout by default */
  gap: 0px; /* Optional: Adds some space between the columns */

  .look-up-grid-item {
    /* Styles for each column */
    position: relative;
  }

  .look-up-grid-item:not(:last-child)::after {
    content: "";
    position: absolute;
    right: -5px; /* Adjust as needed */
    top: 0;
    bottom: 0;
    width: 5px; /* Adjust as needed */
    background-color: #f7f7f7; /* Adjust as needed */
  }

  @media (min-width: $breakpoint-md) {
    .look-up-grid-item:not(:last-child)::after {
      right: 0;
    }
  }
}

@media (min-width: $breakpoint-md) {
  /* Adjust the breakpoint as needed */
  .look-up-grid-container {
    grid-template-columns: 1fr 1fr; /* Creates a 2 column layout on larger screens */
  }
}

//Styling for the header with in landing page and try beta search button
.care-side-link {
  flex-direction: row;
  align-items: flex-start;

  .larger-text .ant-font-b span {
    font-size: 1.83rem !important;
  }

  @media (max-width: 600px) {
    flex-direction: row;
  }
}

.chat-icon {
  content: url("/assets/images/common/chat-icon.svg");
}
.refresh-icon {
  content: url("/assets/images/common/refresh.svg");
}
.light-bulb-icon {
  content: url("/assets/images/common/light-bulb.png");
  width: 16px;
  height: 16px;
}
.more-examples {
  display: flex;
  align-self: right;
  margin-top: 1%;
  line-height: 22px;
  font-size: 14px;
}

@media (min-width: $breakpoint-xs) {
  .overlay-container .modal-container.slide-in.right .modal-panel {
    width: 50%;
  }
}

.care-side-subheader {
  display: flex;
  margin: 2em 0 1.5em 0;
  justify-content: space-between;
  .pf-justify-content-between {
    margin: 0;
  }
  .change-plan {
    margin-top: 0.5em;
    .fontSizeLarge {
      margin: 0;
    }
  }
}

.pf-fts-beta-wish-to-try {
  display: flex;
  gap: 6px;
  border: 1px solid #286ce2;
  color: #ffffff;
  background-color: #286ce2;
  width: auto;
  padding: 0.5em 2em;
  cursor: pointer;
  font-weight: 700;
  line-height: 15px;
  align-self: self-end;
  text-align: center;
  .refresh {
    width: fit-content;
    flex: none;
    color: #ffffff;
  }
  .button-label {
    display: inline-flex;
    font-size: 12px !important;
    white-space: nowrap;
  }
}

.care-side-subheader-results {
  justify-content: flex-end;
}

.pf-back-provider-link {
  margin-left: 4px;
}

@media (max-width: 768px) {
  .flex-space-between {
    flex-direction: column-reverse;
    .changeLocation {
      margin-top: 1em;
    }
  }
  .care-side-subheader {
    flex-wrap: wrap;
  }
}
.pf-side-panel.fad-app-wrapper {
  .price-detail-modal.modal-component-body.cost-share {
    overflow: auto;
  }
}

.pf-feedback-modal-body {
  .modal-title {
    font-size: 26px !important;
    padding-bottom: 1rem !important;
  }
  p {
    padding-bottom: 2rem;
  }
}

.assistanceHelpText {
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  padding: 10px;
  color: #333333;
}

.fcr-assistanceHelpText {
  display: flex;

  .fts-assistanceHelpText {
    padding: 0 !important;
    font-weight: 400 !important;
    margin-left: 2% !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #333333;
  }

  .fts-example-link {
    text-align: right;

    img {
      height: 24px;
      width: 28px;
      padding-right: 6px;
    }

    a.fts-more-example {
      position: relative;
    }
  }
}

.fts-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  padding: 10px;
  color: #333333;
}

.oon-prov-status-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 13px 0;
  * {
    color: #a35c00;
    line-height: 22px;
  }
  .oon-status-icon {
    margin-right: 5px;
    font-size: 20px !important;
  }
  .oon-status-label {
    font-weight: 700;
    font-size: 14px !important;
  }
}

.oon-pg-alert-wrapper {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  font-size: 14px !important;
  .oon-pg-alert-icon {
    margin-right: 8px;
    font-size: 26px !important;
    color: #a35c00;
  }
  .oon-pg-alert-text {
    margin-right: 5px;
  }
  .oon-pg-alert-link {
    color: #286ce2;
    text-decoration: none;
  }
}

.search-location-container {
  display: flex;
  align-items: center;
  height: 100%;

  .motif-marker {
    color: #ffff;
    font-size: 1.5rem !important;
    flex-grow: 1;
  }
  background-color: #286ce2;
}

.btn-update-location {
  display: flex;
  align-items: center;
  background-color: #1a73e8;
  /* Make this similar to the button color in the image */
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  padding: 11px 48px;
  .motif-icon.motif-marker {
    font-size: 30px !important;
  }
}

.btn-update-location:hover {
  background-color: #286ce2;
  /* Darker shade for hover effect */
}

.icon {
  // margin-right: 10px;
  width: 26px;
  height: 26px;
  gap: 0px;
  opacity: 0px;
}

.search-location {
  max-width: 400px;
  margin: 0 auto;
}

.search-location h1 {
  font-size: 24px;
  margin-bottom: 16px;
}

.search-location form div {
  margin-bottom: 16px;
}

.search-location input[type="text"] {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #949494;
}

.red-border {
  border: 2px solid red;
}

.searchType {
  font-size: 14px;
  font-weight: 400;
  // line-height: 22px;
  text-align: left;
}

.pf-note {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.pf-currentLoc {
  input {
    width: 100%;
  }
}

.pf-srch {
  font-size: 26px !important;
  color: #000;
}

.pf-geo {
  padding-left: 1rem !important;
  width: 80%;
  input.flyout-parent-width {
    width: 55%;
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  .fly-out.look-up-container {
    width: 35% !important;
    @media screen and (max-width: $breakpoint-sm) {
      width: 67% !important;
    }
    @media screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
      width: 35% !important;
    }
    @media screen and (max-width: $breakpoint-xs) {
      width: 61% !important;
    }
  }
  .look-up-item-more {
    max-height: 9rem;
    overflow: auto;
    height: auto;
  }
  .fly-out {
    @media screen and (max-width: $breakpoint-xs) {
      left: 23%;
    }
  }
}

.modal-component-body.location-modal-height {
  @media screen and (max-width: $breakpoint-md) {
    height: 600px;
    overflow-y: scroll;
  }
  .look-up-item-more {
    max-height: 9rem !important;
    overflow: auto;
    height: auto;
  }
}

.button-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 50%;
  display: flex;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.button-container button {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-container .cancel-button {
  color: #333;
  margin-right: 16px;
}

.button-container .cancel-button:hover {
  background-color: #e6e6e6;
}

.button-container .continue-button {
  background-color: #286ce2;
  color: #fff;
}

.button-container .continue-button:hover {
  background-color: #286ce2;
}

@media (max-width: 768px) {
  .button-container {
    padding: 12px;
  }

  .button-container button {
    height: 40px;
    font-size: 14px;
  }
}

.pf-radio {
  width: 16px !important;
  height: 16px !important;
}

.pf-otr {
  padding: 0px 0px 0px 24px;
  input {
    width: 80%;
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }
}

.pf-errorMsg {
  color: #d20a3c;
  padding: 0px 0px 0px 24px !important;
}

.pf-location {
  display: flex;
  flex-direction: column;
  flex-grow: 8;
}

.red-text {
  font-size: inherit;
  color: #d20a3c;
}

.pf-side-panel.switchLocation {
  .location-modal-height {
    height: auto;
    margin-bottom: 30px;
    @media screen and (max-width: $breakpoint-md) {
      height: 600px;
      overflow-y: scroll;
    }
    .speciality-sticky-button .btn-row-area {
      @media screen and (max-width: $breakpoint-md) {
        position: static;
        box-shadow: none !important;
      }
    }
  }
}
.view-claims {
  display: flex;
}

.pf-button {
  cursor: pointer;
}
.cpt-cost-info-fc,
.procedure-cost-info-fc {
  .cpt-alertBox-container {
    margin-left: 20px;
  }
  .motif-icon.motif-right-round {
    font-size: 20px !important;
    color: #999;
    vertical-align: bottom;
  }
}
.infoBox-container.scenario-alert-container.alert-notification {
  .infoBox .ant-alert {
    .ant-alert-left {
      display: inline-block;
    }
    .alert-icon {
      padding: 0px !important;
      font-size: 25px;
    }
    .ant-alert-main {
      margin-left: 5px;
    }
    .ant-alert-main {
      a {
        text-decoration: none;
      }
    }
  }
  .infoBox .ant-alert.ant-negative .ant-alert-left {
    background: #fff !important;
  }
  .infoBox .ant-warning {
    border-left: 5px #a35c00 solid;
  }
  .fa.fa-exclamation-triangle {
    font-size: 27px !important;
    color: #a35c00;
  }

  .alert-close {
    height: 30px;
    cursor: pointer;
  }

  .page-alert-row {
    display: flex;
    justify-content: space-between;
    border: 1px solid #cccccc;
    border-left: 5px solid var(--border-left-color, #286ce2); // Default to blue if not set
    padding: 5px 10px 5px 5px;
    position: relative;
  }

  .page-alert-row-close-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #949494;
    font-size: 2rem !important;
    position: absolute;
    right: 0.5%;
  }

  .page-alert-row-content {
    flex-grow: 1;
    text-align: left;
  }

  .page-alert-row-left-section {
    display: flex;
  }

  .page-alert-row-icon {
    font-size: 23px;
  }
  .fa.fa-plus {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    color: #949494;
    font-size: 28px !important;
  }

  // Define styles for different alert types
  .page-alert-row.information {
    --border-left-color: #286ce2;
    .page-alert-row-icon::before {
      color: #286ce2;
      font-size: 23px !important;
      font-family: "ant-motif-icons" !important;
      content: "\e9f9";
    }
  }

  .page-alert-row.negative {
    --border-left-color: #cc0033;
    .page-alert-row-icon::before {
      color: #cc0033;
      font-size: 23px !important;
      font-family: "ant-motif-icons" !important;
      content: "\e93b";
    }
  }

  .page-alert-row.warning {
    --border-left-color: #a35c00;
    .page-alert-row-icon::before {
      color: #a35c00;
      font-size: 25px !important;
      font-family: "ant-classic-icons" !important;
      content: "\f1cf";
    }
  }
}
.modal-component-footer.sticky-slide-in-footer .btn.btn-primary,
.modal-component-footer.sticky-slide-in-footer .btn.btn-secondary {
  font-size: 1rem;
  width: 145px;
  height: 45px;
  margin-right: 5%;
}

#fcr-generic-side-panel {
  .modal-component-footer.clearfix.sticky-slide-in-footer {
    @media screen and (max-width: $breakpoint-sm) {
      display: block;
      text-align: center;
    }
    .btn.btn-secondary.btn-return {
      width: auto !important;
      @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 20px;
        width: 240px !important;
        height: 45px;
      }
    }
    .btn.btn-primary {
      @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 20px;
        width: 240px !important;
        height: 45px;
      }
    }
  }
}

.motif-theme {
  .generic-modal {
    @media screen and (max-width: $breakpoint-sm) {
      background: rgba(0, 0, 0, 0.5);
    }
    .modal-content {
      width: 650px;
      height: 400px;
      margin: 15% auto;
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        height: auto;
        overflow-x: hidden;
      }
      .close {
        border: none;
        right: -9px;
        z-index: 1;
        span.fa.fa-remove {
          color: #949494;
          font-size: 28px;
        }
      }
      .modal-header {
        height: 20px;
      }
      h2.modal-title {
        display: none;
      }
      .modal-footer {
        padding: 0;
        .center {
          margin-top: 5px;
          position: static;
          text-align: center;
          .btn-full-width {
            width: 70%;
          }
        }
      }
      .modal-header {
        padding: 0;
      }
      .modal-body {
        width: 100%;
        text-align: center;
        .home-health {
          margin-top: -50px;
          padding: 0 10%;
          line-height: 22px;
          @media screen and (max-width: $breakpoint-sm) {
            padding: 0;
          }
          h3.modal-title {
            padding-top: 0;
            @media screen and (max-width: $breakpoint-sm) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

.motif-theme {
  .generic-modal.modal-left-align {
    @media screen and (max-width: $breakpoint-sm) {
      background: rgba(0, 0, 0, 0.5);
    }
    .modal-content {
      width: 800px;
      max-width: 800px;
      max-height: 350px;
      height: auto;
      margin: 15% auto;
      padding-bottom: 20px;
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        height: auto;
        overflow-x: hidden;
        max-height: max-content;
      }
      .close .fa.fa-remove {
        border: none !important;
        font-weight: normal;
      }
      .modal-left-align-body {
        text-align: left;
        h3.modal-title {
          font-weight: normal;
          padding-bottom: 10px;
          padding-top: 0;
        }
        .print-modal {
          display: flex;
          flex-direction: row;
          width: 100%;
          @media screen and (max-width: $breakpoint-sm) {
            flex-direction: column-reverse;
          }
          .modal-plan {
            flex-basis: 90%;
          }
        }
        span.fa.fa-print {
          font-size: 26px;
          vertical-align: sub;
          margin-right: 0 !important;
          padding-right: 8px;
        }
        a {
          text-decoration: none;
          font-weight: bold;
        }
      }
      .modal-footer {
        // @media screen and (max-width: $breakpoint-xs) {
        //   position: absolute; //temporary removal
        // }
        .modal-component-footer {
          padding-bottom: 20px;
          @media screen and (max-width: $breakpoint-sm) {
            position: static;
            display: flex;
            justify-content: center;
          }
          .btn.btn-primary {
            height: 45px;
            width: 177px;
            @media screen and (max-width: $breakpoint-sm) {
              width: 70%;
            }
          }
        }
      }
      .fad-trans-footer-height {
        padding-right: 0;
      }
      .modal-header {
        height: 20px;
      }
      h2.modal-title {
        display: none;
      }
    }
  }
}
.fc-page-alert-detail {
  .infoBox-container.scenario-alert-container {
    .fcr-result-summary-container {
      align-items: flex-start !important;
      .motif-icon.fcr-page-alert-icon.motif-info {
        font-size: 26px !important;
        color: #286ce2;
        vertical-align: top;
      }
      .fa.fa-exclamation-triangle {
        font-size: 28px !important;
        color: #a35c00;
      }
      .motif-icon.motif-cancel {
        color: #cc0033;
        font-size: 26px !important;
      }
    }
  }
}
.modal-component-footer.sticky-slide-in-footer .btn.btn-primary {
  font-size: 1rem;
  width: 145px;
  height: 45px;
  margin-right: 5%;
}
