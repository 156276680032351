.provider-details{
  .details-header{
    padding: 1.6rem 0;
    flex-flow: row;
    justify-content: space-between;
    .align-self-center {
      align-self: center;
    }
    .d-flex {
      display: flex;
      &.basis-100 {
        flex-basis: 100%;
      }
    }
    .rFlex-bs100-alignCtr-spaceBwn {
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      align-items: center;
      justify-content: space-between;
    }
    .height-14px {
      height:14px;
    }
    .align-botm-2px {
      align-self: flex-end;
      padding-bottom: 2px;
    }
    .details-item {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
    }

    @media screen and (max-width: $breakpoint-sm) {
      padding-bottom: 0px;
    }
    .email-print-container{
      text-align: right;
      @media screen and (min-width: $breakpoint-sm) {
        padding-top: 0px;
        padding-right: 30px;
      }
      .email{
        margin-right: 15px;
      }
    }
    .network-status-container{
      margin-top: .5rem;
      &.header-flex {
        display: flex;
        align-items: stretch;
        flex-direction: row;
      }
      &.flex-col-reverse {
        flex-direction: column-reverse;
      }
      .tier-value-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        margin-left:5px;
        &.clear-margin {
          margin-left: 0px;
        }
      }
      .tier-value-block {
        padding: 5px 0 10px 0;
      }
    }
  }
  .detail-helpfulhints{
    text-align: right;
    .helpful-hints{
      padding-right: 0rem;
    }
  }

  .isPrintDisabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .address-dropdown{
    margin-bottom: 1.5rem;
  }

  .ant-lg-select .ant-text-input{
    width: 100%;
  }
  .tab-content{
    @media screen and (max-width: $breakpoint-sm) {
      padding-top: 30px;
    }
  }

  .tab-content-section{
    padding-top: 5px;
    .ant-font-b{
      padding-bottom: 5px;
    }
    .d-inline-block {
      display: inline-block;
    }
    .d-inline {
      display: inline;
    }
    .ant-list{
      display: block;
    }
    .summary-data-container:last-child{
      margin-bottom: inherit!important;
    }
  }
  a{
    vertical-align: baseline;
  }
  a.popIcon {
    vertical-align: middle;
    height: 15px;
    margin-left:0px;
    span.fa {
      display: block;
      height: 15px;
      width: 15px;
    }
  }
  .desktop-disclaimer a {
    vertical-align: unset; // overide style above for footer disclaimer
  }
  .email-line-break {
    word-break: break-word;
  }
  .onlineUrl {
    word-break: break-word;
  }
}

// .locationMapContainer{
//   @media only screen and (min-width: 800px) {
//     margin-top: -20px!important;
//   }
// }
.affiliationDropDowns{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 100%;
  @media screen and (max-width: $breakpoint-sm) {
    display: block;
    padding-right: 6%;
    span.type {
      margin-bottom: 2%;
    }
    span.col-xxs-12.col-xs-6.ant-font-bold.mobile-speciality {
      margin-bottom: 5px;
      display: block;
    }
    span.col-xxs-12.col-xs-6.ant-font-bold.speciality {
      display: none;
    }
  }
  @media screen and (min-width: $breakpoint-sm) {
    span.col-xxs-12.col-xs-6.ant-font-bold.speciality {
      display: block;
    }
    span.col-xxs-12.col-xs-6.ant-font-bold.mobile-speciality {
      display: none;
    }
  }
  .filterAffiliations{
    width: 100%;
    margin-left: 15px;
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 5%;
      width: 94%;
      margin-left: 4%;
    }
  }
}
.disabled {
  color: #949494;
}
.category{
  margin: 5% 0 5% 0;
  padding: 5%;
  border: solid 1px rgba(0, 0, 0, 0.1);
  overflow: auto;
  @media screen and (max-width: $breakpoint-sm) {
    width: 96%;
    .emulate-actions {
      padding-left: 0;
    }
  }
}
.btn-toggle{
  overflow: auto;
  width: 100%;
  text-align: center;
  a{
    font-weight: bold;
  }
}
.change-location-outer{
  float:left;
  margin-right: 2rem;
  .changeLocationContainer{
    width:100% !important;
  }
}

@media screen and (max-width: $breakpoint-sm){
  .fad-pf-details-wrapper .summary-data-container .selector-price-prod-container{
    width:100%;
  }
  .change-location-outer{
    float: none;
    margin-top: 10px;
    margin-right: 0;
  }
}
.top-margin-1{
  margin-top: 1rem;
}
.bottom-margin-1{
  margin-bottom:1rem;
  font-size: 20px;
}
.bottom-margin-2{
  margin-bottom:2rem;
  font-size: 20px;
}
.card-noprocedure-align
{
  font-size: 14px;
  font-family: 'Lato';
  margin-right: 2rem;
  display: flex;
}

.pricing-location-wrapper{
  position:relative;
  span{
    position: absolute;
    right: 12px;
    top: -32px;
  }
}

.cpt-pricing-location-wrapper{
  position:relative;
  padding-bottom: 10px;
  span{
    position: absolute !important;
    right: 0px;
    top: -35px;
  }
    @media only screen and (max-width: $breakpoint-sm) {
      position: none;
      padding-bottom: 10px;
      span{
        position: static !important;
      }
  }
}


.fad-pf-details-wrapper .summary-data-container .details-column-2-on .estim-col-content{
  .pricing-sub{
    font-weight: 600;
    margin-bottom: 0.6rem;
  }
}

// < profile enhance onn details page
.profile-enhance-container {

  padding-left: 0;
  padding-right: 0;
  margin-bottom: 3rem;

  .content-holder {

    padding-left:0;
    padding-right: 0;

    .profile-heading {
      margin-bottom: 1rem;
      font-weight: 700;
    }

    img, .profile-img {
      float: left;
      height: auto;
      width: 156px;
      margin-right: 50px;
      margin-bottom: 10px;
    }

    .btn-readmore {
      margin-left: 206px;
      margin-top: 10px;
      @media screen and (max-width: 800px){
        margin-left: 0px;
        margin-top:  10px;
      }
    }
    .btn-readless {
      margin-top: 10px;
    }

    .subcol-rt {
      padding-left:0;
      height: 237px;
      overflow: hidden;

      .btn-readmore {
        margin-left: 206px;
        margin-top: 10px;
      }

      @media screen and (max-width: 800px){
        .btn-readmore {
          margin-left: 0px;
          margin-top:  0px;
        }
      }
    }

    @media screen and (max-width: 800px){
      img, .profile-img {
        height: auto;
        width: 72px;
        margin-right: 25px;
        margin-bottom: 5px;
      }
    }
  }
}
// profile enhance onn details page >

.fad-pf-details-wrapper, .modal-component-body{
  .pf-location-block {
    flex-basis: 48%;
    .location-block-title {
      margin-bottom: 5px;
      padding:0;
    }
  }
  .pf-location-block-end{
    .motif-marker{
      color: #666!important;
    }
  }
}

.fad-pf-details-wrapper ul {
  padding-left: 1rem;
  margin-bottom: 0px;
  &.fad-pf-list-nobullet {
      list-style: none !important;
      padding-left: 0px !important;
  }
}

// New design changes
.provider-details {
  .detail-tab-header {
    margin-bottom: 2rem;
  }
  span.fa.fa-question-circle {
    top:1px;
    color: #286ce2;
  }

  .m-botm-5 {
    margin-bottom: 5px;
  }

  .tab-content-section {
    margin-bottom: 20px;
    #txt-office-hour {
      line-height: 2;
    }
  }

  .fad-pf-details-wrapper {
    .details-column-1, .details-column-2, .locationMapContainer {
      line-height: 1.5;
    }
    .details-column-1, .details-column-2 {
      padding-left: 0;
      padding-top: 0;
    }
  }
}

.affiliations-prime-group-label {
  padding: .1rem;
  background-color: #286ce2;
  color:#ffffff;
}
// New design changes ends

.pcp-info {
  padding-left: 0px !important;
  line-height: 2;
  list-style-type: none;
}

.pcp-info ul li {
  line-height: 2;
  padding-left: 0px;
}

.pcp-detail {
  margin-right: 10px;
}

/** Override styles of 'PCP IDs and Affiliations List Component' - displayed in Details Page **/
.fad-pf-details-wrapper
{
  .pf-pcp-id-list {
    padding-left: 0px;
    margin-top: 0px;
    padding-top: 5px;

    .pf-pcp-id-list-item:not(:first-child){
      padding: 0.25rem 0rem 0.75rem 0rem;
      border-top: none;
    }
  }
}
/** end of PCP IDs and Affiliations List Component**/

.card-align{
  margin-bottom: 3rem;
  }

.font-size-26 {
  font-size: 26px !important;
}

.infoBox-container .infoBox.out-network {
  width: 100%;
  background-color: #fff !important;
  >.ant-alert.ant-negative {
    color: #cc0033 !important;
    padding: 2px 0 !important;
    margin: 0 !important;
    border-left: 5px solid #c03 !important;
    >.ant-alert-left {
      background-color: #fff !important;
      border: none 0 !important;
      >.alert-icon.alert-icon-negative{
        padding: 0 5px !important;
        margin-top: -2px;
        font-size: 22px;
        line-height: normal;
        -webkit-font-smoothing: auto;
        &::before {
          content: "\26A0" !important;
          color: red;
        }
      }
    }
    >.ant-alert-main{
      background-color: #fff !important;
    }
  }
}
