@import "variables";

.tabs-content-container {
  @media (max-width: $breakpoint-sm) {
    padding: 10px;
  }
  display: flex;
  justify-content: center;

  .tab-content {
    padding-top: 30px;
    > #regTile1 {
      &.tab-pane {
        padding: 0px !important;
      }
    }
  }

  .landing-link {
    font-size: 22px;
    text-decoration: none;
  }

  .guest-link {
    text-decoration: none;
  }

  .d-inline {
    display: inline;
  }

  .width-50 {
    width: 100%;
    @media (min-width: $breakpoint-sm) {
      width: 50%;
    }
  }

  .width-40 {
    width: 100%;
    @media (min-width: $breakpoint-sm) {
      width: 40%;
    }
  }

  .land-srch-btn {
    height: 31px;
    font-size: 12px;
    padding: 7px 3.42rem;
    border: 0;
    margin: 2px 18px;
    @media (max-width: $breakpoint-sm) {
      margin: 15px 18px;
    }
  }

  .id-card {
    margin-top: 0;
    max-width: 100%;
  }

  .alpha-prefix-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .pf-toolTip-header {
    padding-bottom: 10px;
    font-size: 17px;
    font-weight: 600;
    margin: 0 0 10px;
  }

  .pf-disclaimer-note {
    padding-top: 10px;
    margin: 15px 0 0;
  }

  .full-span-alert {
    .ant-alert {
      width: 1019px;
    }
  }

  .alpha-prefix-wrapper {
    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  .prefix-input-wrapper {
    display: flex;
    align-items: center;
    flex-flow: column;
    @media (min-width: $breakpoint-sm) {
      flex-flow: row;
    }

    .prefix-input {
      width: 100%;
    }
  }

  .medicaid-header {
    padding-top: 2rem;
  }
}

.locationForm {
  .formWrapper {
    width: 80%;
    max-width: 577px;
    margin: 0 auto;
    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
    }

    h2 {
      margin: 0 auto 3rem;
      width: 80%;
      text-align: center;
      font-size: 1.8rem;
    }
    h3 {
      margin-bottom: 1rem;
      font-weight: 600;
    }
    .formRow {
      margin-bottom: 1.5rem;
      .ant-text-input {
        width: 100%;
      }
      .formRowHalf {
        vertical-align: top;
        width: 50%;
        display: inline-block;
        &:first-child {
          padding-right: 2%;
        }
        &:last-child {
          padding-left: 2%;
        }
      }
    }
    .sectionWrapper {
      margin-bottom: 4rem;
    }
  }
}

.fad-landing-tabs {
  .tabs {
    h2 {
      margin-bottom: 10px;
      font-weight: 300;
      @media (max-width: $breakpoint-md) {
        font-size: 16px;
      }
    }

    .nav-pills {
      width: 100%;
      @media (min-width: $breakpoint-sm) {
        width: 33%;
      }
      margin: 0 auto;

      li {
        background-color: $ant-motif-white;

        &.active {
          &:after {
            display: none;
          }

          a {
            background-color: $ant-blue;

            img {
              &.active {
                display: block;
              }

              &.inactive {
                display: none;
              }
            }

            &:hover {
              color: white;
            }
          }
        }

        &:not(.active) {
          a {
            &:hover {
              background-color: $white;
              color: $ant-blue;
            }
          }
        }

        a {
          border: none;
          /*  height: 100%; */
          display: flex;
          flex-flow: column;
          max-width: 100%;
          @media screen and (min-width: $breakpoint-md) {
            justify-content: flex-start;
          }

          img {
            height: 39px;
            max-width: 100%;

            &.active {
              display: none;
            }

            &.inactive {
              display: block;
            }

            @media (min-width: $breakpoint-sm) {
              margin: 20px;
            }
          }
        }

        p {
          font-size: 14px;
          padding: 0 30px;
          @media (max-width: $breakpoint-md) {
            font-size: 12px;
          }
          @media (min-width: $breakpoint-sm) {
            padding: 0 10px;
          }
        }

        p.h5 {
          font-weight: 300;
          @media (max-width: $breakpoint-md) {
            font-size: 16px;
          }
        }
      }
    }

    .nav {
      .heading-text {
        width: 100%;
        text-transform: none;
      }
    }
  }

  .prLabel {
    margin-right: 1.571rem;
    width: inherit;
  }

  .fad-action-buttons {
    flex-wrap: wrap;
  }
}

.medicaid-btn-row {
  .btn {
    padding: 0.85714286rem 2.428571rem;
  }
}

.plan-selection-link {
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  width: max-content;
}

.pf-care-team-container {
  // remove after launching v2?
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;

  a {
    text-decoration: none;
  }

  .open-panel-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    width: max-content;
    font-weight: 600;

    .motif-icon {
      font-size: 1.5rem !important;
    }
    .motif-icon.motif-id-card {
      font-size: 1.5rem !important;
      color: #949494;
      padding-right: 10px;
    }
  }

  .motif-id-card {
    color: $ant-motif-dusty-gray;
    font-size: 1.5rem;
    padding-right: 10px;
  }

  .pf-cards-wrapper {
    display: flex;
    @media screen and (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
    }

    .provider-types {
      list-style-type: none;
      padding: 0;

      li {
        padding: 0.5rem 0;

        a {
          text-decoration: none;
        }
      }
    }

    .card-providers {
      font-size: 18px;
      padding-bottom: 1rem;
    }

    .card-items-wrapper {
      display: flex;

      .card-column {
        flex: 1 0 50%;
      }
    }

    .care-team-item {
      border-top: 1px solid #e6e6e6;
      padding: 1.5rem 0;
    }

    .provider-no-link {
      text-transform: capitalize !important;
      color: #2453a6;
      opacity: 0.6;
    }

    .provider-link {
      cursor: pointer;
      text-transform: capitalize !important;
    }

    .motif-marker {
      color: $ant-motif-dove-dark-gray;
      padding-right: 0.5rem;
    }

    .card-address {
      line-height: normal;
    }
  }

  .pf-card {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
    padding: 2rem;
    background-color: $ant-motif-white;
    flex: 1 0 100%;

    @media screen and (min-width: $breakpoint-sm) {
      flex: 1 0 50%;
    }

    .care-team-detail {
      line-height: 2.5;
    }

    .care-team-list {
      list-style: none;
      padding: 0;
    }

    .care-team-name {
      font-size: 1.15rem;
      font-weight: 600;
    }
  }

  .pf-card-two {
    @media screen and (min-width: $breakpoint-sm) {
      margin-left: 1rem;
    }
  }

  .card-title {
    padding-bottom: 1.5rem;
    .card-header {
      line-height: 2.5;
    }
  }
}

.ant-bkg-blue {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.integrated-search-backdrop {
  background-image: url(/assets/images/motif/page-header-background.svg), linear-gradient(347deg, #58abdf, #0079c2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: grid;
}
.integrated-search-backdrop:before {
  content: "";
  background-image: url(/assets/images/motif/page-header-background.svg), linear-gradient(347deg, #58abdf, #0079c2);
  position: absolute;
  height: 100%;
  // width: 200vw;
  width: 100%;
  left: -100vw;
  z-index: -1;
}

.vpc-promotion-backdrop {
  background-image: url(/assets/images/common/chat-with-doctor-promotion.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: grid;
}

.pf2--bannerCard {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-right: 14px;
  margin-bottom: 14px;
  padding: 16px 0 0 20px;
  color: #fff;
  max-width: calc(50% - 14px);
  max-height: 187px;
  @media (max-width: $breakpoint-sm) {
    max-width: calc(100% - 14px);
  }
  @media (max-width: $breakpoint-xs) {
    min-width: calc(100% - 14px);
  }
  h4 {
    font-weight: 500;
  }
  p {
    max-width: 70%;
    padding-top: 8px;
  }
  div {
    display: flex;
    padding: 20px 0 20px 0;
    span {
      padding-left: 8px;
      font-weight: bold;
    }
    a {
      padding-left: 8px;
      font-weight: bold;
      color: #fff;
    }
  }
}

// dsepre...-46
.mbr-page-body {
  .container {
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 1200px) {
      width: 100%;
    }
  }
}

div[name="radioValue"] {
  .align-vertical {
    label {
      padding-left: 0;
    }
  }
}

.pf-vcard-container {
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;

  .vcard-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    width: max-content;
    font-weight: 700;
  }

  .motif-id-card {
    color: $ant-motif-dusty-gray;
    font-size: 1.8rem !important;
    padding-right: 10px;
  }
}

.info-alert-container {
  a {
    text-decoration: underline;
  }
  .btn-alert-dismiss {
    float: right;
    position: relative;
    top: 2.3rem;
    right: 0.3rem;
    // border: 1px #000 solid;
    .fa-close {
      font-size: 2rem;
      color: #949494;
    }
  }
  .ant-alert {
    border-top: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
    border-right: 1px #ccc solid;
    border-left: 6px #286ce2 solid;
    .ant-alert-main {
      background-color: #fff;
      p {
        width: 95%;
      }
    }
    .ant-alert-left {
      padding-top: 0.2rem;
      background-color: #fff;
      .alert-icon.alert-icon-info {
        color: #286ce2;
      }
    }
    .alert-icon {
      padding: 0;
    }
  }
}

// 2021 update ------------------------------------------------------------------------

//------UNICARE COLORS ------\\
$unicare-green-primary: #006b54;
$unicare-green-secondary: #7fa08c;
$unicare-blue-primary: #0078ab;
$unicare-brown: #d8d6b7;
$unicare: ".unicare-theme, .unicare-theme .fad-app-wrapper";

// WELLPOINT COLORS
$wellpoint: ".wellpoint-theme, .wellpoint-theme .fad-app-wrapper";

$wellpoint-primary: #1a3673;

@at-root .pf2-globalOverrides {
  // apply to top-level element on page AND top level element on modals/slide-in panels
  a {
    text-decoration: none;
  }
  .modal-component-body {
    padding: 1rem 5rem 5rem !important;
  }
  h2 .fa-question-circle {
    font-size: inherit !important;
    position: relative;
    top: 4px;
    margin-left: 1rem;
  }
  .modal-component-body div {
    padding-bottom: 0.5rem !important;
  }
  .modal-component-body .reason-txt-container {
    margin-top: -12px !important;
  }
  .feedback-bottom-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    border-top: 2.5px solid rgba($color: gray, $alpha: 0.1);
  }
}

// utilities ------------------------------------
// match headers to specific sizes per designs
h1,
h2,
h3,
h4,
h5,
h6 {
  &.pf2-header-18 {
    font-size: 18px;
  }
}

// margin classes (to add spacing in HTML)
.pf2 {
  &-mt-5 {
    margin-top: 5px;
  }
  &-mt-10 {
    margin-top: 10px;
  }
  &-mt-30 {
    margin-top: 30px;
  }
  &-mb-10 {
    margin-bottom: 10px;
  }
  &-mlr-2 {
    margin-left: 2rem;
  }
  &-mr-5 {
    margin-right: 5px;
  }
  &-mr-10 {
    margin-right: 10px;
  }
  &-mr-20 {
    margin-right: 20px;
  }
  &-mr-30 {
    margin-right: 30px;
  }
  &-mr-15 {
    margin-right: 15px;
  }
  &-pb-15 {
    padding-bottom: 15px;
  }
  &-pt-15 {
    padding-top: 15px;
  }
  &-float-lt {
    float: left;
  }
  &-mb-5 {
    margin-bottom: 5px;
  }
  &-font-normal {
    font-weight: normal;
  }
  &-mar-0 {
    margin: 0;
  }
  &-mb-30 {
    margin-bottom: 30px;
  }
  &-pb-0 {
    padding-bottom: 0 !important;
  }
  &-pl-0 {
    padding-left: 0 !important;
  }
  &-mb-7 {
    margin-bottom: 7px;
  }
  &-mb-20 {
    margin-bottom: 20px;
  }
  &-mt-20 {
    margin-top: 20px;
  }
  &-pl-10 {
    padding-left: 10px;
  }
}

// END utilities ------------------------------------

.pf2-flexcardrow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  margin-right: -14px;
  .pf2-flexcardwrapper {
    order: 0;
    margin-right: 14px;
    flex: 0 0 calc(50% - 14px);
    max-width: calc(100% - 14px);
    align-self: stretch;
  }
  &.pf2-flex-2col {
    flex-wrap: wrap;
    .pf2-flexcardwrapper {
      flex: 1 0 calc(50% - 14px);
      text-align: center;
      min-width: 320px;
    }
  }
}

.pf2-card-white {
  box-shadow: 0 6px 12px 0 rgb(0 0 0 / 8%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem;
  background-color: #ffffff;
  span.motif-honesty {
    float: left;
    padding-top: 14px;
    text-align: center;
    height: 70px;
    display: block;
    font-size: 50px !important;
    color: white;
    border-radius: 50%;
    background-color: #0079c2;
    width: 70px;
  }
  .provider-no-link {
    text-transform: capitalize !important;
    opacity: 0.6;
  }
  .pf2-card-content {
    display: flex;
    font-size: 14px;
    text-align: left;
    @media (max-width: $breakpoint-sm) {
      // float: right;
    }
    a {
      font-size: 14px;
    }
    &.pf2-virtual-care {
      display: inline;
    }
  }
  .pf2-card-title {
    font-size: 18px !important;
    font-weight: 400;
  }
}
.pf2-overflow {
  overflow: auto;
}
.pf2-display-blk {
  display: block;
}
.pf2-padding-14 {
  padding: 1rem;
}
.pf2-pl-20 {
  padding-left: 20px;
}
ul.pf2-actionItemList li.pf2-ptb-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.sub-header {
  font-weight: normal;
  margin-bottom: 5px;
  p {
    font-size: 14px;
  }
}
.pf2-flexcardwrapper-withtoplinks {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  margin-bottom: 1rem;
  .pf2--cardtoplinks {
    order: 0;
    flex: 0 1 auto;
    align-self: stretch;
    padding: 0.5rem 0;
    display: flex;
    margin-bottom: -9px;
    .pf2--cardtopheader,
    .pf2--cardtoplink {
      align-self: center;
    }

    .pf2--cardtopheader {
      flex: 0 0 60%;
      text-align: left;
      h2 {
        font-size: 18px;
      }
    }
    .pf2--cardtoplink {
      flex: 0 0 40%;
      text-align: right;
      height: 100%;
      align-items: center;
      a {
        height: 100%;
      }
    }
    .d-inline {
      display: inline;
    }
  }

  .pf2--cardcontent {
    order: 0;
    flex: 1 1 auto;
    align-self: stretch;
    margin-top: 12px;
  }
}

.pf2-split-col {
  width: 45%;
  float: left !important;
  &:first-child {
    float: left;
    margin-right: 110px;
  }
  &:last-child {
    float: right;
  }
  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.pf2-iconLink,
.pf2-iconLinkRight {
  display: inline-flex;
  align-items: center;
  > span {
    align-self: center;
    flex: 0 0 auto;
  }
  span.learn-more {
    max-width: 80%;
  }
  .motif-icon {
    font-size: 1.5rem !important;
    color: #666;
    margin-right: 0.5rem;
  }
}
.pf2-iconLinkRight {
  justify-content: flex-end !important;
}

.pf2-actionItemsAlertBug {
  display: inline-block;
  width: 2rem;
  position: relative;
  span.motif-icon {
    font-size: 24px !important;
  }
  .pft-actionItemsAlertBug-count {
    position: absolute;
    left: 16px;
    bottom: -4px;
    background: #e02020;
    font-size: 8px;
    color: white;
    padding: 2px 4px;
    display: inline-block;
  }
}

@at-root ul.pf2-actionItemList,
  ul.pf2-careTeamList {
  margin: 0;
  margin-left: -2rem; // offset card padding to allow full width dividers
  margin-right: -2rem;
  padding: 0;
  list-style: none;
  text-align: left;
  p,
  a {
    font-size: 16px;
  }
  span.pf-pre-wrap span {
    font-size: 16px !important;
  }
  p {
    margin-bottom: 1rem;
  }
  li {
    border-bottom: 1px solid #ccc;
    padding: 1rem 2rem;
    position: relative;
    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      padding-top: 0;
    }
  }
  .infoBox {
    margin-right: 30px; //make room for 3-dot menu icon
  }
  .careteam-alert-item {
    background: none;
    position: absolute;
    right: 25px;
    border: none;
  }
  .pf2-sub-link {
    @media (max-width: $breakpoint-sm) {
      float: right;
      margin-top: 0;
    }
  }
}
.pf2-vertical-bottom {
  vertical-align: bottom;
}
.cursor-pointer {
  cursor: pointer;
}

.pf-specialtyBadge {
  color: white;
  background: #268476;
  border-radius: 2px;
  display: inline-block;
  font-size: 10px;
  margin: 4px 0px 0px;
  padding: 4px 5px 2px;
  font-weight: bold;
  letter-spacing: 1px;
  width: fit-content;
  @media (max-width: $breakpoint-sm) {
    margin-top: 20px;
  }
  .pf-PCPBadge {
    margin: 0px 0px 6px;
  }
  span.subtext {
    font-size: 10px !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.virtual-care {
  letter-spacing: 1px;
  text-align: center;
}

.pf-careTeamNameAddress {
  line-height: 30px;
  margin-top: 1rem;
  a {
    font-size: 14px !important;
  }
}

.pf2-PCPbug {
  text-align: center;
  h3 {
    font-weight: 300;
    font-size: 22px;
  }
  span.motif-medical-doctor {
    display: block;
    font-size: 70px !important;
    margin: 1.5rem;
    color: #949494;
  }
  a {
    font-size: 16px;
    font-weight: 700;
    display: block;
    margin: 1rem 0;
  }
  button.btn {
    white-space: normal; // over-ride to force wrap
  }
}

.pf2-iconCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 12px -15px 1rem 0;
  .pf2--iconCard {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    min-height: 155px;
    flex: 1 1 calc(14.28% - 14px); //14.28% = 7 items in a row
    text-align: center;
    margin-right: 14px;
    max-width: calc(20% - 14px); //20% = 5 or more items will stretch full width
    min-width: 120px;
    margin-bottom: 14px;
    @media (max-width: $breakpoint-sm) {
      flex: 1 1 calc(33% - 14px); //33% = 3 items in a row
      max-width: calc(50% - 14px); //50% = 2 or more items will stretch full width
    }
    @media (max-width: $breakpoint-xs) {
      flex: 1 1 calc(50% - 14px); //50% = 2 items in a row
      min-width: calc(50% - 14px); //50% = force wrap after 2 items
    }
    a {
      padding: 2rem 1rem 1rem 1rem;
      color: #333333;
      height: 100%;
      display: block;
      .motif-icon {
        display: block;
        color: #286ce2;
        font-size: 40px !important;
        margin-bottom: 1rem;
      }
      .eye-icon-provider {
        display: block;
        margin-bottom: 1rem;
      }
      .tile-procedure-icon {
        margin-top: 5px !important;
      }
      .care-view-pencil {
        font-size: 14px !important;
      }
      .videoVisit-label {
        padding: 0.3rem 1.5rem 2.7rem 1.5rem;
      }
      .pf2-iconCardLabel {
        font-size: 18px !important;
        font-weight: 300;
      }
    }
    .action-item {
      position: relative;
      right: 10%;
    }
  }
}

.idcard-nav-link {
  display: inline !important;
}

.pf2-allCaughtUpIcon {
  display: inline-block;
  font-size: 50px !important;
  color: #38827f;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

#{$unicare} {
  .pf2-iconCards {
    .pf2--iconCard {
      a {
        .motif-icon {
          color: $unicare-green-primary;
        }
      }
    }
  }
}
#{$wellpoint} {
  .pf2-iconCards {
    .pf2--iconCard {
      a {
        .motif-icon {
          color: $wellpoint-primary;
        }
      }
    }
  }
}

// unicare global -----------------------------------------------------------------------
// move to separate file (loaded last)

#{$unicare} {
  //------BASE COLORS------\\
  $unicare-green-primary: #006b54;
  $unicare-green-secondary: #7fa08c;
  $unicare-blue-primary: #0078ab;
  $unicare-brown: #d8d6b7;

  .btn {
    font-family: "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    @include color-function($unicare-green-primary, $base-color);
    border: 1px solid $unicare-green-primary;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      @include color-function(darken($unicare-green-primary, 10%), $base-color);
      border-color: darken($unicare-green-primary, 10%);
    }
  }
  .btn-default,
  .btn-primary {
    @include color-function($unicare-green-primary, $base-color);
    border: 1px solid $unicare-green-primary;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      @include color-function(darken($unicare-green-primary, 10%), $base-color);
      border-color: darken($unicare-green-primary, 10%);
    }
  }

  .btn-secondary {
    @include color-function($white, $unicare-green-primary);
    border: 1px solid $unicare-green-primary;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      @include color-function($base-color, darken($unicare-green-primary, 10%));
      border-color: darken($unicare-green-primary, 10%);
    }
  }

  a {
    color: $unicare-green-primary;
  }
}

// flout specialty panel Adding these behalf of Ross, Andre

.flyout-spec-panel {
  top: 6rem;
  background-color: #fff;
  .hw-content-container {
    top: 8rem;
    right: 0;
    background-color: #fff;
    padding: 0 0 4rem 4rem;
    height: 100%;
    overflow-y: hidden;
    .content {
      text-align: left;
      font-size: 1.2rem;
    }
    .HwStructuredContent h3,
    h3 {
      font-weight: normal !important;
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
    .HwSection p,
    p {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }
    &.in-tab {
      padding: 0;
      margin-top: 2rem;
      .hw-content-in-tab {
        padding: 0;
      }
    }
  }
}

.hw-content-container.speciality-container {
  h3 {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #000;
  }
  p,
  .HwSection p {
    font-size: 14px;
  }
}

.pf2-float-right {
  float: right;
}
.pf-procedure-details {
  padding-top: 7px;
  font-weight: 600;
  font-size: 14px !important;
  .pf-foryou-label {
    color: white;
    background: #3bb273;
    border-radius: 0.5px;
    display: inline-block;
    font-size: 9px !important;
    padding: 3px;
  }
  .motif-circled-right {
    color: #999;
    font-size: 20px !important;
  }
}

.spec-details {
  a {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
  }
}

@at-root .pm-details {
  a {
    text-decoration: none !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}

@at-root .personalized-match {
  padding: 2px;
  width: max-content;
  a {
    text-decoration: none;
    width: 181px;
    height: 16px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #286ce2;
  }
  .motif-icon {
    font-size: 1.25rem !important;
  }
}

.modal-container.slide-in.right .close .close-icon:before {
  font-size: 2rem;
}

@media (max-width: $breakpoint-sm) {
  .modal-container.slide-in.right .close {
    top: 0;
    position: fixed;
  }
  .modal-container.slide-in.right .modal-panel {
    width: 100%;
  }
}

.promoted-procedures-tile-cmp {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .pf2--promoted-procedures-tile {
    display: inline-flex;
    border: 1px solid #e0e0e0;
    margin: 1rem;
    background: white;
    box-shadow: 0px 6px 12px 0px rgb(0 0 0 / 8%);
    .small {
      border: none; // PL over-ride
      position: relative;
      padding-bottom: 30px; //leave room for compare link
      .description-section {
        box-shadow: none; // PL over-ride
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        font-family: Lato, sans-serif;
        .optional-badge {
          background-color: #3bb273;
        }
        .section-header-three {
          font-size: 1.25rem;
          font-weight: 500;
          color: #333333;
          &:after {
            height: calc(100% - 2.5rem);
          }
        }
        .description-text {
          font-size: 0.875rem;
          color: #333;
          line-height: 1.57;
          @media (max-width: $breakpoint-sm) {
            width: auto; // UXD PL over-ride to allow full-width cards on mobile
          }
        }
        .one-line-description-text {
          font-size: 0.875rem;
          color: #333;
          line-height: 1.57;
          height: 20px;
          overflow: auto;
          display: inline-block;
          @media (max-width: $breakpoint-sm) {
            width: auto;
          }
        }
        .read-more-link {
          text-align: left;
          color: #286ce2;
          display: inline-block;
          padding-bottom: 10px;
          a {
            text-decoration: none;
            font-weight: bold;
            font-size: 0.875rem;
          }
        }

        .range-text {
          font-size: 0.75rem;
          line-height: 1.67;
          color: #747474;
        }
      }
      .image-section {
        .img {
          height: 14.063;
        }
      }
      .contact-section {
        position: absolute;
        bottom: 0px;
        left: 20px;
        .contact-link.motif-right-round {
          width: 100%; // PL over-ride
          &:focus {
            width: 85%;
          }
        }
      }
      .actionItems-section {
        position: absolute;
        right: 25px;
        bottom: 35px;
        display: flex;
        .action-item-button {
          &:after {
            content: "\22EE";
          }
        }
        .action-item-feedback-menu {
          z-index: 1;
        }
        .action-item-feedback-menu a {
          text-decoration: none;
          text-align: left;
        }
      }
    }
  }
}

.modal-component-body.pf-map-modal-container {
  .promoted-procedures-tile-cmp {
    @media screen and (max-width: $breakpoint-md) {
      flex-direction: column;
    }
  }
  .fixed-tray.map-sticky-footer .tray-button-row .btn-secondary {
    width: auto;
  }
}
.pf-machine-readable-container {
  margin-top: 20px;
  a {
    border: none;
    text-decoration: none;
    color: #286ce2;
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    font-weight: normal;
  }
}

.videoVisitSso {
  a {
    color: #333;
  }
}

.card-col-md-4 {
  // width: 31%;
  margin-right: 2%;
  float: left;
  display: flex;
  padding: 0;
  align-items: center;
  border: none;
  border-radius: 9px;
  cursor: default;
  max-width: calc(33% - 14px);
  @media (max-width: $breakpoint-sm - 50) {
    margin-bottom: 25px;
    width: 100%;
    max-width: 100%;
  }

  .box-container {
    width: 60%;
    display: flex;
    @media (max-width: $breakpoint-sm) {
      // margin-right: 20px;
    }
    @media (max-width: $breakpoint-sm - 50) and (min-width: $breakpoint-xs) {
      width: 20%;
    }
  }
  .box-container-non-clinical {
    display: flex;
    height: 138px;
  }
  .card-content {
    margin: 0 10px 10px 10px;
  }
  dt.sub-header {
    font-size: 18px;
    padding-top: 10px;
    text-align: left;
    height: 50px;
    overflow: hidden;
    line-height: 21px;
  }
  .infoBox.pf2-ptb-0.pf2-mar-rt-10 {
    padding-left: 0;
  }
  .pf-back-link-text {
    vertical-align: text-bottom;
  }
  .pf-back-to-link {
    padding: 0;
    padding-right: 10px;
    @media (max-width: $breakpoint-md) and (min-width: $breakpoint-sm - 50) {
      float: left;
      padding-right: 0;
    }
    .pf-back-link .pf-back-link-text {
      @media (max-width: $breakpoint-md) and (min-width: $breakpoint-sm - 50) {
        margin-left: 2px;
      }
    }
  }
}

.cards-container {
  .app-card-loop {
    &:last-child {
      .card-col-md-4 {
        margin-right: 0;
      }
    }
  }
}

.rtModalContent {
  .card-col-md-4 {
    margin-bottom: 25px;
    width: 46%;
    float: none;
    overflow: hidden;
    max-width: 100%;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      max-width: 100%;
    }
    .card-slider-gap {
      margin-left: 20px !important;
    }
    .box-container {
      @media (max-width: $breakpoint-sm) {
        margin-right: 20px;
      }
    }
    .limit-text {
      @media (max-width: $breakpoint-sm) {
        display: inline-block !important;
      }
    }
  }

  h2.pf2-pb-0 {
    padding-bottom: 0;
  }
}
.icon-gray {
  font-size: 20px !important;
  color: #999;
}
.pf2-PCPbug {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  justify-content: center;
  a.pf2-mar-0 {
    margin: 0;
  }
  .display-inblk {
    display: inline-block;
    width: 100%;
  }
}
ul.pf2-actionItemList .infoBox.pf2-mar-rt-10 {
  margin-right: 10px;
}
.pf-side-panel .pf2-card-white.card-col-md-4 {
  padding-bottom: 0;
}
.pf-side-panel .card-col-md-4 .box-container {
  width: 25%;
  @media (min-width: $breakpoint-md + 1) {
    width: 60%;
  }
}
.box-height {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  display: block;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  object-position: top;
}
.rounded-icons {
  height: 97px;
  width: 97px;
  margin: 0 auto;
}
.limit-text {
  height: 40px;
  overflow: hidden;
  margin-bottom: 10px;
}

.onsiteinfo-slidein {
  .content {
    text-align: left; // UXDPL override
  }
  h4 {
    font-size: 14px;
    font-weight: bold;
  }
}

.onsiteinfo-slidein ul {
  padding-left: 1rem;
  margin-bottom: 0px;
  &.fad-pf-list-nobullet {
    list-style: none;
    padding-left: 0px;
  }
}
//Activity
.pf-split-col-adjust {
  display: flex;
  position: relative;
  left: 0;
}
.colorgray {
  color: #808080;
}

.headingRecent {
  font-size: 18px;
  font-weight: 400;
  color: #000000c9;
}
.padding-10 {
  padding: 1rem 0;
}
.padding-5 {
  padding: 0.25rem 0;
}

.pf-justify-content-between {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 2rem;
}

.searchAddressResponsive {
  @media screen and (max-width: $breakpoint-xs) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.pf1-split-col {
  width: 50%;
  float: left !important;
  &:first-child {
    float: left;
    margin-right: 40px;
  }
  &:last-child {
    float: right;
  }
  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.ra-pf-bold {
  font-weight: bold;
}

.ra-pf2-split-col {
  width: 50%;
  float: left !important;
  &:first-child {
    float: left;
    margin-right: 110px;
  }
  &:last-child {
    float: right;
  }
  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
  &.firstchildpfSplit {
    width: 44%;
    box-shadow: 0 6px 12px #00000014;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    flex: 1 1 calc(50% - 14px);
    text-align: center;
    margin-right: 14px;
    max-width: calc(50% - 14px);
    min-width: 120px;
    margin-bottom: 14px;
  }
}

.fontSizeLarge {
  margin-bottom: 1rem;

  span {
    font-size: 1.76rem !important;
    font-family: "Montserrat", sans-serif;
    color: #333;
  }
}

h4.modal-heading {
  font-size: 26px !important;
  padding: 2rem 0;
  font-weight: 600 !important;
  font-family: "Lato", sans-serif;
}

.modal-new-body {
  li {
    margin-bottom: 5px;
  }
}

.new-sample {
  margin-bottom: 15px;
}

.hide-bullets {
  padding-left: 15px;
}
.pf2-card-white.pf2--cardcontent {
  padding-top: 1rem;
}

.speciality-modal-container {
  h2:first-child {
    margin-bottom: 40px;
  }
}

.inline-items {
  display: inline;
}
.item-font {
  font-family: "Lato";
  margin-left: 0px;
}
.more-icon {
  .action-item-button {
    right: -10px;
  }
  .action-item-feedback-menu {
    z-index: 1;
  }
  .action-item-feedback-menu a {
    text-align: left;
  }
}
.link-font {
  font-size: 12px;
  font-family: "Lato", sans-serif;
}
.view-claims {
  display: flex;
}
.pf-options-v2.pf2-iconCards {
  margin-bottom: 0;
  .pf2-card-white {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.care-action-theme.pf2-flexcardrow.pf2-flex-2col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin: 3.7rem -14px 1rem 0;
  @media (max-width: $breakpoint-sm) {
    display: block;
  }
  .pf2-iconCards.pf-options-v2 {
    display: block;
    margin-top: 10px;
    .pf2-card-white {
      min-height: 112px;
    }
  }
  .pf2-flexcardwrapper.pf2-flexcardwrapper-withtoplinks {
    min-height: 1px;
    flex: 1 1 calc(50% - 14px);
    text-align: center;
    margin-right: 14px;
    max-width: calc(50% - 14px);
    min-width: 120px;
    margin-bottom: 14px;
    &:last-child {
      margin-right: 0px;
      margin-left: 12px;
    }
    @media (max-width: $breakpoint-sm) {
      display: block;
      width: 100%;
      max-width: 100%;
    }
    .options-tiles {
      margin-bottom: 0;
      max-width: calc(100% - 14px);
    }
    .more-options {
      margin-top: 7px;
    }
    .care-action-items {
      display: block;
      width: 100%;
    }
  }
  .options-horizontal {
    display: flex;
    flex-direction: row;
    max-width: 100% !important;
    width: 100%;
    .options-tiles {
      width: 100%;
      .pf2-iconCards.pf-options-v2 {
        display: flex;
        width: 100%;
        max-width: 100%;
        flex-direction: row;
        .pf2-card-white {
          text-align: center;
          min-height: 112px;
          flex: 1 1 calc(50% - 14px);
          max-width: calc(50% - 14px);
          min-width: 120px;
          margin-bottom: 14px;
          margin-right: 14px;
          &:only-child {
            max-width: 100%;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.pf2-card-white.pf2--cardcontent.care-team-v2 {
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 12px;
  .pf2-careTeamList.care-seperator {
    border-bottom: 1px solid #ccc;
    &:last-child {
      border-bottom: none;
    }
    .pf-careTeamNameAddress {
      display: flex;
      @media (max-width: $breakpoint-sm) {
        display: block;
      }
      .provider-container-v2 {
        flex-basis: 80%;
      }
      .card-phone {
        flex-basis: 15%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (max-width: $breakpoint-sm) {
          display: block;
        }
      }
    }
  }
}

.care-header-wrapper2 {
  margin: -20px -40rem 0 -40rem;
  padding: 10px;
  @media screen and (max-width: $breakpoint-xs) {
    margin: 0;
    padding: 10px 0px;
  }
  .careHeaderBackground {
    background: #f1f2f3;
  }
  .pf-care-team-container {
    padding-bottom: 0;
    .pf-justify-content-between {
      @media screen and (max-width: $breakpoint-xs) {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }
  }
}
