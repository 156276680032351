@import "variables";

.fixed-tray {
  @import "../../../../node_modules/@anthem/uxd/assets/styles/themes/motif.theme";

  background-color: $ant-motif-white;
  color: $ant-motif-highlight-ocean;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 350;
  -webkit-box-shadow: 0 -1px 18px -4px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 -1px 18px -4px rgba(0, 0, 0, 0.14);
  box-shadow: 0 -1px 18px -4px rgba(0, 0, 0, 0.14);
  &_collapsed {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    visibility: hidden;
    transition: bottom 2500ms ease-out 1ms, visibility 1ms linear 501ms;
  }
  &_expanded {
    visibility: visible;
    bottom: 0;
    transition: bottom 500ms ease-out 1ms, visibility 1ms linear;
  }

  .tray-button-row {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding: 1.25rem 0;

    .btn {
      font-size: 14px;
      padding: .5rem 2rem;

      a {
        text-decoration: none !important;
        font-family: Lato, sans-serif;
        color: #ffffff;
      }
    }

    .btn-margin-right, .btn:not(:last-child) {
      margin-right: .5rem;
    }

    .img {
      margin: 0 0 5px 5px !important;
      width: 15px;
      height: 15px;
    }
  }
}
