.assign-pcp-container-page {

  .nav-link-container {
    padding-top: 50px;
  }

  .pf-assign-pcp-v2-cmp {

    h3 {
      margin: 0.75rem 0 0.75rem;
    }

    .m-tb-1 {
      margin: 0.25rem 0 0.25rem;
    }

    .m-tb-2 {
      margin: 0.5rem 0 0.5rem;
    }

    .m-tb-3 {
      margin: 0.75rem 0 0.75rem 0;
    }

    .pt-10 {
      padding-top: 10px;
    }

    .pt-20 {
      padding-top: 20px;
    }

    .pl-20 {
      padding-left: 20px;
    }

    .m-b-large {
      margin-bottom: 5rem;
    }

    .prLabel {
      line-height: 20px;

      .pcp-plan-name {
        padding: 0px 5px 0px 5px;
      }

      .pcp-plan-name-suffix {
        font-weight: bold;
        padding: 0px 10px 0px 10px;
      }
    }

    .afflns-radio-container {
      margin: 0px 0px 20px 0px;

      .affln-pcp-id-row {
        margin-left: 0.5rem;
      }

      .affln-pcp-id {
        text-transform: uppercase;
      }

      .affln-pcp-plan-name {
        padding: 0px 5px 0px 5px;
      }

      .affln-pcp-plan-name-suffix {
        font-weight: bold;
        padding: 0px 10px 0px 10px;
      }
    }

    .afflns-container {
      margin: 0px 0px 5px 0px;

      .affln-pcp-id-row {
        margin-left: -15px;
      }

      .affln-pcp-id {
        text-transform: uppercase;
      }

      .affln-pcp-plan-name {
        padding: 0px 5px 0px 5px;
      }

      .affln-pcp-plan-name-suffix {
        font-weight: bold;
        padding: 0px 10px 0px 10px;
      }
    }
    .ant-alert {
      padding: 0!important;
      margin-top: 0;
      border: #ccc solid 1px;
      border-left: 5px #286ce2 solid;
      .ant-alert-left {
        padding: 0.2rem;
        background-color: #fff;
        .alert-icon.alert-icon-info::before {
          content: "\e9f9";
          color: #286ce2;
        }
      }
      .ant-alert-main {
        background: #fff;
        padding: 0 15px;
      }
    }
    label.disabled {
      opacity: 0.65;
    }
  }

}

.m-tb-1-rem {
  margin: 1rem 0 1rem 0;
}
