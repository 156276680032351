@import "variables";

.provider-details {
  a {
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }

  .pf-details-tabs {
    .pf-details-tabs, .ant-tab-header {
      padding-top: 1rem;
    }
    >.details-menu-tabs{
      &.hide-tab-header {
        button.ant-tab-button{
          visibility: hidden !important;
        }
      }
      >.ant-tab-header {
        float: right;
	      max-width: 100%;
        min-width: 50%;
        @media screen and (max-width: $breakpoint-sm) {
          float: none;
        }
      }
    }

    .ant-tab-button {
      background-color: $ant-motif-white;
      &.is-active {
        font-weight: 700;
      }
    }

    .ant-tab-header {
      display: flex;

      .ant-tab-list {
        flex: 1 0 100%;
        padding-bottom: 3rem;
      }
    }

    .tab-body {
      padding-left: 15px;
      clear:both;
    }
  }

  .col-sm-6.locationMapContainer {
    @media screen and (max-width: $breakpoint-sm) {
      padding-left: 0;
    }
  }

  .pf-location-wrapper {
    .motif-marker {
      color: $ant-motif-highlight-green;
    }

    .fad-flex {
      flex-flow: column;
    }

    .pf-location-flex {
      display: flex;
      @media screen and (min-width: $breakpoint-sm) {
        justify-content: space-between;
      }

      .pf-location-block-end {
        @media screen and (max-width: $breakpoint-sm) {
          padding-left: 11rem;
        }
      }
    }

    .fp-map-container {
      margin-bottom: 1rem;
    }
  }

  .select-address-dropdown {
    @media screen and (min-width: $breakpoint-sm) {
      width: 50%;
      float: right;
    }

    legend {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .popover {
    .popover-close {
      a .fa {
        color: $primary-color-dark-blue;
      }
    }
  }

  .summary-data-container {
    .insurance-accepted-header {
      padding-bottom: 3rem;
    }

    .insurance-label {
      padding-bottom: 1rem;
      font-weight: 600;
      font-size: 18px;
    }

    ul.dashed {
      list-style-type: none;
      padding-left: 0;
    }
    ul.dashed > li {
      text-indent: -5px;
    }
    ul.dashed > li:before {
      content: "-";
      text-indent: -5px;
      padding-right: 5px;
    }

    .insurance-plans-container {
      padding-bottom: 1rem;
    }

    // in-plan "table"
    ul.in-network-key {
      padding: 0;
      margin: 1rem 0;
      >li {
        display: flex;
        flex-wrap: wrap;
        &:nth-child(odd) {
          background-color: #f0f0f0;
        }
        &:nth-child(even) {
          background-color: #fff;
        }
        .in-network-key-term, .in-network-key-icon, .in-network-key-definition {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 1rem;
        }
        .in-network-key-term {
          width: 30%;
          font-size: 16px;
        }
        .in-network-key-icon {
          width: 20%;
          font-size: 16px;
        }
        .in-network-key-icon-checkmark, .in-network-key-icon-na {
          color: #53b1a3;
        }
        .in-network-key-icon-notinplan {
          color: #d20a3c;
        }
        .in-network-key-definition {
          width: 50%;
          font-size: 14px;
        }
        .faux-icon-na::before {
          content: "N/A"
        }
        @media screen and (max-width: 480px) {
          .in-network-key-term {
            width: 100%;
          }
          .in-network-key-icon {
            width: 15%;
          }
          .in-network-key-definition {
            width: 85%;
          }
        }
      }
    } // end in-plan "table"

  }

  .recognition-tab-header,
  .insurance-tab-header,
  .service-tab-header,
  .pricing-tab-header {
    padding-bottom: 2rem;
  }

  .recognition-tab {
    .award-title__details {
      font-size: 22px;
      padding-bottom: 1rem;
    }
    .award-text-border {
      border-left: 2px solid #E3E1E1;
    }

    .award-text {
      padding-left: .5rem;
      @media screen and (min-width: $breakpoint-sm) {
        width: 33.33%;
      }
    }
  }
}
