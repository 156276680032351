@import "./_variables.scss";
@import "./fad-provider-finder.scss";
@import "./eyc-cost-finder.scss";
@import "./fad-provider-details.scss";
@import "actionable-header";
@import "motif-fad-finder";
@import "motif-pf-details";
@import "motif-overrides";
@import "fixed-tray";
@import "fad-landing-page";
@import "variables";
@import "integrated-search";
@import "_careteam-v2.scss";
@import "fad-assign-pcp-page.scss";

ul.no-padding {
  padding: 0;
  margin-top: 1rem;
}

.ant-special-banner-discount {
  border-top: 4px solid #06a59d;
  padding: 20px 0;
  margin: 20px 0 0 0;
  background: #eeeeee;
  .ant-special-discount-img {
    margin-top: 5px;
    img {
      width: 100%;
    }
  }
  .ant-special-discount-banner-content {
    a {
      border-bottom: none;
    }
    .ant-special-discount-deal-of-day {
      font-size: 22px;
      font-weight: 600;
      color: #06a59d;
      margin-bottom: 5px;
    }
    .ant-special-discount-expiry-date {
      margin-top: 15px;
      .ant-special-discount-expiry {
        margin-left: 10px;
      }
    }
  }
}

@media (min-width: 767px) {
  .ant-contact-message .call-button {
    display: none;
  }
  .ant-contact-message .call-link {
    display: inline;
  }
}

@media (max-width: 767px) {
  .ant-contact-message .call-button {
    display: none;
  }
  .ant-contact-message .call-link {
    display: inline;
  }
  .id-card-card {
    margin-top: 1rem !important;
  }
}

/* START: delete once in UX package ********/

/* END: delete once in UX package **********/

/*page transition start*/
.ui-widget-overlay {
  background-color: $black;
  height: 100%;
  left: 0;
  opacity: 0.7;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 1099;
}

.fad-page-load {
  .ui-widget-overlay {
    background-color: $black;
    height: 100%;
    left: 0;
    opacity: 0.7;
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 1099;
  }

  &.hidden {
    .ui-widget-overlay {
      height: 0;
      width: 0;
      z-index: 0;
    }
  }
}

.ajax-load {
  display: block;
  outline: 0 none;
  overflow: hidden;
  width: 280px;
  z-index: 2000;
  height: auto !important;
  left: 50% !important;
  margin-left: -140px !important;
  //margin-top: -20px !important;
  position: fixed !important;
  top: 50% !important;
  //background-color: $base-color;
  padding: 15px;
  border-radius: 6px;
  text-align: center;
  color: $base-color;

  .ajax-spin {
    float: left;
    font-size: 25px;
  }

  .ajax-text {
    float: right;
  }
}

.centerFlex {
  align-items: center;
  display: flex;
  justify-content: center;
}

/*page transition end*/

.expand-id {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.01);
}

a.icon-item {
  display: inline-block;

  .glyphicon {
    margin-right: 6px;
  }
}

a.icon-item,
button.icon-item {
  background: transparent;
  color: $accent-color-salmon;
  padding: 9px 5px 7px;
  position: relative;
  margin-right: 20px;
  font-size: 16px;
}

.btn-group.link-style {
  top: -3px;
  padding: 9px 0px 7px;
  margin: 0px;
}

.btn-group.link-style button,
.btn-group.link-style button.btn:first-child {
  padding: 3px 0px;
  margin-left: 10px;
  background: transparent;
  color: $accent-color-salmon;
  border: 2px solid;
  border-radius: 4px;
  display: block;
}

.claims-detail-controls .btn-group.open .btn.dropdown-toggle,
.claims-detail-controls .btn-group.open .btn.dropdown-toggle:focus,
.claims-detail-controls .btn-group.open .btn.dropdown-toggle:active {
  box-shadow: none;
}

.btn-group.link-style li a.icon-item {
  @include color-function(transparent, $accent-color-salmon);
  display: block;
  margin-bottom: 5px;
}

.fax-input-container {
  width: 100%;
}
.email-input-container {
  width: 100%;
  margin-bottom: 0px;
  margin-top: 1rem;
}

.email-message-container {
  width: 100%;
  margin-bottom: 30px;
}

.email-message-container .item,
.fax-message-container .item {
  width: 100%;
  margin-bottom: 30px;
}

.error-wrapper,
.error-reLoad {
  position: relative;
  padding: 10px 15px;
  margin-bottom: 10px;
  color: red;
}

.error-wrapper ul {
  list-style-type: none;
}

.error-container .error-reload {
  margin-left: 40px;
  cursor: pointer;
}

.error-container {
  margin: 10px 15px 20px 15px;
}

/*compose msg start*/
.catLvl2 {
  padding-left: 40px;
}
/*compose msg end*/

/*404 and 500 error*/
.ant-error-container {
  margin-top: 85px;
  min-height: 300px;
}
.ant-error-container h1 {
  margin-top: 70px;
  margin-bottom: 20px;
}
.ant-error-container ul {
  list-style: square;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  position: relative;
  left: 30%;
}
.ant-error-container ul li {
  margin-bottom: 10px;
  margin-left: 20px;
  padding-right: 15px;
}

@media screen and (max-width: 800px) {
  .ant-error-container p > br {
    display: none;
  }
}
@media screen and (min-width: 480px) {
  .ant-error-container ul {
    width: 400px;
    left: 5%;
  }
  .ant-error-container ul li {
    float: left;
    width: 44%;
  }
}

@media screen and (min-width: 801px) {
  #ant-logout-link-tb.data-fad-logout-link {
    display: inline-block;
  }
}
// CSS fo Global Footer Links
.foreseeFooterLink {
  display: block;
  margin: 0.6rem 0;
}
#footer-block-3 li.fa-globe {
  width: 56px;
}

// Class for hiding identity protection link
.floating-footer-links-display {
  display: none;
}

.ant-text-input.ant-has-notes,
.ant-text-input.ant-has-notes:focus {
  z-index: 1;
}

#sskyplayer {
  width: 100% !important;
}

// Navigation - PROFILE RESPONSIVE VIEWS
.fad-top-of-page-wrapper.ant-secure-nav-overrides #ant-profile-link-tb .ant-task-bar-widget {
  display: inline-block;
}

@media screen and (max-width: 800px) {
  .ant-special-discount-img {
    padding: 0;
  }
  .ant-special-discount-banner-content {
    margin-top: 10px;
    padding: 0;
  }
  .ant-task-bar-widget {
    width: 100%;
  }
  #fad-profile-responsive-hdr {
    width: 100%;
    margin: 0px;
    a {
      padding-top: 25px;
    }
  }
  #fad-profile-responsive-hdr .fa.fa-angle-down {
    color: #333;
  }
  #ant-profile-link-tb.ant-menu-expanded .fa.fa-angle-down {
    transform: rotateX(180deg);
    transition: transform 0.35s ease;
  }
  #ant-profile-link-tb .fa.fa-angle-down {
    transform: rotateX(0deg);
    transition: transform 0.35s ease;
  }
  .data-fad-profile-link {
    padding: 0px;
    margin-right: 0rem;
    a {
      padding: 10px 0px;
    }
  }
  .ant-navigation-wrapper .ant-margin-bot-20 {
    margin-bottom: 0px;
  }
  #ant-profile-link-tb.ant-menu-expanded {
    background: #0079c2;
    a {
      color: white;
    }
    .ant-heading-two .fa {
      color: white;
    }
  }
  .fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-navigation-wrapper li a.fad-profile-link-nav {
    font-size: 14px;
  }
  #fad-profile-link-responsive li {
    margin: 0.15rem 0px;
    float: left;
    cursor: pointer;
    &:hover {
      background: none;
    }
  }
  #ant-logout-link-tb.data-fad-logout-link {
    padding: 10px 15px;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  #ant-profile-link-tb.data-fad-profile-link .ant-task-bar-widget {
    transition-delay: 0.3s, 0.15s, 0.1s;
    transition-duration: 0.35s, 0.45s, 0.15s;
    transition-property: border, left, opacity;
    transition-timing-function: ease-out, ease-out, ease-in;
  }

  .fad-mobile-menu-divider {
    border-top: 1px solid #d8d8d8;
    width: 100%;
    height: 1px;
    // margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fad-flex {
  display: flex;
}

$btn-tomato: #db3b19;
$btn-white: #ffffff;
//These are not Motif colors for buttons
//.fad-action-buttons{
//  .btn-secondary{
//    color: $btn-tomato!important;
//    border: 1px solid $btn-tomato!important;
//    &:hover,
//    &:focus {
//      color: $btn-white!important;
//    }
//  }
//  .btn-primary{
//    background-color:$btn-tomato!important;
//    &:hover,
//    &:focus {
//      background-color:#e82d05!important;
//    }
//  }
//}

/* AutoSuggest Component */
.fad-auto-suggest {
  text-align: left;
  .psDropdown {
    margin-top: 2.1rem;
    &.active {
      overflow-y: hidden;
      & > div {
        position: relative;
        overflow-y: auto;
      }
    }
  }
  legend {
    border: none;
  }
  .ant-text-input {
    width: 100%;
    @media screen and (min-width: $breakpoint-xs) {
      width: 350px;
    }
    @media screen and (min-width: $breakpoint-sm) {
      width: 900px;
    }
  }
  .no-suggestions {
    padding: 8px 1rem;
  }
}
/* End of AutoSuggest Component */
#screenreaderText {
  opacity: 0;
  height: 1px;
  overflow: hidden;
}
.justifyLeft {
  justify-content: left;
}
[data-uxd-radio-input-cmp] {
  .prLabel {
    line-height: 25px;
  }
}

.ant-alert {
  .ant-alert-main {
    color: #333333;
  }
}

.blodText {
  font-weight: bold;
}
.pfCheckbox-listView,
.pfCheckboxInputCmp {
  .pcLabel {
    width: 30px;
    height: 17px;
    font-size: 1px;
    color: transparent;
    margin-right: 0;
    margin-left: 0;
    &::before {
      font-size: 1rem;
      color: initial;
      margin-left: 0 !important;
    }
    &.focus::before {
      box-shadow: 0 0 8px 1px #005b99 !important;
    }
  }
}
.filter-tab {
  h2 {
    background-color: #167abd !important;
  }

  .motif-filter {
    color: $ant-motif-white;
    padding-right: 0.25rem;
  }
}

// action item component --------------------
.action-item-button {
  background: none;
  position: absolute;
  right: -20px;
  border: none;
  &:after {
    content: "\22EE";
    font-size: 40px;
    color: #999;
    font-weight: bold;
  }
}
.action-item-menu {
  list-style-type: none;
  width: auto;
  border: 1px solid #dedede;
  border-radius: 5px;
  box-shadow: 0 6px 12px 0 rgb(0 0 0 / 8%);
  margin: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;
  background: white;
  text-align: left;
  &.xtoggle-body li {
    padding: 0;
  }
  a {
    font-family: Lato, sans-serif;
    font-size: 12px !important;
    color: #2a67d1;
    white-space: nowrap;
    font-weight: bold;
    padding: 0.5px 0.5px;
    text-align: center;
    margin: 0px;
  }
  div {
    margin: 0px !important;
    padding: 6px 0 !important;
    line-height: 1.2rem !important;
  }
}

.action-item-feedback-menu {
  list-style-type: none;
  min-width: 75%;
  border: 1px solid #dedede;
  border-radius: 5px;
  z-index: 999;
  background: rgba($color: white, $alpha: 1) !important;
  text-align: left;
  position: absolute;
  top: -20px;
  right: 15px;
  padding: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  a {
    font-family: Lato, sans-serif;
    font-size: 12px !important;
    color: #2a67d1 !important;
    white-space: nowrap;
    font-weight: bold !important;
    padding: 10px !important;
    text-align: center;

    #interested-icon,
    #not-interested-icon {
      font-size: 12px !important;
    }
  }

  a:hover {
    background-color: #2a67d1;
    color: #ffffff !important;

    .motif-icon {
      color: #ffffff !important;
    }
  }

  div {
    margin: 0px !important;
    padding: 0 !important;
    line-height: 1.2rem !important;
  }
}
// end action item component
.feedback-side-panel {
  display: flex;
  flex-flow: column;

  .feedback-side-panel-header {
    flex: 0 1 auto;
  }

  .feedback-side-panel-body {
    flex: 1 1 auto;

    .rtModalContentTop {
      margin-bottom: 1rem;
    }

    .reason-title {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .reason-txt-container {
      margin-left: 1.5rem;
      width: 100%;

      #txt-reason-code-other {
        margin-left: 10px;
        width: calc(100% - 25px);
      }
    }
  }
  .reason-container {
    margin-left: 0px !important;
  }

  .feedback-side-panel-footer {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row-reverse;
    border-top: 2.5px solid rgba($color: gray, $alpha: 0.1);

    @media screen and (max-width: $breakpoint-sm) {
      .fad-action-buttons {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
      }
      .fad-action-buttons > .btn {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.pf-radio-group-cmp {
  .pf-radio-btn-cmp {
    margin: 15px 0 15px 0;
    display: block;

    input[type="radio"],
    input.radio {
      margin: 0 10px 0 10px;
      display: inline-block;
    }

    label {
      padding: 0px 0px 0px 15px;
      margin: -22px 0 0 20px;
      font-weight: normal;
    }

    .pcp-plan-name {
      font-weight: normal;
    }

    .pcp-plan-name-suffix {
      font-weight: bold;
      padding: 0 0 0 10px;
    }
  }
}

.map-overlay-container {
  position: absolute;
  left: 20px;
  top: 20px;
}

.btn-map-overlay {
  background: #ffffff;
  width: 125px;
  height: 26px;
  border-radius: 13px;
  font-size: 12px;
  color: #000;
  border: #4d4d4d solid 1.5px;
  box-shadow:
    0 3px 3px 0 rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  &:hover {
    background: #37475a;
    border-color: #2c3747;
    color: white;
  }
  &:focus {
    background: #37475a;
    border-color: #2c3747;
    color: white;
  }
}
//Styling for the Button to switch to Free Text Search beta.
.fts-search-beta {
  flex: auto;
  display: flex;
  background-color: #fe5e3c;
  color: #fff;
  height: fit-content;
  margin: 1rem 7.5rem;
  align-items: center;
}
.denial-of-service-cmp {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 5rem 0;
  .img {
    margin-bottom: 20px; /* Add some space between the image and the message */
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
