@import "variables";

.eyc-container {
  width: 178px !important;

  .eyc-location-wrapper {
    .eyc-location-item {
      display: flex;
      flex-flow: column nowrap;
      margin-bottom: 1rem;

      .eyc-location-link {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .eyc-show-more {
    font-size: 14px;
  }
}

.eyc-ctrl-width {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 900px;
}

.eyc-margin-top-70{
  margin-top: 1.75rem;
}

.eyc-pf-search-wrapper {
  #showSplDetails,
  #hideSplDetails {
    margin-top: 1rem;
    display: inline-block;
  }
  .search-input-container {
    label {
      margin-bottom: 0.7143rem;
    }
    input {
      width: 100%;
    }
  }
  .search-input-container,
  .dropdown-container {
    margin-bottom: 2.5rem;
  }
  .checkbox-container label {
    margin-bottom: 15px;
  }
  .show-more-options-container {
    margin-bottom: 25px;
    a:hover {
      cursor: pointer;
    }
  } //popover is having dropdown content show through, increasing z-index
  .popover {
    z-index: 9999999;
  }
}

.eyc-pf-search-wrapper {
  .popover {
    &.top,
    &.bottom,
    &.left,
    &.right {
      z-index: 999999999;
    }
  }

  .eyc-pf-input-lbl {
    font-size: 14px;
    font-weight: 600;
  }

  .dropdown-container,
  .search-input-container,
  .showSplDetailsLink,
  #hideSplDetails {
    margin-bottom: 2rem;
  }
  #showSplDetails,
  #hideSplDetails {
    margin-top: 2rem;
    display: inline-block;
  }
  .checkbox-container {
    legend {
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
    label {
      font-weight: 400;
    }
  }
  .show-more-options-container {
    margin-bottom: 2rem;
  }
  .pcLabel {
    margin-right: 0px;
  }
  .popIcon {
    margin-left: 0px;
  }
}

.eyc-pf-search-wrapper{
  .search-form{
    .search-input-container{
      input{
        width: 600px;
        max-width: 100%;
      }
      .geo-pf-href-no-border{
        display: block;
      }
    }
  }
  @media screen and (min-width: $breakpoint-sm) {
    .eyc-toggle-minus{
      padding-right: 15px;
    }
  }
}

.eyc-margin-top-35 {
  margin-top: 35px;
}

.eyc-center-content{
  display: block;
  margin: auto;
  .eyc-alphabet-tab{
    .ant-alphabet-tabs-container{
      li{
        &:hover,
        &:focus{
          background-color:#00609a!important;
          a {
            color: #ffffff;
          }
        }
      }
    }
  }
}

.eyc-center{
  text-align: center;
}

.eyc-alphabet{
  padding-left: 15px;
  padding-right: 15px;
}

.eyc-left-15{
  padding-left: 15px;
}

.eyc-data-container {
  margin: 0px;
  .ant-auto-suggest {
    .psDropdown {
      position: relative;
      height: auto;
    }
  }
}

.eyc-toggle-minus {
  text-align: right;
}

.eyc-cost-desc {
  padding-top: 20px;
  width:705px;
}
.eyc-cost-desc .eyc-cost-desc-heading {
  padding-bottom: 5px !important;
}
.eyc-cost-desc dl {
  width: 700px !important;
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
}
.eyc-cost-desc dt {
  float: left !important;
  width: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 5px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin: 0 !important;
}
.eyc-cost-desc dd {
  float: left !important;
  width: 689px !important;
  padding-top: 0px !important;
  padding-bottom: 5px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin: 0 !important;
  display:block !important;
}

.eyc-cost-desc-pop-up{
  .eyc-cost-desc {
    padding-top: 0px;
    width:260px;
  }
  .eyc-cost-desc dl {
    width: 255px !important;
  }
  .eyc-cost-desc dd {
    width: 244px !important;
  }
}

.eyc-search-container{
  .ant-text-input{
    width: 100%
  }
}

.eyc-cost-search-btn {
  margin: 2rem 0 0;
}

.eyc-toggle-cmp {
  border-top: 0;
}

.eyc-reset-search {
  cursor: pointer;
}

.search-highlight{
  font-weight: bold;
}

@media screen and (min-width: $breakpoint-sm) {
  .offset {
    margin-left: 11.7%;
  }
}

@media screen and (min-width: $breakpoint-xs) {
  .offset {
    margin-left: 11.7%;
  }

  .offset-mbr {
    margin-left: 10%;
  }

  .eyc-cf-dropdown {
    width: 235px;
  }
}

.eyc-discalimer-footer-box{
  margin:10px 0px 10px 0px;
  font-size:0.9em;
}

div.eyc-cost-info {
  margin-bottom: 20px;

  .eyc-you-pay-txt {
    color: $ant-motif-dove-gray;

    .popIcon .glyphicon,
    .popIcon .fa {
      color: $ant-motif-dove-gray;
    }
  }

  .eyc-cost-border {
    border-top: 2px solid #eeeeee;
    width: 100%;
    margin: 10px 0 10px 0;
  }

  .info-msg-wrapper {
    flex-flow: row;
  }

  .eyc-disclaimer-icon {
    display: inline-block;
    width: 12%;
    margin-left: -14%;
    margin-right: 2%;
    vertical-align: top;
    font-size: 18px;
  }

  .eyc-disclaimer-txt {
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 1rem;
    }
  }
 .cost-details-popup, .popover-content{
   color:$ant-motif-black;
   min-width: 230px;
  //  padding:10px;
  .cost-details-popup-container{
    width: 100%;
    display: flex;
  }
  .cost-details-popup-label{
    width: 60%;
  }
  .cost-details-popup-info{
    width: 40%;
  }
 }
}

.procedureInfo {
  > div {
    margin: 10px 0px 10px 0px;

    .fa-exclamation-circle {
      vertical-align: text-bottom;
    }
  }

  div.procedure-cost-info > div {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #979797;
  }

  .item-row > div {
    margin: .5rem 0;
  }

}

 .costDisclaimers {
   li > a {
     width:100%;
   }
 }

 .cost-disclaimer-msg{
   padding: 15px 0px 20px 0px;
 }
.cost-info-container{
  width: 100%;
  max-width: 180px;
  display: flex;
  flex-flow: column;
  padding-bottom: .5rem;
  color: $ant-motif-mine-shaft;

  .cost-info-label{
    margin-right: 5px;
    font-size: 14px;
  }
  .cost-info-content{
    display: flex;
    font-size: 18px;
    font-weight: 600;
    span {
      font-size: 18px;
      color: #000000;
    }
  }
  .office-visit-cost{
    font-size: 14px;
    margin-top: 10px;
  }
  .padding-top-4{
    padding-top: 4px;
  }
  .padding-top-20 {
    padding-top: 20px;
  }
}

.tile-cost-info-container {
  flex-flow: row;
  text-align: left;
  margin-bottom: -10px;
  @media screen and (max-width: $breakpoint-sm) {
    margin-bottom: 10px;
  }
  .cost-info-container {
    display: flex;
    flex-flow: column-reverse;
    margin-top: 10px;
  }
}
.icon-alignment {
  margin-right: 2%;
  font-size: 18px !important;
}

.error-msg{
  margin-left:10px;
}
