.careteam-page-container-v2 {
  background: #fff;
  margin-top: 25px;
  h2 {
    margin: 2rem 0;
  }

  .disclaimer {
    margin: 2rem 0;
  }

  .container .header-description-text{
    padding-bottom: 1rem;
  }

  .container .noprocedures-description-text{
    padding-bottom: 1.5rem;
  }

  .container .findcare-btn{
    padding-top: 1.5rem;
    padding-bottom: 4rem;
  }

  .ant-media-card {

    .description-section {
      background: white;
      .section-header-three:after {
        height: 30px;
        background-color: #3bb273!important;
      }
    }

    .estimatedPrice {
      color: #333333;
      font-size: 14px;
    }
    .planCoverage {
      color:#3bb273;
      font-size: 12px;
      .motif-icon {
        margin-right: 6px;
      }
    }
  }

  // unique styles to each main section
  .procedures-frequently-searched-wrapper {
    .ant-media-card {
      .description-section .freq-searched:after {
        background-color: #286ce2!important;
      }
    }
  }



}
