@import "variables";

.fad-flex {
  display: flex;
}

.fad-f-row {
  flex-direction: row;
}

.fad-f-column {
  flex-direction: column;
}

.tile-review-flex {
  flex-grow: 1;
}

.font-14 {
  font-size: 14px;
}

.greyOut-text {
  color: $ant-motif-dove-dark-gray;
  opacity: 0.8;
}

.pf-view-imp {
  padding-top: 14px;
  text-align: right;
  color: #286ce2;

  .anchor-imp-icon.pf-view-imp-icon {
    &:focus {
      outline-offset: 0px;
    }
  }

  .pf-info-icon {
    color: #286ce2 !important;
    position: relative;
    top: 2px;
    right: 7px;
  }
}

.pf-hr-border {
  border-top: 1px solid #eee;
}

.pf-results-header {
  display: flex;
  justify-content: space-between;
}

.pf-action-links {
  display: flex;

  @media screen and (max-width: $breakpoint-sm) {
    flex-flow: column;
  }

  .print-link-anchor {
    display: block;
    padding: 0.25rem 5px;
    @media screen and (min-width: $breakpoint-md) {
      margin-right: 2rem;
      padding: 0.1rem 0;
      display: inline-block;
    }
  }

  .motif-print,
  .motif-message {
    margin-right: 0.5rem;
  }

  .print-label,
  .email-label {
    color: #333;
  }
}

.nav-links {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-sm) {
    flex-flow: column;
  }

  .scroll-link-anchor {
    display: block;
    padding: 0.25rem 5px;
    @media screen and (min-width: $breakpoint-md) {
      margin-right: 1rem;
      padding: 0.1rem 0;
      display: inline-block;
    }
  }

  .motif-print,
  .motif-message {
    margin-right: 0.5rem;
  }
}

.sk-spinner-wave.sk-spinner {
  justify-content: center;
}

.popIcon {
  text-decoration: none;
  cursor: pointer;
}

.tile-view-modal {
  margin-left: 0px;
}

.tile-view-modal-alignment {
  margin-bottom: 5px;
}

.tt-BluePrecision {
  .popover.right {
    width: 300px;
    margin-top: 13.5rem;
  }
}

.popover {
  .popover-close {
    a .fa {
      color: $primary-color-dark-blue;
    }
  }
}

.anchor-icon {
  .motif-icon {
    color: #286ce2;
    font-size: 18px;
  }
}

.anchor-imp-icon {
  text-decoration: none;
  .motif-icon {
    color: #ffffff;
    font-size: 18px;
  }
}

.hide-disclaimer {
  display: none;
}

.details-disclaimer {
  .hide-disclaimer {
    display: block;
  }
}

.search-results-info {
  .ant-tab-button {
    background-color: $ant-motif-white;
    font-size: 16px;

    // Commented as part of [ANREIMAGED-178217]
    // @media screen and (max-width: $breakpoint-lg) {
    //   .motif-icon {
    //     display: none;
    //   }
    // }
  }

  .fad-details-wrapper {
    font-size: 14px;
    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: -60px;
    }
    // @media screen and (max-width: $breakpoint-sm) {
    //padding-left: 3rem;
    // }
  }

  .ant-tab-list {
    display: flex;
    justify-content: flex-end;

    //.motif-icon {
    //font-size: 1rem !important;
    //}

    .ant-tab-button {
      font-weight: 300;
      @media screen and (min-width: $breakpoint-md) {
        flex-basis: 15%;
      }
    }

    .ant-tab-button.is-active {
      font-weight: 500;
    }

    @media screen and (min-width: $breakpoint-md) {
      .fad-tab-title {
        padding-right: 15px;
      }
    }
  }

  .pf-toggle-map-wrapper {
    margin-top: 2rem;
  }
  .data-loader-cmp {
    margin-top: 3rem;
  }

  // .fad-pf-summary-wrapper {
  /* padding-top: 1rem; */
  // }

  .pcp-id {
    color: $ant-motif-dove-gray;
  }

  .fad-pf-search-results-first-col {
    min-width: 36px;
  }

  .tier-wrapper {
    @media screen and (max-width: $breakpoint-md) {
      margin: 0.5rem 0 0.5rem 2rem;
    }

    a {
      font-size: 14px;
      cursor: pointer;
    }

    .popIcon {
      margin-left: 0;
      padding-bottom: 0.3rem;
      text-indent: 0;
    }

    .motif-icon {
      display: none;
    }

    .show-motif-icon .motif-icon {
      display: inline-block !important;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .search-results-info {
    flex-direction: column;
  }
}

.tab-body {
  display: none;

  &.is-active {
    display: block;
  }
}

.motif-filled-star {
  color: $ant-motif-accent-pumpkin;
  font-size: 1.2rem;
  margin-right: 5px;
}

.motif-star {
  color: $ant-motif-silver;
  font-size: 1.2rem;
  margin-right: 5px;
}

.ratings-link {
  cursor: pointer;
}
.ratings-text {
  font-weight: 300;
}

.search-result-grid {
  .fa-user-plus {
    color: $ant-motif-highlight-green;
  }

  .gender-icon {
    color: $ant-motif-dusty-gray;
  }

  .motif-marker {
    color: $ant-motif-highlight-green;
    font-size: 16px;
  }

  .gender-pcp-row {
    p:not(:first-of-type) {
      padding-right: 0.25rem;
    }

    p,
    span {
      color: $ant-motif-dove-gray;
      margin: 0;
    }

    a {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .speciality-wrapper {
    font-weight: 300;

    .speciality-comma::after {
      content: ", ";
    }

    .speciality-comma:last-child::after {
      content: "";
    }
  }

  .provider-details-container {
    width: 258px;
  }

  .mb-5 {
    margin-bottom: 5px !important;
  }
}

.right-content-icon {
  .popover-close {
    a .fa {
      color: $primary-color-dark-blue;
    }
  }
}

.pf-search-results-section {
  .pf-flex-row {
    display: flex;
    flex-flow: row nowrap !important; //For IE Fix
    width: 100%;

    @media screen and (max-width: $breakpoint-sm) {
      display: block;
    }
  }

  .pf-flex-col {
    flex: 1 0 10%;
    @media screen and (min-width: $breakpoint-md) {
      flex: 1 0 14%;
    }

    margin-left: 24px;

    &:first-of-type {
      flex: 1 0 20%;
      margin-left: 0;
    }
  }

  .pf-flex-col-auto {
    width: auto;
    // margin-left: 24px;
  }

  .pf-mobile-spacer {
    @media screen and (max-width: $breakpoint-md) {
      margin: 0.5rem 0 0.5rem 0rem;
    }
  }

  // 00-00-00
  .hidden {
    visibility: hidden;
  }

  .network-wrapper {
    font-weight: 300;
    color: $ant-motif-dove-gray;
    float: right;
  }

  .fad-item-details-wrapper {
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: nowrap;

    .fad-item-details {
      padding-top: 0.5rem;
    }
  }

  .provider-name-row {
    display: flex;

    .eyc-speciality {
      padding-top: 0.5rem;
    }
  }
}

.cpt-specialty {
  width: 9.25rem;
}

.provider-links {
  .view-more-addresses {
    text-decoration: none;
  }
}

.distance-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-top: 0.5rem;

  .motif-map-marker {
    color: $ant-bermuda-teal;
  }

  .summary-dist-text {
    color: $ant-motif-dove-gray;
    font-weight: 300;
  }
}

.flex-tile-wrap {
  display: flex;
  flex-flow: wrap;

  .fad-flex-tile {
    flex: 1 0 100%;
    @media screen and (min-width: $breakpoint-xs) {
      flex: 1 0 33%;
    }
    padding: 0.5rem 0;
    display: flex;
    max-width: 355px;
    margin: 0 0 0 1.25rem;
  }
}

.level-wrapper {
  padding: 0.5rem 0;

  .level-icon {
    color: #286ce2;
    padding-right: 0.25rem;
  }

  .level-type {
    text-decoration: none;
    cursor: pointer;
  }
}

.hours-wrapper {
  padding: 0.5rem 0;
}

.hours-icon {
  padding-right: 0.25rem;
}

.pharmacy-hours {
  padding-right: 0.25rem;
}

.provider-exp {
  padding-right: 0.25rem;
}

.pm-match-flash-icon {
  padding-right: 0.5rem;
  color: #53b1a3;
}

.provider-exp-pdf-icon {
  padding-right: 0.5rem;
  color: #a9a9a9;
  font-size: 1.25rem !important;
}

.left-padding {
  padding-left: 3px;
}
.tile-view-wrapper {
  padding: 1.5rem;
  flex-grow: 1;
  border: 1px solid $ant-medium-gray;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tile-name-wrapper,
  .address-wrapper {
    padding: 0.5rem 0;
  }

  .address-wrapper {
    flex-grow: 1;
  }

  .tile-space-btw {
    justify-content: space-between;
  }

  .tile-space-end {
    justify-content: flex-end;
    display: flex;
  }

  .name-network {
    display: flex;

    .network-text {
      padding-left: 0.5rem;
    }
  }

  .tile-review-wrapper {
    padding: 0.5rem 0;
  }

  .eyc-tile-cost-info {
    font-size: 18px;
  }

  .tile-button-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;

    .tile-link {
      margin: 0.5rem 0;
      font-size: 12px;
      font-weight: bold;
    }

    .btn {
      padding: 0.5rem 2rem;
      font-size: 12px;
      width: 100%;
    }

    .tile-assign-care {
      width: 100%;
    }

    .tile-assign-pcp {
      padding: 0.5rem 2rem;
    }
  }

  .tile-name-wrapper {
    max-width: 325px;
    padding: 0.5rem 0;

    .Personalized-match {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 2px;
    }

    .provider-name-link {
      cursor: pointer;
      font-size: 18px !important;
      text-transform: capitalize !important;
    }

    .provider-name-no-link {
      font-size: 18px !important;
      text-transform: capitalize !important;
      color: #333333;
      opacity: 1;
    }

    .gender-container {
      padding-bottom: 0.2rem;
    }
  }

  .pcp-row {
    p {
      padding-right: 0.25rem;
    }

    p,
    span {
      color: $ant-motif-dove-gray;
      margin: 0;
    }

    a {
      font-size: 14px;
    }

    .gender-text {
      padding-right: 0;
    }
  }

  .tile-checkbox {
    align-items: center;
    justify-content: space-between;
  }
}

.beFirstReview {
  a {
    text-decoration: none;
  }
}

.eyc-cost-wrapper {
  display: flex;
  flex-flow: column;

  .eyc-info {
    padding-left: 1rem;
  }
}

.popover.right,
.motif-theme .popover.left,
.motif-theme .popover.top,
.motif-theme .popover.bottom {
  z-index: 1060;
}

.popover.top {
  margin: -15px 0px 0 -10px;

  .popover-close,
  .popover-indicator {
    display: none;
  }
}

.fad-pf-search-results-select-cb {
  display: flex;
}

.tile-map-wrapper {
  background-color: $ant-motif-white;
  width: 300px;
  text-align: left;
  /* margin: 17px; */

  .tile-view-wrapper {
    border: none;
    box-shadow: none;
  }
  .cost-value {
    font-weight: 700;
    font-size: 18px !important;
    color: #286ce2;
  }
  /* &:after {
    background-color: $ant-motif-white;
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -1px;
    transform: rotate(45deg);
    margin-left: -6px;
    top: 20%;
    z-index: -1;
  } */
}

.modal-container.slide-in.right .close {
  // styles to bring in alignment with latest PL
  //position: absolute;
  float: none;
  height: 60px;
  width: 60px;
}

.pf-side-panel {
  overflow-y: auto;
  height: 100%;
  .modal-component-body {
    padding: 1rem 1rem 5rem 1rem;
    @media screen and (min-width: $breakpoint-sm) {
      //padding: 5rem;
    }
    .hw-heading {
      margin-bottom: 1rem;
    }
  }

  .dropddown-wrapper {
    padding: 3rem 0 1rem 0;
  }

  .modal-title {
    font-weight: 300;
    padding-bottom: 0.75rem;
  }

  .title-pcp-select {
    padding-top: 0.75rem;
  }

  .fad-action-buttons {
    justify-content: flex-start;
  }

  .panel-pcp-row {
    padding-bottom: 2rem;
  }

  .pcp-radio-grp {
    div {
      display: flex;
      flex-flow: column;
    }
  }
  .assign-pcp-empty-mbrIdtxt {
    padding-left: 21px;
  }
  .assign-pcp-simple-txt {
    padding: 0.85714286rem 3.42857143rem;
  }

  .pcp-info-wrapper {
    .motif-phone {
      color: $ant-motif-highlight-ocean;
      padding-right: 0.25rem;
    }
  }

  [data-uxd-radio-input-cmp] .prLabel {
    line-height: inherit;
  }
}

.change-plan-side {
  .modal-title {
    font-size: 28px;
    padding-bottom: 2rem;
  }

  .fad-action-buttons {
    justify-content: flex-start;
  }

  .dropdown-wrapper {
    padding-bottom: 0.5rem;
  }
}

.rtModalContent {
  h2 {
    padding-bottom: 4rem;
  }
  h6 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }
  .pcp-remove {
    padding-bottom: 1.5rem;
  }
  .rtModalContentTop {
    margin-left: 0;
    margin-right: 0;
    input {
      margin-right: 0.7rem;
    }
    .pcp-info {
      padding-left: 1.6rem;
      padding-bottom: 0.9rem;
    }
  }

  .slidePcpIdSection {
    margin-top: 2rem;
    .pcpdesc {
      padding-top: 0.8rem;
    }
  }
  .selectDropList {
    margin-top: 2rem;
    button {
      height: 2.3rem;
      width: 100% !important;
    }
    .ant-lg-select {
      width: 100% !important;
    }
  }
  .button-group {
    float: right;
    padding-top: 1rem;

    @media screen and (max-width: 768px) {
      float: none;
      .btn,
      .btn-secondary {
        margin-left: 0rem !important;
        margin-right: 0rem !important;
        display: block;
        width: 100%;
      }
    }
  }

  .clear {
    clear: both;
  }

  .pt-10 {
    padding: 10px 0 0 0;
  }

  .pt-20 {
    padding: 20px 0 0 0;
  }

  .pf-assign-pcp-list {
    list-style: disc;
    padding-left: 0px;
  }

  .pf-assign-pcp-list-item {
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0 0 0 3.5rem;
  }

  .no-padding-left {
    padding-left: 0px !important;
  }
}

//000-000
.modal-component-body.pf2-change-location {
  div.ng-untouched.ng-pristine.ng-valid {
    margin-bottom: 1rem;
  }
  .formRow {
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
    width: 75%;
    #txt-first-name {
      width: 100%;
    }
    .formRowHalf {
      display: inline-block;
    }
    .formRowHalf .uxd-btn-ddl {
      margin-right: 1rem;
    }
    .zipcode {
      position: relative;
      top: -0.5rem;
      width: 50%;
    }
    .state {
      width: 50%;
    }
  }

  #lbl-member-summary-error {
    margin-left: -1rem;
    margin-top: 1rem;
    .ant-alert {
      width: 80%;
    }
  }
  .fad-action-buttons.pf2-btn-slider {
    bottom: 1% !important;

    //force button color
    .btn.btn-primary {
      background-color: #00609a;
      color: #fff;
    }
  }
}

.price-detail-modal.modal-component-body {
  .rtModalContent.pricetab {
    h2 {
      padding-bottom: 4rem;
    }
    h3 {
      font-weight: normal;
    }
    .slide-modal-title {
      padding-bottom: 4rem;
    }
    h6 {
      font-weight: bold;
      font-size: 1rem;
      margin: 0;
    }

    .detail-group {
      padding-left: 0;
      margin-bottom: 3rem;

      .item-label {
        font-weight: 600;
        font-size: 1rem;
        margin: 0;
        @media screen and (max-width: $breakpoint-sm) {
          width: 50%;
        }
      }
      .right-align {
        text-align: right;
      }

      .item-label-val {
        @media screen and (max-width: $breakpoint-sm) {
          font-weight: normal;
          font-size: 1.28rem;
          width: 50%;
          text-align: right;
        }
      }

      .detail-right {
        padding-right: 0;
      }
      .detail-left {
        padding-left: 0;
      }
      .price {
        font-size: 1.25rem;
      }
      .description {
        border-top: 1px #000 solid;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .col-sm-12.detail-group {
      margin-bottom: 1.14rem;
    }

    .pcp-remove {
      padding-bottom: 1.5rem;
    }
    .rtModalContentTop {
      margin-left: 0;
      margin-right: 0;
      input {
        margin-right: 0.7rem;
      }
      .pcp-info {
        padding-left: 1.6rem;
        padding-bottom: 0.9rem;
      }
    }

    .slidePcpIdSection {
      margin-top: 2rem;
      .pcpdesc {
        padding-top: 0.8rem;
      }
    }
    .selectDropList {
      margin-top: 2rem;
      button {
        height: 2.3rem;
        width: 100% !important;
      }
      .ant-lg-select {
        width: 100% !important;
      }
    }
    .button-group {
      float: right;
      padding-top: 1rem;

      @media screen and (max-width: 768px) {
        float: none;
        .btn,
        .btn-secondary {
          margin-left: 0rem !important;
          margin-right: 0rem !important;
          display: block;
          width: 100%;
        }
      }
    }
    .fad-action-buttons {
      display: block;
    }

    .clear {
      clear: both;
    }
  }
}

// < price detail modal adjustment RCP/Stadalone consistecy

.motif-theme.rtModalContent {
  h2 {
    padding-bottom: 4rem;
  }
  h3 {
    font-weight: normal;
  }
  .slide-modal-title {
    padding-bottom: 4rem;
  }
  h6 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }

  .detail-group {
    padding-left: 0;
    margin-bottom: 3rem;

    .item-label {
      font-weight: 600;
      font-size: 1rem;
      margin: 0;
    }
    .detail-right {
      padding-right: 0;
    }
    .detail-left {
      padding-left: 0;
    }
    .price {
      font-size: 1.25rem;
    }
    .description {
      border-top: 1px #000 solid;
      padding-right: 0;
      padding-left: 0;
    }
  }

  // .pcp-remove {
  //   padding-bottom: 1.5rem;
  // }
  .rtModalContentTop {
    margin-left: 0;
    margin-right: 0;
    input {
      margin-right: 0.7rem;
    }
    .pcp-info {
      padding-left: 1.6rem;
      padding-bottom: 0.9rem;
    }
  }

  // .slidePcpIdSection {
  //   margin-top: 2rem;
  //   .pcpdesc{
  //     padding-top: .8rem;
  //   }
  // }
  // .selectDropList {
  //   margin-top: 2rem;
  //   button {
  //     height: 2.3rem;
  //     width: 100% !important;
  //   }
  //   .ant-lg-select {
  //     width: 100% !important;
  //   }
  // }
  .button-group {
    float: right;
    padding-top: 1rem;

    @media screen and (max-width: 768px) {
      float: none;
      .btn,
      .btn-secondary {
        margin-left: 0rem !important;
        margin-right: 0rem !important;
        display: block;
        width: 100%;
      }
    }
  }
  .fad-action-buttons {
    display: block;
  }

  .clear {
    clear: both;
  }
}

// price detail modal adjustment RCP/Standalone consistency

/** PCP IDs and Affiliations List Component - displayed in Summary, Detail and Compare pages **/
.pf-pcp-id-list {
  list-style: none;
  padding-left: 0px;
  margin-top: 1rem;
  margin-bottom: 0.25rem;

  .pf-pcp-id-list-item {
    margin: 0rem 0rem 0.75rem 0rem;
  }
  .pf-pcp-id-list-item:not(:first-child) {
    padding: 0.75rem 0rem 0.75rem 0rem;
    border-top: solid 1px #cccccc;
  }

  p {
    margin-bottom: 0px;
    color: $ant-motif-dove-dark-gray;
    font-size: 0.8rem;
  }

  .pf-pcpid-lbl {
    font-weight: bold;
    margin: 0;
  }

  .pf-pcpid-val {
    font-weight: bold;
  }

  .pf-pcp-afflnm-list {
    list-style: disc;
    padding-left: 0px;

    .pf-pcp-afflnm-item {
      margin: 0 0 0 1.25rem;
      padding: 0.35rem 0rem 0.35rem 0rem;
    }

    .pf-pcp-afflnm-item:first-child {
      padding: 0rem 0rem 0.35rem 0rem;
    }
  }
}

.view-more-link {
  text-decoration: underline !important;
}

/** end of PCP IDs and Affiliations List Component**/

.pf2-iconCards.care-circle {
  @media screen and (max-width: $breakpoint-sm) {
    display: block;
  }
  .pf2--iconCard {
    max-width: none;
    flex: 0 0 32%;
    text-align: left;
    display: grid;
    a {
      color: #286ce2;
      padding: 0;
      text-decoration: none;
      span.motif-icon.font-icon {
        font-size: 14px !important;
        padding-top: 0.5rem;
      }
    }
    .tile-view-wrapper {
      border: none;
      box-shadow: none;
      .tile-name-wrapper {
        flex-direction: row;
        display: flex;
        padding-bottom: 1rem;
        justify-content: space-between;
        width: 100%;
        .floater-tag {
          position: static;
        }
      }
      .tile-name-wrapper .provider-name-link {
        padding-left: 0;
      }
    }
  }
  p.ext {
    margin-top: -0.56rem !important;
  }
}
.careCircleAdv {
  .pf-specialtyBadge {
    font-size: 10px !important;
  }
  p.advPhone {
    color: $pf-primary-color;
    text-decoration: underline;
  }
  p.ext {
    margin-top: -0.56rem !important;
  }
}
.reasonHeader h2 {
  padding-bottom: 21px;
}
.provider-view {
  display: flex;
  align-items: baseline;
}

.more-examples-panel {
  .modal-title {
    padding-bottom: 0 !important;
  }
  .more-examples-content {
    margin: 2rem 0 1rem;
  }
}
