@import "variables";

@mixin pill-style {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid $ant-motif-dusty-gray;
  border-radius: 2px;
  height: 32px;
  /* min-width: 98px; */
  color: $ant-motif-mine-shaft;
  background-color: $ant-motif-white;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  letter-spacing: .0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  margin: 4px;
  outline: none;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 3px 2px $ant-motif-highlight-ocean;
  }

  .pill-text {
    padding: 7px 12px;
    font-size: 1rem;
  }

  &-selected {
    border: 1px solid $ant-motif-highlight-ocean;
    font-size: 16px;
    font-weight: bold;

    .pill-plus.fa {
      font-size: 80%;
      padding-right: 0;
    }

    .pill-counter {
      padding-right: .5rem;
    }
  }

  &:hover {
    border: 1px solid $ant-motif-highlight-ocean;
  }
}

.pill-list-wrapper {
  display: flex;
  max-width: 1034px; //IE fix
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .pf-pill {
    @include pill-style;

    .pill-text {
      &:focus {
        outline: none;
      }
    }

    .fa {
      color: $ant-motif-highlight-ocean;
      padding-right: .5rem
    }

    .selected-pill-background {
      background-color: $ant-motif-highlight-ocean;
      color: $ant-motif-white;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: .25rem;

      .fa {
        color: $ant-motif-white;
      }
    }
  }
  .searchFilterItem {
    display: flex;
    width:90%;
    label.pcLabel{
      font-size: 14px;
      max-width: 145px;
      overflow-wrap: break-word;
      &:focus {
        outline: none;
      }
    }
  }

  span.flipIcon {
    transform: rotateX(180deg);
    transition: transform 0.25s ease-out;
  }

  span.fa-caret-down {
    margin-left: 5px;
  }

  .bdcValue-style {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .bdc-link {
    outline: none;
  }

  .searchFilterItemNew{
    display: flex;
    width:50%;
    padding-left: 1rem;
    @media screen and (max-width: 700px) {
      width:90%;
    }
    label.pcLabel.focus{
      outline: none;
    }
    .popover{
      .popover-close{
        border: 1px solid #286ce2;
        a{
          height: 0;
          width: 0;
          margin-left: -17px!important;
          border-radius: 0;
        }
      }
    }
  }

  .fly-out {
    padding: 0;
    &.fly-out-lg{
      max-width: 574px;
      margin: auto;
      width: auto;
    }
    .specialtyScroll {
      position: relative;
    }
    .toggle-body {
      padding: 1rem 1rem 1rem 2rem;
      &.pill-list-flex{
        display: flex;
        flex-wrap: wrap;
        padding-left: 1rem !important;
      }

      &.toggle-scroll{
        overflow-y: auto;
        height: 13rem;
      }
    }
    .toggle-child > div:nth-child(n+7){
      display:none;
    }

  }

  .pill-btn-grp {
    border-top: 1px solid $ant-motif-silver;
    padding: 1rem 2rem;
    text-align: right;
    position: sticky;
    bottom: 0;

    .btn-secondary {
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    .pill-btn-grp {
      padding: 1rem 0.5rem;

      .btn-secondary {
        margin-right: 0.5rem;
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    .fly-out{
      &.fly-out-lg{
        width: 100%;
        max-width: 100%;
      }
    }
  }

  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  {
    .fly-out{
      &.fly-out-lg{
        width: auto;
        margin:auto;
        position: relative;
      }
    }
  }
}

.pill-header-wrapper {
  display: flex;
  margin: 1rem 0;
  justify-content: space-between;

  .pf-search-pills {
    width: 75%;
  }

  .overlay{
    height: 31rem;
    overflow-y: auto;
    overflow-x:hidden;
  }

  .pill-header-profile {
    font-size: 1.4rem;
    margin-bottom: 5px;
    padding: 1rem 2rem;
  }
  .section-pill-bg {
    background-color: $ant-motif-light-gray;

    .section-pill-p {
      font-weight: $font-weight-bold;
      padding: 1rem 2rem;
    }
  }

  .clear-all-link{
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    float: right;
    width: max-content;
    padding: .5rem 1.5rem .5rem .5rem;
    @media screen and (max-width: 1200px) {
      padding: .5rem .5rem .5rem .5rem;
    }
  }

  .clear-all-btn {
    @include pill-style;
    color: $ant-motif-highlight-mauve;
    justify-content: space-evenly;
    min-width: 107px;

    &:focus {
      box-shadow: 0 0 3px 2px $ant-motif-highlight-mauve;
    }

    .pill-text {
      padding: 3px;
    }

    .fa-plus {
      font-family: $ant-classic-font-family;
      content: $ant-classic-plus;
      padding: 0 0 3px 3px;
      transform: rotate(45deg);
      font-size: 1.4rem;
      color: $ant-motif-highlight-mauve;
    }

    &:hover {
      border: 1px solid $ant-motif-highlight-mauve;
    }

    .pill-text {
      &:focus {
        outline: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .pill-header-wrapper {
    flex-direction: column;
    .pf-search-pills {
      width: 100%;
      .pill-list-wrapper {
        margin-bottom: 2rem;
      }
      .pillItemCmp {
        width: 50%;
        .pf-pill {
          justify-content: center;
          width: 90%
        }
      }
    }
  }
}


.searchFilterItemNew,
.searchFilterItem  .prLabel:before {
	margin-bottom: 1rem;
}

.pill-list-wrapper .searchFilterItem {
	width: 90%;
}
.pill-list-wrapper .affLabel{
  font-weight: 700;
  padding: 1rem 1rem 0rem 2rem;
}
.pill-filter-sub-space {
  margin-left:1.6rem;
  .pill-filter-sub {
    color: rgb(102, 102, 102);
    font-size: 12px;
    font-family: Lato, sans-serif;
    font-weight: normal;
    span{
      font-family: Lato, sans-serif;
    }
  }
}
.fly-out {
  @media screen and (max-width: 450px) {
    left: 0;
  }
  .look-up-container {
    width: 30rem;
    @media screen and (max-width: 320px) {
      width: 20;
    }
  }
}

// < COLD STATE
#mbr-page-wrapper #pfContainer {
  .fly-out {
    .look-up-container {
      @media screen and (min-width: 708px) and (max-width: 1023px) {
        position: absolute;
        left: 70%;
      }
    }
  }
}
// COLD STATE >

.infoBox-container {
  padding-bottom: 1rem;
  .dismiss {
    position: relative;
    float: right;
    .fa.fa-plus {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      color: #949494;
      font-size: 2.25rem;
    }
  }

  .location-alert {
    .ant-alert {
      .ant-alert-left {
        padding: 0.2rem 1rem !important;
        background-color: #fff !important;
      }
    }
    .dismiss {
      position: absolute;
      right: 30px;
    }
  }

  .infoBox.location-alert {
    .ant-negative {
      background: #fff;
      border-left: none !important;
    }
  }

  .infoBox {
    &.fit-content {
      width: fit-content;
      width: -moz-fit-content;
      .ant-alert-main {
        background-color: #fff !important;
      }
    }
    .ant-alert {
      padding: 0em !important;
      margin-top: 0;
      border: #ccc solid 1px;
      border-left: 5px #286ce2 solid;
      .alert-icon {
        padding: 5px 0px !important;
        font-size: 1rem;
      }
      .ant-alert-left {
        padding: 0.2rem;
        background-color: #fff;
        .alert-icon.alert-icon-info::before {
          color: #286ce2;
          margin-right: 0.5rem;
        }
      }
      .ant-alert-main {
        padding: 0;
        background-color: #fff !important;
        p {
          width: 100%;
          span {
            display: flex;
            align-items: center;
            .fa.fa-plus {
              font-size: 1.5rem;
            }
            .pcp-msg {
              display: inline;
            }
          }
        }
        .infoText {
          width: 97%;
          @media screen and (max-width: 700px) {
            width: 95%;
          }
        }
        .ant-alert {
          border: none;
          .ant-alert-left {
            border: 0 #fff solid;
          }
        }
      }
    }
    .ant-negative {
      border-left: none !important;
    }
    .ant-positive .ant-alert-left {
      border-left: 5px #53B1A3 solid;
      .alert-icon-positive {
        color: #53B1A3;
      }
    }
    .ant-negative .ant-alert-left {
      border-left: 5px #cc0033 solid !important;
      .alert-icon-negative {
        color: #cc0033 !important;
      }
    }
  }
  .fa.fa-external-link {
    display: inline !important;
  }
}
.infoBox-container.lho-info-container {
  padding-top: .7em;
}
.infoBox-container.scenario-alert-container {
  padding-bottom: 0em !important;
}

.no-result{
  display: flex;
  .view-info-label{
    padding: 0.4rem;
  }
}

// over-rides for use in public to update zip alert design to better match other environments
.fad-app-wrapper .fly-out.look-up-container {
  div.ant-alert {
    align-items: stretch !important;
    .ant-alert-main {
      padding: .857rem 10px .857rem .57rem !important;
      background-color: #f7f7f7 !important;
    }
    .ant-alert .ant-alert-left .alert-icon {
      padding: .5rem !important;
    }
  }
}
.fly-out.look-up-container.help-msg {
  padding: 10px;
  width: 270px;
  height: 88px;
  padding: 22px 24px 0;
  box-shadow: 2px 2px 4px 0 rgba(220, 220, 220, 0.5);
}

.pill-list-wrapper-leftsidebar {
  // display: flex;
   max-width: 1034px; //IE fix
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;

   .pf-pill {
     @include pill-style;

     .pill-text {
       &:focus {
         outline: none;
       }
     }

     .fa {
       color: $ant-motif-highlight-ocean;
       padding-right: .5rem
     }

     .selected-pill-background {
       background-color: $ant-motif-highlight-ocean;
       color: $ant-motif-white;
       height: 100%;
       display: flex;
       align-items: center;
       padding-left: .25rem;

       .fa {
         color: $ant-motif-white;
       }
     }
   }
   .searchFilterItem {
     display: flex;
     width:90%;
     label.pcLabel{
       font-size: 14px;
       width: auto;
       &:focus {
         outline: none;
       }
     }
   }

   span.flipIcon {
     transform: rotateX(180deg);
     transition: transform 0.25s ease-out;
   }

   span.fa-caret-down {
     margin-left: 5px;
   }

   .bdcValue-style {
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     cursor: pointer;
   }

   .bdc-link {
     outline: none;
   }

   .searchFilterItemNew{
     display: flex;
     width:50%;
     padding-left: 1rem;
     @media screen and (max-width: 700px) {
       width:90%;
     }
     label.pcLabel.focus{
       outline: none;
     }
     .popover{
       .popover-close{
         border: 1px solid #286ce2;
         a{
           height: 0;
           width: 0;
           margin-left: -17px!important;
           border-radius: 0;
         }
       }
     }
   }

   .fly-out {
     padding: 0;
     &.fly-out-lg{
       max-width: 574px;
       margin: auto;
       width: auto;
     }
     .specialtyScroll {
       position: relative;
     }
     .toggle-body {
       padding: 1rem 1rem 1rem 2rem;
       &.pill-list-flex{
         display: flex;
         flex-wrap: wrap;
         padding-left: 1rem !important;
       }

       &.toggle-scroll{
         overflow-y: auto;
         height: 13rem;
       }
     }
     .toggle-child > div:nth-child(n+7){
       display:none;
     }

   }

   .pill-btn-grp {
     border-top: 1px solid $ant-motif-silver;
     padding: 1rem 2rem;
     text-align: right;
     position: sticky;
     bottom: 0;

     .btn-secondary {
       margin-right: 1rem;
     }
   }

   @media screen and (max-width: $breakpoint-xs) {
     .pill-btn-grp {
       padding: 1rem 0.5rem;

       .btn-secondary {
         margin-right: 0.5rem;
       }
     }
   }

   @media screen and (max-width: $breakpoint-sm) {
     .fly-out{
       &.fly-out-lg{
         width: 100%;
         max-width: 100%;
       }
     }
   }

   @media only screen
   and (min-device-width: 768px)
   and (max-device-width: 1024px)
   {
     .fly-out{
       &.fly-out-lg{
         width: auto;
         margin:auto;
         position: relative;
       }
     }
   }
 }
 .leftfilter-clear-all-link{
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  width: max-content;
  padding: .5rem 1.5rem .5rem .5rem;
  @media screen and (max-width: 1200px) {
    padding: .5rem .5rem .5rem .5rem;
  }
}

.pfServiceCheckbox .pcLabel {
  margin-left: 25px;
}

.content-container {
  box-shadow: none;
}
